import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './OrganizationGridComponent.css';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import React from 'react';
import OrganizationItem from './OrganizationItem'
import OrganizationGridViewModel from '../../models/Organizations/OrganizationGridViewModel'
import OrganizationStore from '../../stores/OrganizationStore';
import { ORGANIZATION_FRAMWORK_PAGE_PATH } from '../../Settings';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import { Redirect } from 'react-router';

import { RouterStore } from 'mobx-react-router';
import OrganizationConstants from '../../constants/OrganizationConstants';
import OrganizationModel from '../../models/Organizations/OrganizationModel'
// import { ORGANIZATION_GRID_CY_DATA,ORGANIZATION_GRID_ITEM_CY_DATA,ORGANIZATION_GRID_PAGINATION_CY_DATA,ORGANIZATION_SEARCH_BUTTON_CY_DATA,ORGANIZATION_SEARCH_INPUT_CY_DATA } from '../../cypressDataConstants/organizationGridCyDataConstants';
import { CaughtException } from 'mobx/lib/internal';
import PaginationComponent from '../providers/PagintationComponent';


interface OrganizationGridComponentProps {
    globalLoader?: GlobalLoaderStore,
    routing?: RouterStore,
    organizations: OrganizationStore
}

interface OrganizationGridComponentState {
    filterString: string,
    appliedFilterString: string,
    redirectToFramework: boolean,
    hasError: boolean,
    textInputError: string,
    queryField: string,
    queryValue: string,
    resultData: any
}
interface ApiOrganizationResult {
    data: {
        fetcher: [{
            Id: string,
            Name: string,
            LearningPlatform: string,
            LearningPlatformHostURL: string,
            IntegrationType: string,
            Status: string,
            CreateDate: string,
        }]
    }
}

@inject('organizations', 'globalLoader', 'routing')
@observer
export default class OrganizationGridComponent extends Component<OrganizationGridComponentProps, OrganizationGridComponentState> {
    constructor(props: OrganizationGridComponentProps) {
        super(props);        
        this.state = {
            filterString: "",
            appliedFilterString: "",
            redirectToFramework: false,
            hasError: false,
            textInputError: '',
            queryField: "GlobalContentID",
            queryValue: "",
            resultData: null
        }
    }

    componentDidMount() {
        if(this.props.organizations.Organizations.length == 0)
        {
            this.props.globalLoader!.startLoading();
            this.props.organizations!.loadOrganizations().finally(()=>this.props.globalLoader!.finishLoading());
        }
    }

 
    onQueryFilterValueChange = (event: string) => {
        this.setState({ queryValue: event })
    }

    mapResultsToorganizationsGridViewModelObject = (data: ApiOrganizationResult) => {
        var result: OrganizationGridViewModel[] = []
        data.data.fetcher.forEach(item => {
            result.push(new OrganizationGridViewModel(item.Id, item.Name, item.LearningPlatform, item.LearningPlatformHostURL, item.IntegrationType,
                item.Status, item.CreateDate))
        });
        return result;
    }

   
    sortOrganizationById = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { return isDescending ? -(a.Id!.localeCompare(b.Id!)) : (a.Id!.localeCompare(b.Id!)) });
    }
    sortOrganizationByStatus = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { return isDescending ? -(a.IsActive!.toString().localeCompare(b.IsActive!.toString())) : (a.IsActive!.toString().localeCompare(b.IsActive!.toString())) });
    }
    sortOrganizationByCreateDate = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => {
            if(a.CreatedDate == undefined && b.CreatedDate== undefined) return 0; if(a.CreatedDate == undefined) return 1;  if(b.CreatedDate == undefined) return -1;
            return isDescending ? -(a.CreatedDate!.localeCompare(b.CreatedDate!)) : (a.CreatedDate!.localeCompare(b.CreatedDate!)) });
    }
    sortOrganizationByName = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { 
            if(a.Name == undefined && b.Name== undefined) return 0; if(a.Name == undefined) return 1;  if(b.Name == undefined) return -1;
            return isDescending ? -(a.Name!.localeCompare(b.Name!)) : (a.Name!.localeCompare(b.Name!)) });
    }
    sortOrganizationByLearningPlatform = (isDescending: boolean) => {
        // this.props.organizations!.sortOrganizations((a, b) => { return isDescending ? -(a.LearningPlatform!.localeCompare(b.LearningPlatform!)) : (a.LearningPlatform!.localeCompare(b.LearningPlatform!)) });
    }
    sortOrganizationByLearningPlatformUrl = (isDescending: boolean) => {
        // this.props.organizations!.sortOrganizations((a, b) => { return isDescending ? -(a.LearningHostUrl!.localeCompare(b.LearningHostUrl!)) : (a.LearningHostUrl!.localeCompare(b.LearningHostUrl!)) });
    }
    sortOrganizationByIntegrationType = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { return isDescending ? -(a.IntegrationType!.toString().localeCompare(b.IntegrationType!.toString())) : (a.IntegrationType!.toString().localeCompare(b.IntegrationType!.toString())) });
    }

  
    handleOnChangePage = (newCurrentPage: number): Promise<any> => {
        return new Promise<any>((resolve) => {
            this.props.organizations!.CurrentPage = newCurrentPage;
            resolve(null);
        })
    }


    onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            this.setState({ filterString: "", appliedFilterString: "" });
        } else {
            this.setState({ filterString: e.target.value });
        }
    }
    redirectToOrganizationFramework = () => {
        this.setState({redirectToFramework: true});
    }

    onFilterApply = (event: any) => {
        event.preventDefault();
        this.setState({ appliedFilterString: this.state.filterString });
    }

    onTipClicked = (event: any) => {
        this.setState({ filterString: event.target.innerText, appliedFilterString: event.target.innerText });
    }
    ResetCurrentSort = (idString: string) => {
        var elements = document.getElementsByClassName("sortable");
        while (elements.length)
            elements[0].classList.remove("sortable");

        var sortTarget = document.getElementById(idString);
        sortTarget?.classList.add("sortable");
    }
    renderGridHeaderItem = (title: string, onSort: (isDescending: boolean)=>void) => {
        return <th scope='col'>
            <div className="csod-grid-header-container">
                {title}
                <div className = "csod-sorting-container">
                    
                    <div id={title + 'up'} className="csod-sort-up "  onClick= {() => { onSort(true); 
                        this.ResetCurrentSort(title + 'up')} }><div className="csod-icon-sort-up">▲</div></div>
                    <div id={title + 'down'} className="csod-sort-down" onClick= {() =>{ onSort(false)
                        this.ResetCurrentSort(title + 'down')} }><div className="csod-icon-sort-down">▼</div></div>
                </div>
            </div>
        </th>
    }


    static getDerivedStateFromError(error: CaughtException) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    onCreateNewOrgClick = () => {
        this.props.organizations!.CurrentOrganization = new OrganizationModel();
        this.props.organizations!.FrameworkMode = FrameworkModes.Create;
        this.redirectToOrganizationFramework();
    }
    render() {
        if (this.props.globalLoader!.isLoading) {
            return null;
        }
        if (this.state.redirectToFramework) {
            return <Redirect push={true} to = {ORGANIZATION_FRAMWORK_PAGE_PATH}/>
        }
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        var OrganizationList: OrganizationModel[] = this.state.resultData ? this.state.resultData : this.props.organizations?.Organizations;
        var currentPage = this.props.organizations!.CurrentPage;
        var lastItemPerCurrentPageNumber = (this.props.organizations!.CurrentPage - 1) * OrganizationConstants.ITEMS_PER_PAGE_COUNT + OrganizationConstants.ITEMS_PER_PAGE_COUNT;
        if (lastItemPerCurrentPageNumber > this.props.organizations!.Organizations!.length) {
            lastItemPerCurrentPageNumber = this.props.organizations!.Organizations!.length;
        }

        return <div className="csod-organization-data-container">

            <div className="csod-search-container">
                <div className="csod-organization-data-count-title">Content Organization ({this.props.organizations.Organizations.length})</div>
                <button className = "csod-button less-rounded-corners blue" onClick={this.onCreateNewOrgClick}>{OrganizationConstants.ADD_NEW_ORGANIZATION}</button>

                {this.props.organizations.Organizations.length === 0 ? null :
                    <div>
                        <table className="csod-organization-data-grid">
                            <thead>
                                <tr>
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_ID, this.sortOrganizationById)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_NAME, this.sortOrganizationByName)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_LEARNING_PLATFORM, this.sortOrganizationByLearningPlatform)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_LEARNING_PLATFORM_HOST_URL, this.sortOrganizationByLearningPlatformUrl)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_INTEGRATION_TYPE, this.sortOrganizationByIntegrationType)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_STATUS, this.sortOrganizationByStatus)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_CREATE_DATE, this.sortOrganizationByCreateDate)} 
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    console.log("current page: " + (this.props.organizations!.CurrentPage - 1))
                                }
                                {
                                    this.props.organizations.Organizations.slice((this.props.organizations!.CurrentPage - 1) * OrganizationConstants.ITEMS_PER_PAGE_COUNT, lastItemPerCurrentPageNumber)
                                        .map((item) => { return <OrganizationItem Organization={OrganizationGridViewModel.Map(item)} key={item.Id} 
                                        RedirectToUpdate={this.redirectToOrganizationFramework} /> })
                                }
                            </tbody>
                        </table>
                        {this.props.organizations.Organizations!.length <= OrganizationConstants.ITEMS_PER_PAGE_COUNT ? null : <PaginationComponent currentPageIndex={currentPage} listPages={this.props.organizations!.TotalPages} handleChangePage={this.handleOnChangePage} />} 

                    </div>}
            </div>
        </div>
    }
}