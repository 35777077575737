import React, { Component } from 'react';
import "./LabelComponent.css";
import { InputWidthTypes } from '../../../helpers/common/StyleHelper';

interface LabelComponentState {
    isCopied: boolean
}

interface LabelComponentProps {
    title: string,
    value: string,
    isAbleToCopy: boolean,
    isHidden: boolean,
    widthClass: InputWidthTypes,
    additionalClass?: string,
    cy_data?: string
}


export default class LabelComponent extends Component<LabelComponentProps, LabelComponentState> {
    constructor(props: LabelComponentProps) {
        super(props);
        this.state = {isCopied:false}
    }

    private onCopyButtonClick = (event: any) => {
        this.setState({isCopied: true})
        setTimeout(()=> {this.setState({isCopied: false})},1500)
        navigator.clipboard.writeText(this.props.value);
    }

    render() {
        return <div className = {`csod-inputs-column-container ${this.props.widthClass}`}>
            <div className = "csod-input-title-text non-selectable">{this.props.title}</div>
            <div className = "csod-inputs-row-container no-flex-wrap">
                <div className = {`csod-input-label non-selectable ${this.state.isCopied? "csod-label-copied" : "" } ${this.props.additionalClass != null? this.props.additionalClass : ""}`} cy-data={this.props.cy_data}>{this.props.isHidden? "*".repeat(this.props.value.length):this.props.value}</div>
                {this.props.isAbleToCopy ? <button className = "csod-copy-button" onClick={this.onCopyButtonClick}/> : null}
            </div>
        </div>
    }
}