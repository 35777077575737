import TestingFrameworkItemModel from "../ProviderFramework/TestingFrameworkItemModel";
import ProviderDetailsExpansion, { CourseMapping, SubjectMapping } from "../ProviderDetails/ProviderDetailsExpansion";
import ProviderModel from "../ProviderDetails/ProviderModel";
import { ProviderOfferingType } from "../ProviderDetails/ProviderOfferingType";
import TestPortalData from "./TestPortalData";
import CiidData from "./CiidData";
import { TestSubscriptionOption } from "./TestSubscriptionOption";

export default class ProviderToolData {
    constructor(	
        public Id: string,
        public IsCustomSubjectMapping: boolean,
        public IsCustomCourseMapping: boolean,
        public DirectSubscriptionCIIDs: [string],
        public DirectSubscriptionCIIDData: CiidData[],
        public IsForCuration: boolean,
        public TestPortals: TestPortalData[],
    ) { }

    public static ComposeProviderToolData = (provider: ProviderModel, providerDetailsExpansion: ProviderDetailsExpansion, testSubscriptionConfig: {[id: string]: TestingFrameworkItemModel}) => {
        let testPortals: TestingFrameworkItemModel[] = [];
        let testCiidDatas: CiidData[] = [];
        if(provider.ProviderOfferingType === ProviderOfferingType.OneToOne || provider.ProviderOfferingType === ProviderOfferingType.Both) {
            testPortals = testPortals.concat([testSubscriptionConfig[TestSubscriptionOption.TestPortal1],testSubscriptionConfig[TestSubscriptionOption.TestPortal2]]);
        } else if (provider.ProviderOfferingType === ProviderOfferingType.OneToMany || provider.ProviderOfferingType === ProviderOfferingType.Both) {
            testPortals = testPortals.concat([testSubscriptionConfig[TestSubscriptionOption.CSODTestPortal], testSubscriptionConfig[TestSubscriptionOption.SabaTestPortal]]);
        }
        let testPrortalsForUpsert: TestPortalData[] = testPortals.map(i=> new TestPortalData(i.isChecked,i.url,i.CIID));
        return new ProviderToolData(provider.Id!,providerDetailsExpansion.SubjectMappingType === SubjectMapping.Custom, providerDetailsExpansion.CourseMappingType === CourseMapping.Custom,
             providerDetailsExpansion.CIIDs, testCiidDatas, providerDetailsExpansion.IsForCuration, testPrortalsForUpsert, providerDetailsExpansion.ProviderDescription);
    }
}