import CiidData from "../TestSubscription/CiidData";

export enum SubjectMapping {
    Custom = "Custom Taxonomy",
    CSODPreset = "CSOD Subject Mapping"
}
export enum CourseMapping {
    None = "None",
    Custom = "Custom Course Mapping",
}

export enum TimestampType {
    YYYYMMDDTHHMMSSZ = "yyyy-MM-ddTHH:mm:ssZ",
    YYYYMMDDTHHMMSSFZ = "yyyy-MM-ddTHH:mm:ss.fZ",
    YYYYMMDDTHHMMSSFFZ = "yyyy-MM-ddTHH:mm:ss.ffZ",
    YYYYMMDDTHHMMSSFFFZ = "yyyy-MM-ddTHH:mm:ss.fffZ",
    YYYYMMDDTHHMMSSFFFFZ = "yyyy-MM-ddTHH:mm:ss.ffffZ",
    YYYYMMDDTHHMMSSFFFFFZ = "yyyy-MM-ddTHH:mm:ss.fffffZ",
    YYYYMMDDTHHMMSSFFFFFFZ = "yyyy-MM-ddTHH:mm:ss.ffffffZ",
}

export const SubjectMappingMap = (value: string) : SubjectMapping | undefined => {
    switch (value) {
        case SubjectMapping.Custom: return SubjectMapping.Custom;
        case SubjectMapping.CSODPreset: return SubjectMapping.CSODPreset;
        default: return undefined
    }
}
export const CourseMappingMap = (value: string) : CourseMapping | undefined => {
    switch (value) {
        case CourseMapping.None: return CourseMapping.None;
        case CourseMapping.Custom: return CourseMapping.Custom;
        default: return undefined
    }
}
export const TimestampMappingMap = (value: string) : TimestampType | undefined => {
    switch (value) {
        case TimestampType.YYYYMMDDTHHMMSSZ: return TimestampType.YYYYMMDDTHHMMSSZ;
        case TimestampType.YYYYMMDDTHHMMSSFZ: return TimestampType.YYYYMMDDTHHMMSSFZ;
        case TimestampType.YYYYMMDDTHHMMSSFFZ: return TimestampType.YYYYMMDDTHHMMSSFFZ;
        case TimestampType.YYYYMMDDTHHMMSSFFFZ: return TimestampType.YYYYMMDDTHHMMSSFFFZ;
        case TimestampType.YYYYMMDDTHHMMSSFFFFZ: return TimestampType.YYYYMMDDTHHMMSSFFFFZ;
        case TimestampType.YYYYMMDDTHHMMSSFFFFFZ: return TimestampType.YYYYMMDDTHHMMSSFFFFFZ;
        case TimestampType.YYYYMMDDTHHMMSSFFFFFFZ: return TimestampType.YYYYMMDDTHHMMSSFFFFFFZ;
        default: return undefined
    }
}

export default class ProviderDetailsExpansion {
    constructor(
        public CIIDs: [string] = ["no ciid"],
        public ProviderDescription: string = "",
        public DirectSubscriptionCIIDData: CiidData[] = [{Ciid:"no ciid", SubscriptionUri:""}],
        public IsExcelProvider: boolean = false,
        public IsForCuration: boolean = false,
        public SelectedCIID: CiidData = {Ciid:"",SubscriptionUri:""},
        public ContactEmails: string = "",
        public SubjectMappingType: SubjectMapping = SubjectMapping.CSODPreset,
        public CourseMappingType: CourseMapping = CourseMapping.None,
        public CustomCourseMapping: string = "",
        public CustomTaxonomy: string = ""
    ) { }
}