import { observable, action } from 'mobx';
import GraphQLHelper from '../helpers/common/GraphQLHelper';
import EcaSubscriptionGridViewModel from '../models/ExploreContentAnytimeModels/EcaSubscriptionGridViewModel';
import EcaConstants from '../constants/ExploreContentAnytimeConstants';
import EcaSubscriptionModel from '../models/ExploreContentAnytimeModels/EcaSubscriptionModel';


export default class EcaStore {
    @observable Subscriptions: EcaSubscriptionModel[] = observable([]);
    @observable CurrentSubscription: EcaSubscriptionModel | null = null;
    @observable CurrentPage: number = 1;
    @observable LastModifiedBy: String | null = null;
    @observable TotalPages: number = 1;


    @action
    loadProviders = async () => {
        let res = {
            data: {
                Subscriptions: [
                    { SubscriberUri: "https://www.hotdog.plenty", CcaBundleName: "Professional Bundle II", Description: "This is a description",
                     NoOfCourses: "67",  DownloadAiccPackage: "link?", DownloadAiccMetaData: "Link2?" },
                     { SubscriberUri: "https://www.plenty.hotdog", CcaBundleName: "Professional Bundle IV", Description: "This is another description",
                     NoOfCourses: "98",  DownloadAiccPackage: "link?", DownloadAiccMetaData: "Link2?" },
                ]
            }
        }

        if (res != null) {
            this.Subscriptions = res.data.Subscriptions;
            this.TotalPages = Math.ceil(this.Subscriptions.length / EcaConstants.ITEMS_PER_PAGE_COUNT);
        }
    }

    cleanProviderData = () => {
        this.CurrentSubscription = null;
    }

    @action
    loadCurrentProvider = (subscription: EcaSubscriptionGridViewModel) => {
        this.CurrentSubscription = this.Subscriptions.filter(b => b.SubscriberUri === subscription.SubscriberUri)[0];
    }

    @action
    setNewProvider = (subscription: EcaSubscriptionModel | null) => {
        this.CurrentSubscription = subscription;
    }

    @action
    getProvider = (SubscriberUri: string) => {
        return this.Subscriptions.find(b => b.SubscriberUri === SubscriberUri);
    }

    @action
    sortProviders = (compareFn?: ((a: EcaSubscriptionModel, b: EcaSubscriptionModel) => number) | undefined) => {
        this.Subscriptions = this.Subscriptions.slice().sort(compareFn);
    }
}