import { observable, action } from 'mobx';
import GraphQLHelper from '../helpers/common/GraphQLHelper';
import { FrameworkModes } from '../models/ProviderFramework/FrameworkMode';
import BasicAuthConfig from '../models/ProviderDetails/BasicAuthConfig';
import Oauth2Config from '../models/ProviderDetails/Oauth2Config';
import ProviderDetailsExpansion from '../models/ProviderDetails/ProviderDetailsExpansion';
import OrganizationGridViewModel from '../models/Organizations/OrganizationGridViewModel';
import SSOConfigModel from '../models/SSOConfig/SSOConfigModel';
import { TestSubscriptionOption } from '../models/TestSubscription/TestSubscriptionOption';
import OrganizationConstants from '../constants/OrganizationConstants';
import OrganizationModel from '../models/Organizations/OrganizationModel'
import metadataLanguages from '../components/framework/OrganizationMetadataLanguages.json';



export default class OrganizationStore {
    @observable Organizations: OrganizationModel[] = observable([]);
    @observable Bundles: any[] = observable([]);
    @observable MetadataLanguages: any[] = observable([]);
    @observable CurrentOrganization: OrganizationModel | null = null;
    @observable CurrentOAuth2Config: Oauth2Config = new Oauth2Config();
    @observable CurrentBasicAuthConfig: BasicAuthConfig | null = new BasicAuthConfig();
    @observable CurrentProgressApiConfig: BasicAuthConfig | null = new BasicAuthConfig();
    @observable ProviderDetailsExpansion: ProviderDetailsExpansion = new ProviderDetailsExpansion();    
    @observable FrameworkMode: FrameworkModes = FrameworkModes.Create;

    @observable CurrentSSOConfigModel: SSOConfigModel = new SSOConfigModel();
    @observable CurrentPage: number = 1;
    @observable LastModifiedBy: String | null = null;
    @observable OrganizationModified: String | null = null;
    @observable TotalPages: number = 1;
    @observable IsStrictSchema: boolean = false
    @observable CustomCiid: string = ""
    
    @action
    loadOrganizations = async() => {      
        let res =  await GraphQLHelper.GetOrganizationsGQCall();
        let bundles = await GraphQLHelper.GetCuratedBundles();
        this.Organizations = res.data.getOrganizations;
        this.Bundles = bundles.data.getBundles;
        this.MetadataLanguages = this.getLanguageNames();
        this.TotalPages = Math.ceil(this.Organizations.length / OrganizationConstants.ITEMS_PER_PAGE_COUNT);
    }

    getLanguageNames = () =>{
        return  metadataLanguages;
    }

    cleanOrganizationData = () => {    
        this.CurrentOrganization = null;    
        this.CurrentSSOConfigModel = new SSOConfigModel();
    }

    @action
    loadCurrentOrganization = (Organization: OrganizationGridViewModel) => {
        this.CurrentOrganization = this.Organizations.filter(p => p.Id === Organization.Id)[0];
    }

    @action
    setNewOrganization = (Organization: OrganizationModel | null) => {
        this.CurrentOrganization = Organization;
    }

    @action
    getOrganization = (OrganizationId: string) => {
        return this.Organizations.find(p => p.Id === OrganizationId);
    }

    @action
    sortOrganizations = (compareFn?: ((a: OrganizationModel, b: OrganizationModel) => number) | undefined) => {
        this.Organizations = this.Organizations.slice().sort(compareFn);
    }
}