import { API, graphqlOperation } from "aws-amplify"
import BundleProviderParameter from "../../models/TestSubscription/BundleProviderParameter";
import ProviderDataQueryParameter from "../../models/TestSubscription/ProviderDataQueryParameter";
import PackageQueryParameter from "../../models/TestSubscription/PackageQueryParameter";
import HttpHelper from '../../helpers/common/HttpHelper';
import DirectSubscriptionInput from "../../models/TestSubscription/DirectSubscriptionInput";
import ProviderModel from "../../models/ProviderDetails/ProviderModel";
import ProviderToolData from "../../models/TestSubscription/ProviderToolData";
import SubscriptionInput from "../../models/TestSubscription/SubscriptionInput";
import UpsertProviderModel from "../../models/ProviderDetails/UpsertProviderModel";
import EcaCourseModel from "../../models/ExploreContentAnytimeModels/EcaCourseModel";
import { addProvider, directSubscribeQGCall, sync, subscribeGQCall, upsertProvider, upsertSettings, upsertOrganization } from "../../graphql/mutations";

import {getBundles, getCuratedCoursesAsUrl, getPersignedUrl, getCuratedCourses, getAdditionalDetailsForProvider, getSettings, listProviders, fetchPackagesWithQuery, providerData, getCurrentList, getOrganization, getOrganizations, providerDataList, getProviderDatatSettings, UpdateCCMSBundle, GetCuratedBundles, fetchCoursesPerOrg } from "../../graphql/queries";

import OrganizationModel from "../../models/Organizations/OrganizationModel";


export default class GraphQLHelper {
    public static GetProvidersGQCall = async (): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(listProviders));
        }
        catch (err) {
            throw err;
        }
    }


    public static AddProviderGQCall = async (bundleProvider: BundleProviderParameter): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(addProvider, { bundleProvider: bundleProvider }));
        }
        catch (err) {
            throw err;
        }
    }

    public static AddOrganizationGQCall = async (organization: any): Promise<any> => {
        try {
            if(organization.Sso)
                organization.Sso.SpCertificate=undefined; // Not yet implemented, do not want to add to DB
           
            return await API.graphql(graphqlOperation(upsertOrganization, { organization: organization }));
        }
        catch (err) {
            throw err;
        }
    }

    public static GetOrganizationGQCall = async (organizationInput: any): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(getOrganization, { Id: organizationInput.Id }));
        }
        catch (err) {
            throw err;
        }
    }


    public static GetOrganizationsGQCall = async (): Promise<any> => {
        try {
            console.log("Get Organizations")
            return await API.graphql(graphqlOperation(getOrganizations));
        }
        catch (err) {
            throw err;
        }
    }

    public static GetProviderAdditionalInfoGQCall = async (provider: ProviderModel): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(getAdditionalDetailsForProvider, { Id: provider.Id, TrainingType: provider.Type }));
        }
        catch (err) {
            throw err;
        }
    }

    public static ForceProviderSyncGQCall = async (provider: ProviderModel, ciidString: string): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(sync, { Id: provider.Id, TrainingType: provider.Type, ForceSync: provider.ForceSync, LastSyncDate: provider.LastSuccessfulModifiedDateCourse, Ciid: ciidString }));
        }
        catch (err) {
            throw err;
        }
    }

    public static UpsertProviderGQCall = async (providerUpsertModel: UpsertProviderModel): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(upsertProvider, { provider: providerUpsertModel }));
        }
        catch (err) {
            throw err;
        }
    }

    public static GetSettingsGQCall = async (provider: ProviderModel): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(getSettings, { Id: provider.Id, TrainingType: provider.Type }));
        }
        catch (err) {
            throw err;
        }
    }


    public static GetPersignedUrl = async (BundleID: string,BundleName:string, Gcids: string[], SourceType: string, ResultsInEmail:boolean, EmailAddress:string, bundleIds: string[]): Promise<any> => {
        try {
            let request = { BundleID: BundleID, BundleName: BundleName, Gcids: Gcids, SourceType: SourceType, ResultsInEmail: ResultsInEmail, EmailAddress: EmailAddress, BundleIds: bundleIds }
            let res = API.graphql(graphqlOperation(getPersignedUrl, request));            
            return res;
        }
        catch (err) {
            throw err;
        }

    }

    public static UpsertSettingsGQCall = async (providerToolData: ProviderToolData): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(upsertSettings, { setting: providerToolData }));
        }
        catch (err) {
            throw err;
        }
    }

    public static SubscribeGQCall = async (subscription: SubscriptionInput): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(subscribeGQCall, { subscription: subscription }));
        }
        catch (err) {
            throw err;
        }
    }

    public static DirectSubscribeGQCall = async (directSubscription: DirectSubscriptionInput): Promise<any> => {
        try {
            return await API.graphql(graphqlOperation(directSubscribeQGCall, { directSubscription: directSubscription }));
        }
        catch (err) {
            throw err;
        }
    }



    public static FetchPackagesByQuery = async (packageQueryParameter: PackageQueryParameter): Promise<any> => {
        try {
            console.log("inside fetchPackagesByQuery ->", packageQueryParameter)
            let apiResult = await API.graphql(graphqlOperation(fetchPackagesWithQuery, packageQueryParameter));
            console.log("api result: ", apiResult)
            return apiResult;
        }
        catch (err) {
            throw err;
        }
    }
    public static FetchProviderDataByQuery = async (): Promise<any> => {
        try {
            console.log("inside FetchProviderDataByQuery");
            let apiResult = await API.graphql(graphqlOperation(getCurrentList));
            console.log("api result: ", apiResult)
            return apiResult;
        }
        catch (err) {
            throw err;
        }
    }

    public static FetchProviderStatesById = async (id: string, ciid: string): Promise<any> => {
        try {
            console.log("inside FetchProviderStatesById");
            let apiResult = await API.graphql(graphqlOperation(providerDataList, { Id: id, Ciid: ciid }));
            console.log("api result: ", apiResult)
            return apiResult;
        }
        catch (err) {
            console.log("Error!", err)
            throw err;
        }
    }


    public static UpdateCCMSBundle = async (params): Promise<any> => {
        try {

            console.log("inside FetchProviderDataByQuery");
            let apiResult = await API.graphql(graphqlOperation(UpdateCCMSBundle, params));
            console.log("api result: ", apiResult)
            return apiResult;
        }
        catch (err) {
            throw err;
        }
    }

    public static GetCuratedBundles = async (): Promise<any> => {
        try {
            var data = {"OfficialCCaBundlesOnly":true}
            return await API.graphql(graphqlOperation(getBundles,data));
        }
        catch (err) {
            throw err;
        }
    }

    public static GetCuratedCoursesAsUrl = async (BundleId: string, Gcids: string[]): Promise<any> => {
        try {

            if (BundleId == null && Gcids.length == 0)
                return

            var data = { "BundleId": BundleId, "Gcids": null }
            console.log("GetCuratedCoursesAsUrl starting with data:", data);
            let apiResponse = await API.graphql(graphqlOperation(getCuratedCoursesAsUrl, data));
            var courses = await HttpHelper.SendGetRequest(apiResponse.data.curatedCoursesAsUrl);
            console.log(courses)
            return courses;
        }
        catch (err) {
            throw err;
        }
    }

    public static FetchOrgCuratedCourses  = async (orgId: string): Promise<any> =>{
        try
        {
            if (orgId == null)
                return;
            let data = { "OrgId": orgId}
            let res = await API.graphql(graphqlOperation(fetchCoursesPerOrg, data));
            return res;
        }
        catch (err) {
            throw err;
        }
    }

    public static GetCuratedCourses = async (BundleId: string, Gcids: string[]): Promise<any> => {
        try {
            if (BundleId == null && Gcids.length == 0)
                return

            var data = { "BundleId": BundleId, "Gcids": Gcids }
            let res = await API.graphql(graphqlOperation(getCuratedCourses, data));
            return res;
        }
        catch (err) {
            throw err;
        }
    }


}

