import React, { Component } from 'react';
import "./DropdownComponent.css";
import { InputWidthTypes } from '../../../helpers/common/StyleHelper';
import CodeHelpers from '../../../helpers/common/CodeHelpers';

interface DropdownComponentProps {
    items: string[],
    title: string,
    placeholder: string,
    important: boolean,
    widthClass: InputWidthTypes,
    errorMessage?: string,
    onValueChanged: (value: string) => void,
    defaultValue?: string,
    isHeaderHidden?: boolean,
    additionalClass?: string,
    id?: string,
    cy_data?: string,
    disabled?: boolean
}

interface DropdownComponentState {
    isActivated: boolean
}

export default class DropdownComponent extends Component<DropdownComponentProps, DropdownComponentState> {
    
    constructor(props: DropdownComponentProps) {
        super(props);
        this.state = { isActivated: false };
    }
    
    componentDidMount = () => {
        window.addEventListener('scroll',(event)=>{
            this.onBlur(event);
        });
        window.onscroll = this.onBlur;
    }

    private onBlur = (event: any) => {
        if(this.state.isActivated) {
            this.onClicked(event);
        }
    }

    private onClicked = (event: any) => {
        event.preventDefault();
        this.setState({isActivated: !this.state.isActivated});
    }

    private onChanged = (event: any) => {
        this.props.onValueChanged(event.target.value);
    }

    render() {
        return <div className = {`csod-inputs-column-container ${this.props.additionalClass!} ${this.props.widthClass}`} cy-data={this.props.cy_data}>
            {!this.props.isHeaderHidden ? <div className = "csod-input-title-text non-selectable">{this.props.title + `${this.props.important?" *" : ""}`}</div> : null}
            <div className={`csod-input-dropdown ${!CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage) ? "error-border" : null} ${this.state.isActivated? "active-item" : ""}`}>
                <select className={!CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage)? "has-error" : ""} id={this.props.id!} disabled={this.props.disabled}
                onClick={this.onClicked} onBlur = {this.onBlur} defaultValue={`${this.props.defaultValue == null? "" : this.props.defaultValue}`} 
                required onChange={this.onChanged}>
                    <option value="" disabled hidden>{this.props.placeholder}</option>
                    {this.props.items.map((item)=> {
                        return <option key={item} value={item}>{item}</option>
                    })}
                </select>
            </div>
            {!CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage) ? <div className = "error-text-color">{this.props.errorMessage}</div> : null}
        </div>
    }
}