export default class PackagesConstants {
    public static readonly ITEMS_PER_PAGE_COUNT = 100;
    public static readonly PACKAGES_GRID_BUNDLE_ID_TITLE: string = "BundleID";
    public static readonly PACKAGES_GRID_CIID_TITLE: string = "CIID";
    public static readonly PACKAGES_GRID_GCID_TITLE: string = "Global Content ID";
    public static readonly PACKAGES_GRID_PACKAGE_ID_TITLE: string = "PackageID";
    public static readonly PACKAGES_GRID_PROVIDER_ID_TITLE: string = "ProviderID";
    public static readonly PACKAGES_GRID_TRAINING_TITLE: string = "Training Type";
    public static readonly PACKAGES_GRID_SUBSCRIBER_URI_TITLE: string = "Subscriber Uri";
    public static readonly PACKAGES_GRID_PROCESS_SOURCE: string = "Process Source";
    public static readonly PACKAGES_GRID_CREATE_DATE: string = "Create Date";
}