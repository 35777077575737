import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './PackagesGridComponent.css';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import React from 'react';
import PackagesGridViewModel from '../../models/PackagesGrid/PackagesGridViewModel';
import PaginationComponent from '../providers/PagintationComponent';
import PackagesStore from '../../stores/PackagesStore';
import { RouterStore } from 'mobx-react-router';
import PackagesConstants from '../../constants/packagesConstants';
import DropdownComponent from '../common/inputs/DropdownComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import PackageItem from './PackageItem'
import PackagesModel from '../../models/PackageDetails/PackageModel'
import { PACKAGES_NAME_CY_DATA, PACKAGES_FILTER_CY_DATA, PACKAGES_GRID_CY_DATA, PACKAGES_GRID_PAGINATION_CY_DATA } from '../../cypressDataConstants/packagesDetailsCyDataConstants';
import { CaughtException } from 'mobx/lib/internal';
import TextInputComponent from '../common/inputs/TextInputComponent';
import PackageQueryParameter from "../../models/TestSubscription/PackageQueryParameter";
import PackageConstants from '../../constants/packagesConstants';
import GraphQLHelper from '../../helpers/common/GraphQLHelper'
import { _allowStateChangesInsideComputed } from 'mobx';
interface PackagesGridComponentProps {
    globalLoader?: GlobalLoaderStore,
    routing?: RouterStore,
    packages: PackagesStore,
}

interface PackagesGridComponentState {
    filterString: string,
    appliedFilterString: string,
    redirectToFramework: boolean,
    hasError: boolean,
    textInputError: string,
    queryField: string,
    queryValue: string,
    startDate: string,
    endDate: string,
    resultData: any
}

interface ApiPackageResult {
    data: {
        fetcher: [{
            CIID: string
            CreateDate: string
            GlobalContentID: string
            Id: string
            PackageID: string
            ProcessSource: string
            ProviderID: string
            SubscriberUri: string,
            BundleID: string
        }]
    }
}
const fieldNames: string[] = ["BundleID", "CIID", "GlobalContentID", "PackageID", "ProviderID", "SubscriberUri"]


@inject('packages', 'globalLoader', 'routing')
@observer
export default class PackagesGridComponent extends Component<PackagesGridComponentProps, PackagesGridComponentState> {
    constructor(props: PackagesGridComponentProps) {
        super(props);
            let pastDate = new Date(); pastDate.setFullYear(pastDate.getFullYear() - 1);
            this.state = {
                filterString: "",
                appliedFilterString: "",
                redirectToFramework: false,
                hasError: false,
                textInputError: '',
                queryField: "GlobalContentID",
                queryValue: "",
                startDate: pastDate.toISOString().substring(0, 19),
                endDate: new Date().toISOString().substring(0, 19),
                resultData: null
            }
        }

    componentDidMount() {
      
    }
    componentDidUpdate() {
      
    }

    isValidDate = (d: string) => {
        try {
            let date = new Date(d);
            return date instanceof Date && d;
        } catch (error) {
            return false
        }
    }

    onQueryFilterChange = (event: string) => {
        this.setState({ queryField: event })
    }
    onQueryFilterValueChange = (event: string) => {
        this.setState({ queryValue: event })
    }
    onSubmitClick = () => {
        if (!this.state.queryValue) {  alert("Query value is invalid."); return;}
        if (!this.isValidDate(this.state.startDate) || !this.isValidDate(this.state.endDate)) { alert("Date is invalid"); return }

        let query: PackageQueryParameter = {
            QueryParameter: this.state.queryValue,
            QueryParameterColName: this.state.queryField,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate
        }
        this.props.globalLoader!.startLoading();
        GraphQLHelper.FetchPackagesByQuery(query).then(_data => {
            var res = this.mapResultsToPackageGridViewModelObject(_data);
            console.log("list:", res);
            this.setState({ resultData: res })
        }).catch(err => {
            console.log("Error!", err)
        }).finally(()=> this.props.globalLoader!.finishLoading());
    }
    mapResultsToPackageGridViewModelObject = (data: ApiPackageResult) => {
        var result: PackagesGridViewModel[] = []
        this.props.packages.Packages= data.data.fetcher;
        this.props.packages.TotalPages = Math.ceil(this.props.packages.Packages.length / PackageConstants.ITEMS_PER_PAGE_COUNT);
        data.data.fetcher.forEach(item => {
            result.push(new PackagesGridViewModel(item.Id, item.BundleID, item.GlobalContentID, item.SubscriberUri, item.ProviderID,
                item.PackageID, item.CIID, item.ProcessSource, item.CreateDate))
        });
        return result;
    }
    onStartDateChange = (event: string) => {
        if(event)
            this.setState({ startDate: event })
    }
    onEndDateChange = (event: string) => {
        if(event)
            this.setState({ endDate: event })
    }

    sortPackagesByCIID = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ? -(a.CIID!.localeCompare(b.CIID!)) : (a.CIID!.localeCompare(b.CIID!)) });
    }

    sortPackagesById = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ? -(a.Id!.localeCompare(b.Id!)) : (a.Id!.localeCompare(b.Id!)) });
    }

    sortPackagesByGCID = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ? -(a.GlobalContentID!.localeCompare(b.GlobalContentID!)) : (a.GlobalContentID!.localeCompare(b.GlobalContentID!)) });
    }

    sortPackagesByPackageId = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ? -(a.PackageID!.localeCompare(b.PackageID!)) : (a.PackageID!.localeCompare(b.PackageID!)) });
    }

    sortPackagesByProviderId = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ? -(a.ProviderID!.localeCompare(b.ProviderID!)) : (a.ProviderID!.localeCompare(b.ProviderID!)) });
    }
    sortPackagesBysubscriberUri = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ?
            -(a.SubscriberUri!.localeCompare(b.SubscriberUri!)) :  (a.SubscriberUri!.localeCompare(b.SubscriberUri!)) });
    }
    sortPackagesByProcessSource = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ? -(a.ProcessSource!.localeCompare(b.ProcessSource!)) : (a.ProcessSource!.localeCompare(b.ProcessSource!)) });
    }
    sortPackagesByBundleID = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ? -(a.BundleID!.localeCompare(b.BundleID!)) : (a.BundleID!.localeCompare(b.BundleID!)) });
    }
    sortPackagesByCreateDate = (isDescending: boolean) => {
        this.props.packages!.sortPackages((a, b) => { return isDescending ? -(a.CreateDate!.localeCompare(b.CreateDate!)) : (a.CreateDate!.localeCompare(b.CreateDate!)) });
    }
    handleOnChangePage = (newCurrentPage: number): Promise<any> => {
        return new Promise<any>((resolve) => {
            this.props.packages!.CurrentPage = newCurrentPage;
            resolve(null);
        })
    }


    onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            this.setState({ filterString: "", appliedFilterString: "" });
        } else {
            this.setState({ filterString: e.target.value });
        }
    }

    ResetCurrentSort = (idString: string) => {
        var elements = document.getElementsByClassName("sortable");
        while (elements.length)
            elements[0].classList.remove("sortable");

        var sortTarget = document.getElementById(idString);
        sortTarget?.classList.add("sortable");
    }

    renderGridHeaderItem = (title: string, onSort: (isDescending: boolean) => void) => {
        return <th scope='col'>
            <div className="csod-grid-header-container">
                {title}
                <div className="csod-sorting-container">

                    <div id={title + 'up'} className="csod-sort-up " onClick={() => {
                        onSort(true);
                        this.ResetCurrentSort(title + 'up')
                    }}><div className="csod-icon-sort-up">▲</div></div>
                    <div id={title + 'down'} className="csod-sort-down" onClick={() => {
                        onSort(false)
                        this.ResetCurrentSort(title + 'down')
                    }}><div className="csod-icon-sort-down">▼</div></div>
                </div>
            </div>
        </th>
    }
    static getDerivedStateFromError(error: CaughtException) {
        return { hasError: true };
    }

    render() {
        if (this.props.globalLoader!.isLoading) {
            return null;
        }
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        var packagesList:PackagesModel[] = this.props.packages?.Packages;
        var currentPage = this.props.packages!.CurrentPage;
        console.log("Total Packages: ", packagesList.length)
        var lastItemPerCurrentPageNumber = (this.props.packages!.CurrentPage - 1) * PackagesConstants.ITEMS_PER_PAGE_COUNT + PackagesConstants.ITEMS_PER_PAGE_COUNT;
        if (lastItemPerCurrentPageNumber > this.props.packages!.Packages!.length) {
            lastItemPerCurrentPageNumber = this.props.packages!.Packages!.length;
        }

        return  <div className="csod-packages-container">
                    <div className="csod-search-container">
                        <div className="csod-packages-count-title">Content Packages </div>
                        <div className="csod-search-container-query">
                            <DropdownComponent
                                title="Package Filter"
                                placeholder="Choose Package Filter"
                                items={fieldNames}
                                widthClass={InputWidthTypes.Large}
                                onValueChanged={(e) => this.onQueryFilterChange(e)}
                                important={false}
                                cy_data={PACKAGES_FILTER_CY_DATA}
                                defaultValue={this.state.queryField}
                            />
                            <TextInputComponent title="Query"
                                placeholder="Insert Query Text"
                                widthClass={InputWidthTypes.Small}
                                defaultValue={this.state.queryValue}
                                important={false}
                                errorMessage={this.state.textInputError}
                                onValueChanged={(e) => this.onQueryFilterValueChange(e)}
                            />

                            <div className="csod-inputs-column-container">
                                <TextInputComponent title="Start Date"
                                    defaultValue={this.state.startDate}
                                    type="datetime-local"
                                    widthClass={InputWidthTypes.Small} important={false}
                                    onValueChanged={value => this.onStartDateChange(value)} />
                            </div>
                            <div className="csod-inputs-column-container">
                                <TextInputComponent title="End Date"
                                    defaultValue={this.state.endDate}
                                    type="datetime-local"
                                    widthClass={InputWidthTypes.Small} important={false}
                                    onValueChanged={value => this.onEndDateChange(value)} />
                            </div>
                            <div className="csod-inputs-column-container">
                                <button type="button" className="csod-button less-rounded-corners blue query-set-width" onClick={this.onSubmitClick}>Query Packages</button>
                            </div>

                        </div>

                        {this.props.packages.Packages.length === 0 ? 'No results.' :
                            <div>
                                <table className="csod-packages-grid" cy-data={PACKAGES_GRID_CY_DATA}>
                                    <thead>
                                        <tr>
                                            {this.renderGridHeaderItem(PackagesConstants.PACKAGES_GRID_BUNDLE_ID_TITLE, this.sortPackagesByBundleID)}
                                            {this.renderGridHeaderItem(PackagesConstants.PACKAGES_GRID_CIID_TITLE, this.sortPackagesByCIID)}
                                            {this.renderGridHeaderItem(PackagesConstants.PACKAGES_GRID_GCID_TITLE, this.sortPackagesByGCID)}
                                            {this.renderGridHeaderItem(PackagesConstants.PACKAGES_GRID_PACKAGE_ID_TITLE, this.sortPackagesByPackageId)}
                                            {this.renderGridHeaderItem(PackagesConstants.PACKAGES_GRID_PROVIDER_ID_TITLE, this.sortPackagesByProviderId)}
                                            {this.renderGridHeaderItem(PackagesConstants.PACKAGES_GRID_SUBSCRIBER_URI_TITLE, this.sortPackagesBysubscriberUri)}
                                            {this.renderGridHeaderItem(PackagesConstants.PACKAGES_GRID_PROCESS_SOURCE, this.sortPackagesByProcessSource)}
                                            {this.renderGridHeaderItem(PackagesConstants.PACKAGES_GRID_CREATE_DATE, this.sortPackagesByCreateDate)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            console.log("current pagE: " + (this.props.packages!.CurrentPage - 1))
                                        }
                                        {
                                            packagesList.slice((this.props.packages!.CurrentPage - 1) * PackagesConstants.ITEMS_PER_PAGE_COUNT, lastItemPerCurrentPageNumber)
                                                .map((item) => { return <PackageItem Package={item} key={item.Id} RedirectToUpdate={() => { }} /> })
                                        }
                                    </tbody>
                                </table>
                                {this.props.packages.Packages!.length <= PackagesConstants.ITEMS_PER_PAGE_COUNT ? null : <PaginationComponent currentPageIndex={currentPage} listPages={this.props.packages!.TotalPages} handleChangePage={this.handleOnChangePage} cy_data={PACKAGES_GRID_PAGINATION_CY_DATA} />}
                            </div>}
                    </div>
                </div>
    }
}