export default class FrameworkConstants {
    public static TAXONOMY_ERROR_MESSAGE: string = "Please enter valid JSON string";
    public static VALIDATION_API_URL: string = "https://a4s6ze2tmvf3ravbkzi6swjedq0pedot.lambda-url.us-east-1.on.aws/"
    public static CCMS_MEGA_BUNDLE_ID_dev: string =  "11111111-1111-1111-1111-123456789abc"; 
    public static CCMS_MEGA_BUNDLE_ID_stge: string = "c97874d3-6f78-4e19-91b3-c8a30223f0d3";
    public static CCMS_MEGA_BUNDLE_ID_prod: string = "e352f976-c7b1-46d3-a55b-30b966bcc4cb";
    public static UPSERT_PROVIDER_PHASE_STRING: string = "Provider details and SSO";
    public static SETTINGS_PHASE_STRING: string = "Provider settings";
    public static SUBSCRIBE_PHASE_STRING: string = "Subscribing Test Portals";
    public static ADD_TO_MEGABUNDLE_PHASE_STRING: string = "Adding Provider to CCMS";
    public static SUBSCRIPTION_BUNDLE_ID: string = "00000000-0000-0000-0000-123456789abc";
}