import React, { Component } from 'react';
import "./LoaderComponent.css";

interface LoaderComponentProps {
    isLoading: boolean
}

export default class LoaderComponent extends Component<LoaderComponentProps> {
    render() {
        return this.props.isLoading? (
            <div className="lds-dual-ring"/>
        ) : null;
    }
}