import './PaginationComponent.css'
import React, { Component } from 'react';

interface PaginationComponentState {
    currentPageIndex: number,
    disabled: boolean
}

interface PaginationComponentProps {
    currentPageIndex: number,
    listPages: number,
    handleChangePage: (newCurrentPage: number) => Promise<any>,
    cy_data?: string
}


export default class PaginationComponent extends Component<PaginationComponentProps, PaginationComponentState> {

    constructor(props: PaginationComponentProps) {
        super(props);
        this.state = {
            currentPageIndex: this.props.currentPageIndex,
            disabled: false
        }
    }

    onChangeCurrentPage = (newCurrentPage: number) => {
        this.setState({disabled:true});
        this.props.handleChangePage(newCurrentPage).then(()=>{
            this.setState({
                currentPageIndex: newCurrentPage,
                disabled: false
            });
        });
    }

    calculateIndex(): number {
        let currentIndex = this.state.currentPageIndex - 1;
        if (currentIndex - 2 >= 0) {
            currentIndex = currentIndex - 2;
        }
        else {
            currentIndex = 0;
        }

        if (currentIndex + 4 >= this.props.listPages) {
            currentIndex = this.props.listPages - 5;
        }
        return currentIndex;
    }

    onPaginationButtonClick = (pageToGo: number) => {
        return () => {
            this.onChangeCurrentPage(pageToGo);
        }
    }

    render() {
        
        let currentIndex = this.calculateIndex();

        let iterator = 0;
        return <ul className='pagination sticky-bottom non-selectable' cy-data={this.props.cy_data}>
            <li className={'page-item ' + (this.state.currentPageIndex <= 1 || this.state.disabled ? 'disabled' : '')}>
                <button className='page-link' onClick={this.onPaginationButtonClick(1)}>
                    First
                </button>
            </li>
            <li className={'page-item ' + (this.state.currentPageIndex <= 1 || this.state.disabled ? 'disabled' : '')}>
                <button className='page-link' onClick={this.onPaginationButtonClick(this.state.currentPageIndex - 1)}>
                    <span aria-hidden='true'>«</span>
                </button>
            </li>
            {Array.from(Array(this.props.listPages + 1).keys()).slice(1).map((item, index) => {
                if (index >= currentIndex && iterator < 5) {
                    iterator++;
                    return <li key={item} className={'page-item ' + (this.state.disabled? 'disabled' : (item === this.state.currentPageIndex ? 'active' : ''))}>
                        <button className='page-link' onClick={this.state.disabled ? ()=> {} : this.onPaginationButtonClick(item)}>{item}</button>
                    </li>
                }
                return null;
            })}
            <li className={'page-item ' + (this.state.currentPageIndex > this.props.listPages - 1 || this.state.disabled ? 'disabled' : '')}>
                <button className='page-link' onClick={this.onPaginationButtonClick(this.state.currentPageIndex + 1)}>
                    <span aria-hidden='true'>»</span>
                </button>
            </li>
            <li className={'page-item ' + (this.state.currentPageIndex > this.props.listPages - 1 || this.state.disabled ? 'disabled' : '')}>
                <button className='page-link' onClick={this.onPaginationButtonClick(this.props.listPages)}>
                    Last
                </button>
            </li>
        </ul >
    }
}