import { observable, action } from 'mobx';
import GraphQLHelper from '../helpers/common/GraphQLHelper';
import { FrameworkModes } from '../models/ProviderFramework/FrameworkMode';
import TestingFrameworkItemModel from '../models/ProviderFramework/TestingFrameworkItemModel';
import BasicAuthConfig from '../models/ProviderDetails/BasicAuthConfig';
import Oauth2Config from '../models/ProviderDetails/Oauth2Config';
import ProviderDetailsExpansion from '../models/ProviderDetails/ProviderDetailsExpansion';
import ProviderGridViewModel from '../models/ProvidersGrid/ProviderGridViewModel';
import ProviderModel from '../models/ProviderDetails/ProviderModel';
import SSOConfigModel from '../models/SSOConfig/SSOConfigModel';
import { TestSubscriptionOption } from '../models/TestSubscription/TestSubscriptionOption';
import ProviderConstants from '../constants/providersconstants';
import ApiKeyConfig from '../models/ProviderDetails/ApiKeyConfig'

export const DEFAULT_TEST_SUBSCRIPTION_CONFIG: {[id: string]: TestingFrameworkItemModel} = {
    [TestSubscriptionOption.TestPortal1] : new TestingFrameworkItemModel(
        "CSOD Test Portal 1", 
        process.env.REACT_APP_TEST_PORTAL_FIRST_URL!
    ),
    [TestSubscriptionOption.TestPortal2] : new TestingFrameworkItemModel(
        "CSOD Test Portal 2", 
        process.env.REACT_APP_TEST_PORTAL_SECOND_URL!
    ),
    [TestSubscriptionOption.CSODTestPortal]: new TestingFrameworkItemModel(
        "CSOD Test Portal ", 
        process.env.REACT_APP_TEST_PORTAL_ONE_TO_MANY_URL!,
        false
    ),
    [TestSubscriptionOption.SabaTestPortal]:  new TestingFrameworkItemModel(
        "Saba Test Portal ", 
        process.env.REACT_APP_SABA_TEST_PORTAL_URL!,
        false
    )
}

export default class ProviderStore {
    @observable Providers: ProviderModel[] = observable([]);
    @observable CurrentProvider: ProviderModel | null = null;
    @observable CurrentOAuth2Config: Oauth2Config = new Oauth2Config();
    @observable CurrentBasicAuthConfig: BasicAuthConfig | null = new BasicAuthConfig();
    @observable CurrentApiKeyConfig: ApiKeyConfig | null = new ApiKeyConfig();
    @observable CurrentProgressApiConfig: BasicAuthConfig | null = new BasicAuthConfig();
    @observable CurrentProviderDetailsExpansion: ProviderDetailsExpansion = new ProviderDetailsExpansion();
    @observable CurrentTestSubscriptionConfig: {[id: string]: TestingFrameworkItemModel} = DEFAULT_TEST_SUBSCRIPTION_CONFIG;
    @observable CurrentSSOConfigModel: SSOConfigModel = new SSOConfigModel();
    @observable CurrentPage: number = 1;
    @observable LastModifiedBy: String | null = null;
    @observable ProviderModified: String | null = null;
    @observable TotalPages: number = 1; 
    @observable FrameworkMode: FrameworkModes = FrameworkModes.Create;
    @observable CustomCiid: string=""

    @action
    loadProviders = async() => {                
        let res =  await GraphQLHelper.GetProvidersGQCall();
        let internalRes =  await GraphQLHelper.FetchProviderDataByQuery();
        if(res != null){
            let LAST_10_DAY_DATE = new Date(new Date().setDate(new Date().getDate()-10));                
                res.data.providers.forEach(element => {
                    var prState = internalRes.data.currentData.filter(function(itm) {
                        return itm.ProviderId === element.Id; 
                    });
                    let latestDate = prState.length > 0 ?  prState.reduce((a, b) => (a.LastSuccessfulSyncDate > b.LastSuccessfulSyncDate ? a : b)).LastSuccessfulSyncDate : null;
                    if(new Date(latestDate) > LAST_10_DAY_DATE) {
                        element.SyncStatus = 'success';          
                    }      
                    else{
                        element.SyncStatus = 'fail';
                    }
                });
                this.Providers = res.data.providers;
                this.TotalPages = Math.ceil(this.Providers.length / ProviderConstants.ITEMS_PER_PAGE_COUNT);

        }

    }

    cleanProviderData = () => {
        this.CurrentBasicAuthConfig = new BasicAuthConfig();
        this.CurrentOAuth2Config = new Oauth2Config();
        this.CurrentApiKeyConfig = new ApiKeyConfig();
        this.CurrentProvider = null;
        this.CurrentProviderDetailsExpansion = new ProviderDetailsExpansion();        
        this.CurrentSSOConfigModel = new SSOConfigModel();
        this.CurrentTestSubscriptionConfig = DEFAULT_TEST_SUBSCRIPTION_CONFIG;
    }

    @action
    loadCurrentProvider = (provider: ProviderGridViewModel) => {
        this.CurrentProvider = this.Providers.filter(p => p.Id === provider.ID && p.Type === provider.ProviderTrainingType)[0];
    }

    @action
    setNewProvider = (provider: ProviderModel | null) => {
        this.CurrentProvider = provider;
    }

    @action
    getProvider = (providerId: string) => {
        return this.Providers.find(p=>p.Id === providerId);
    }

    @action
    sortProviders = (compareFn?: ((a: ProviderModel, b: ProviderModel) => number) | undefined) => {
        this.Providers = this.Providers.slice().sort(compareFn);
    }
}