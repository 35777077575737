import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import OrganizationStore from '../../stores/OrganizationStore';
import { Auth } from '@aws-amplify/auth';
import { Link, Redirect } from 'react-router-dom';
import SwitcherComponent from '../common/inputs/SwitcherComponent';
import PopupComponent, { ErrorPopupComponent, ErrorPopupWithGridComponent, GenericSuccessPopupComponent, ErrorPopupWithTextAreaComponent, PhaseCondition, SuccessPopupComponent, ValidationSuccessPopupComponent, WarningPopupComponent, FullSyncWarningPopupComponent } from '../common/popups/PopupComponent';
import SSOConfiguration from './SSOConfiguration';
import OrganizationDetails from './OrganizationDetails'
import OrganizationDetailsErrorModel from '../../models/Organizations/OrganizationErrorModel';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import LargeTextboxComponent from '../common/inputs/LargeTextboxComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import FrameworkValidationService from '../../helpers/framework/FrameworkValidationService';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import LoaderComponent from '../common/LoaderComponent';
import "./FrameworkComponent.css";
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import OrganizationModel, { OrgModifications } from '../../models/Organizations/OrganizationModel'
import ValidationApiErrorModel from '../../models/ProviderDetails/ValidationApiErrorModel';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import { v4 } from 'uuid';
import FrameworkConstants from '../../constants/frameworkConstants';
import { ORGANIZATIONS_PATH } from '../../Settings';
enum TabNames {
    Organization = "Organization",
    Sso = "Sso",
    Authentication = "Authentication",
    XApi = "XApi"
}

export enum PopupTypes {
    None,
    Success,
    Error,
    SavingError,
    Warning,
    FullSyncWarning,
    TaxonomyEdit,
    TestEndpointErrorWithGrid,
    TestEndpointErrorWithTextArea,
    TestEndpointSuccess,
    SyncSuccess,
    SyncFailure,
    MissingCIID
}

interface FrameworkComponentProps {
    organizations?: OrganizationStore,
    globalLoader?: GlobalLoaderStore
}

interface FrameworkComponentState {
    CurrentTab: TabNames,
    CurrentPopup: PopupTypes,
    IsRedirectingToOrganizationsGrid: boolean,
    IsSubmitRequestPosting: boolean,
    isScrolledToProblemElement: boolean,
    OldOrganization: any,
    OrganizationErrorMessages: OrganizationDetailsErrorModel | null,
    // SsoErrorMessages: SsoErrorModel | null,
    // XApiErrorMessages: XApiErrorModel | null,
    // AuthenticationErrorMessages: AuthenticationErrorModel | null,
    ErrorPopupMessages: string[] | ValidationApiErrorModel[],
}



@inject("organizations", "globalLoader")
@observer
export default class FrameworkComponent extends Component<FrameworkComponentProps, FrameworkComponentState> {
    private taxonomyValue: string = "";
    private phaseConditions: PhaseCondition[] = [];

    constructor(props: FrameworkComponentProps) {
        super(props);
        this.state = {
            CurrentTab: TabNames.Organization,
            CurrentPopup: PopupTypes.None,
            IsRedirectingToOrganizationsGrid: false,
            IsSubmitRequestPosting: false,
            isScrolledToProblemElement: true,
            OldOrganization: JSON.parse(JSON.stringify(this.props.organizations?.CurrentOrganization)),
            OrganizationErrorMessages: new OrganizationDetailsErrorModel(),
            // ProviderDetailsErrorMessages: new ProviderDetailsErrorModel(),
            // SSOConfigErrorMessages: new SSOConfigErrorModel(),
            // TestSubscriptionErrorMessages: {},
            // AdditionalSettingsErrorMessages: {},
            // QuerystringParametersErrorMessages: {},
            // S3Url: "",
            ErrorPopupMessages: [],
            // IsForceSyncRequestPosting: false,
            // ShowForceSync: true,
            // IsTestEnpointRequestPosting: false,
            // taxonomyErrorMessage: "",
        }
    }

    onScrolledToProblemElement = () => {
        this.setState({ isScrolledToProblemElement: true });
    }

    getGoToTabFunction = (tab: TabNames) => {
        return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();
            if (this.state.CurrentTab !== tab) {
                this.setState({ CurrentTab: tab });
            }
        }
    }

    renderTab(tab: TabNames, cy_data?: string) {
        return <div className={`csod-tab-item non-selectable ${this.state.CurrentTab === tab ? "active-item gray-text" : "blue-text"}`} onClick={this.getGoToTabFunction(tab)} cy-data={cy_data}>{tab}</div>
    }

    finishSubmitProcess = (renderPopup: PopupTypes, errors?: any) => {
        this.setState({
            ErrorPopupMessages: errors && errors.errors ? errors.errors.map((i: any) => i.message) : [],
            CurrentPopup: renderPopup,
            IsSubmitRequestPosting: false
        })
    }
    populateOrgChange = () => {
        if(this.state.OldOrganization?.Name){
            return (this.findChangedProperties(this.state.OldOrganization, this.props.organizations?.CurrentOrganization));
        }
    }

    deepCompare(obj1:any, obj2:any) {
        // Check if both arguments are objects
        if (typeof obj1 === 'object' && typeof obj2 === 'object' && obj1 !== null && obj2 !== null) {
            // Get the keys of both objects
            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);
    
            // Check if the number of keys is the same
            if (keys1.length !== keys2.length) {
                return false;
            }
    
            // Check if all keys in obj1 exist in obj2 and have the same value
            for (let key of keys1) {
                if (!obj2.hasOwnProperty(key) || !this.deepCompare(obj1[key], obj2[key])) {
                    return false;
                }
            }
    
            // If all checks passed, the objects are considered equal
            return true;
        } else {
            // Perform simple comparison for non-object types
            return obj1 === obj2;
        }
    }
    
    findChangedProperties(obj1:any, obj2:any) {
        const changedProperties:any = {};
    
        // Iterate over all properties in obj1
        for (let key in obj1) {
            // Check if the property exists in obj2
            if (obj2.hasOwnProperty(key)) {
                // Check if the values are different
                if (!this.deepCompare(obj1[key], obj2[key])) {
                    // Add the property to the changedProperties dictionary
                    changedProperties[key] = {
                        oldValue: obj1[key],
                        newValue: obj2[key]
                    };
                }
            } else {
                // If the property doesn't exist in obj2, add it to changedProperties
                changedProperties[key] = {
                    oldValue: obj1[key],
                    newValue: undefined
                };
            }
        }
    
        // Iterate over all properties in obj2 to find any additional properties
        for (let key in obj2) {
            // Check if the property exists in obj1
            if (!obj1.hasOwnProperty(key)) {
                // Add the property to the changedProperties dictionary
                changedProperties[key] = {
                    oldValue: undefined,
                    newValue: obj2[key]
                };
            }
        }
    
        return changedProperties;
    }
    

    onSubmitClick = () => {
        this.setState({ IsSubmitRequestPosting: true });
        var orgChanges = this.populateOrgChange();
        if(this.props.organizations!.CurrentOrganization!.AiccUrl != null)
            this.props.organizations!.CurrentOrganization!.AiccUrl = this.props.organizations!.CurrentOrganization!.AiccUrl.map(url => {
                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                return 'https://' + url;
                }
                return url;
            })
        var organizationErrorrMessage = FrameworkValidationService.OrganizationDataValidate(this.props.organizations?.CurrentOrganization, this.props.organizations?.Organizations, this.props.organizations?.FrameworkMode!);

        if (organizationErrorrMessage != null) {
            this.setState({
                isScrolledToProblemElement: false,
                OrganizationErrorMessages: organizationErrorrMessage,
                IsSubmitRequestPosting: false
            })
        }
        else {
            var modified = new OrgModifications(Auth.Credentials.Auth.user.attributes.email,new Date().toLocaleString("en-US"));
            //Overloading lastModify to string containing the changes themselves.
            modified.LastModifiedBy = modified.LastModifiedBy + ' '  + JSON.stringify({'Changes':orgChanges });
            if (!this.props.organizations!.CurrentOrganization!.MetadataLanguages)
                this.props.organizations!.CurrentOrganization.MetadataLanguages = [];

            if(!this.props.organizations!.CurrentOrganization!.OrganizationModifications)
                this.props.organizations!.CurrentOrganization!.OrganizationModifications = [];

            if(this.props.organizations!.CurrentOrganization!.OrganizationModifications?.length > 4){
                this.props.organizations.CurrentOrganization.OrganizationModifications.replace(this.props.organizations.CurrentOrganization.OrganizationModifications?.sort((a,b) => new Date(a.OrganizationModified).getTime() - new Date(b.OrganizationModified).getTime()));
                this.props.organizations.CurrentOrganization.OrganizationModifications[0] = modified;
            }
            else
                this.props.organizations!.CurrentOrganization!.OrganizationModifications.push(modified);
           
            GraphQLHelper.AddOrganizationGQCall(this.props.organizations?.CurrentOrganization)
                .then(() => {
                    if(this.props.organizations?.Organizations.map(x=>x.Id).indexOf(this.props.organizations?.CurrentOrganization.Id) == -1) 
                        this.props.organizations?.Organizations.push(this.props.organizations?.CurrentOrganization);
                    this.finishSubmitProcess(PopupTypes.Success);
                }).catch(err => {
                    this.finishSubmitProcess(PopupTypes.SavingError, err);
                })
            this.setState({});
        }
    }

    closePopup = () => {
        this.setState({ CurrentPopup: PopupTypes.None })
        this.phaseConditions = [];
    }
    
    redirectToOrganizationsGrid = () => {
        this.props.organizations!.CurrentOrganization = new OrganizationModel();
        this.setState({ IsRedirectingToOrganizationsGrid: true })
    }

    onCancelClick = () => {
        this.setState({ CurrentPopup: PopupTypes.Warning })
    }
    
    renderPopup = () => {
        switch (this.state.CurrentPopup) {
            case PopupTypes.Success: return <SuccessPopupComponent FrameworkMode={this.props.organizations?.FrameworkMode} ProviderName={this.props.organizations?.CurrentOrganization?.Name} OnClose={this.redirectToOrganizationsGrid} OnDone={this.redirectToOrganizationsGrid}  />
            case PopupTypes.Error: return <ErrorPopupComponent ErrorMessages={this.state.ErrorPopupMessages as string[]} PhasesConditions={this.phaseConditions} OnClose={this.closePopup} TitleElements={["Organization ", <span key={v4()} className="blue-text">{this.props.organizations?.CurrentOrganization?.Name}</span>, " was saved, but the following errors occured"]}/>
            case PopupTypes.SavingError: return <ErrorPopupComponent ErrorMessages={this.state.ErrorPopupMessages as string[]} OnClose={this.closePopup} TitleElements={["Failed to save organization:  ", <span className="blue-text">{this.props.organizations?.CurrentOrganization?.Name}</span>]} />
            case PopupTypes.Warning: return <WarningPopupComponent OnClose={this.closePopup} OnDone={this.redirectToOrganizationsGrid} />
            default: return null;
        }
    }

    render() {
        const organizations = this.props.organizations;
        return this.props.globalLoader?.isLoading ? null :
            this.state.IsRedirectingToOrganizationsGrid || organizations?.CurrentOrganization == null ? <Redirect to={ORGANIZATIONS_PATH} /> : <div className="csod-framework-container">
                {this.renderPopup()}
                <div className="csod-breadcrumb-container">
                    <Link to={ORGANIZATIONS_PATH} className="csod-breadcrumb-item non-selectable blue-text">Content Organizations</Link>
                    <div className="non-selectable">/</div>
                    <div className="csod-breadcrumb-item non-selectable gray-text">{this.props.organizations?.FrameworkMode === FrameworkModes.Create ? "New Organization" : "Edit Organization"}</div>
                </div>
                <div className="csod-current-tab-title non-selectable">{`${this.props.organizations?.FrameworkMode === FrameworkModes.Create ? "Create" : "Edit"} ${this.state.CurrentTab}`}</div>
                <div className="csod-tabs-container">
                    {this.renderTab(TabNames.Organization)}
                </div>
                <div className="csod-framework-content">
                    {this.state.CurrentTab === TabNames.Organization
                    ? <OrganizationDetails detailsErrorMessages={this.state.OrganizationErrorMessages!}
                     onScrolledToProblemElement={this.onScrolledToProblemElement} 
                     isScrolledToProblemElement={this.state.isScrolledToProblemElement} /> : null}

                </div>
                <div className="csod-inputs-sticky-container">
                    <div className="csod-inputs-container">
                        <div className="csod-inputs-row-container">
                        </div>
                        <div className="csod-inputs-row-container">
                            <button className="csod-button crimson less-rounded-corners" onClick={this.onCancelClick}>Cancel</button>
                            <button type="submit" className="csod-button blue less-rounded-corners" onClick={this.onSubmitClick}>{this.state.IsSubmitRequestPosting ? <LoaderComponent isLoading={true} /> : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>
    }
}