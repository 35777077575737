import NameValueWithTypeAndPositionInput from "./NameValueWithTypeAndPositionInput";
import OutboundSAMLQueryStringParameterInput from "./OutboundSAMLQueryStringParameterInput";
import SSOConfigModel, { OUTBOUND_SSO_URL_PLACEHOLDER } from "./SSOConfigModel";
import { KeyValue } from "../ProviderDetails/UpsertProviderModel";

export default class SsoDataInput {
    constructor(
        public ssoId: number,
        public serviceProvider: string | null,
        public acsUrl: string,
        public algorithm: string = "",
        public timeStampFormat: string,
        public issuer: string,
        public signatureMode: string,
        public isInternal: Boolean,
        public audience: string,
        public expirationDate: Date | null,
        public authenticationParameters: KeyValue[] = [],
        public spInitiated: Boolean,
        public authRequestIssuer: string,
        public authRequestDestination: string,
        public skipSignatureValidation: Boolean,
        public clientPublicCertificate: string | null,
        public signatureWithinAssertion: Boolean,
        public includeIssuerInResponse: Boolean,
        public queryStringParameters: OutboundSAMLQueryStringParameterInput[] = [],
        public additionalSettings: NameValueWithTypeAndPositionInput[] = [],
        public outboundSsoUrl: string
    ) { }

    public static ComposeSsoDatInput = (ssoConfig: SSOConfigModel) => {
        return new SsoDataInput(
            ssoConfig.SSOIDOUID!,
            null,
            ssoConfig.ACSURL!,
            "OutboundSAML 2.0",
            ssoConfig.TimestampFormat!,
            ssoConfig.Issuer!,
            "SHA256",
            ssoConfig.IsInternal,
            ssoConfig.Audience!,
            null,
            ssoConfig.authenticationParameters,
            ssoConfig.IsSpInitiatedEnabled,
            ssoConfig.AuthenticationRequestIssuer,
            OUTBOUND_SSO_URL_PLACEHOLDER+ssoConfig.SSOIDOUID,
            true,
            "",
            ssoConfig.SignatureWithinAssertion,
            ssoConfig.IncludeIssuerInResponse,
            ssoConfig.QuerystringParameters,
            ssoConfig.AdditionalSettings!.filter(i => i != null && i.name),
            OUTBOUND_SSO_URL_PLACEHOLDER+ssoConfig.SSOIDOUID
        )
    }
}
