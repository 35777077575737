import React, { Component, ReactComponentElement } from 'react';
import { RouterStore } from 'mobx-react-router';
import { Switch, Route } from 'react-router-dom';
import HeaderComponent from './components/common/HeaderComponent';
import ProvidersGridComponent from './components/providers/ProvidersGridComponent';
import PackagesGridComponent from './components/packages/PackagesGridComponent';
import EcaBundleGridComponent from './components/ExploreContentAnytime/EcaBundleGridComponent';
import EcaCourseGridComponent from './components/ExploreContentAnytime/EcaCourseGridComponent';
import OrgCcaCoursesGridComponent from './components/ExploreContentAnytime/OrgCcaCoursesGridComponent';
import EcaSubscriptionGridComponent from './components/ExploreContentAnytime/EcaSubscriptionGridComponent';
import ProviderDataGridComponent from './components/ProviderData/ProviderDataGridComponent';
import OrganizationGridComponent  from './components/Organizations/OrganizationGridComponent';
import { inject, observer } from 'mobx-react';

import { PROVIDERS_PAGE_PATH,ORGANIZATION_FRAMWORK_PAGE_PATH, PROVIDER_FRAMEWORK_PAGE_PATH, PACKGES_PAGE_PATH, PROVIDER_DATA_PATH,ORGANIZATIONS_PATH,ECA_BUNDLES_PATH,ECA_COURSES_PATH,ECA_SUBSCRIPTION_PATH,ECA_ORG_CC_PATH } from './Settings';


import GlobalLoaderStore from './stores/GlobalLoaderStore';
import './App.css';
import FrameworkComponent from './components/framework/FrameworkComponent';
import FrameworkOrgComponent from './components/framework/FrameworkOrgComponent';
import GlobalLoaderComponent from './components/common/GlobalLoaderComponent';
import { Auth } from 'aws-amplify';



interface AppProps {
  routing?: RouterStore,
  globalLoader?: GlobalLoaderStore,
  authState?: String,
  authData?: any
}
var fetchedUserData = false;
@inject("routing", "globalLoader")
@observer
export default class App extends Component<AppProps> {
  restartUserData = () =>{
    fetchedUserData = false;
  }
  componentDidUpdate = () => {
    if(this.props.authData?.groups || fetchedUserData)
          return;
    Auth.currentAuthenticatedUser().then(res=>{
        this.props.authData!.groups = res.signInUserSession.accessToken.payload["cognito:groups"];
        this.props.authData.resetUser = this.restartUserData;
        fetchedUserData = true;
        this.setState({});
        
    });
  }
  private renderRoute = (path: string, componentData:any) => {
    if(path && this.props.authData && this.props.authData.groups)
    if(path?.includes('framework') && this.props.authData?.groups.some((element) => ['Admins','Contentops','OrganizationManage','Readonly'].includes(element)))
      return <Route exact path={path} component={componentData} /> 
    else if((path?.includes('/packages') || path == '/' ) && this.props.authData?.groups.some((element) => ['Admins','Contentops','Readonly'].includes(element)))
      return <Route exact path={path} component={componentData} /> 
    else if(path?.includes('/provider_data') && this.props.authData?.groups.some((element) => ['Admins','Contentops','Readonly'].includes(element)))
      return <Route exact path={path} component={componentData} /> 
    else if(path?.includes('organization') && this.props.authData?.groups.some((element) => ['Admins','OrganizationManage'].includes(element)))
      return <Route exact path={path} component={componentData} /> 
    else if(path?.includes('explore') && this.props.authData?.groups.some((element) => ['Admins','OrganizationManage'].includes(element)))
      return <Route exact path={path} component={componentData} /> 
    else if(path?.includes('catalog'))
        return <Route exact path={path} component={componentData} /> 
  }

  render() {
    if(!this.props.authData?.attributes)
      return null;
    return  <div className="App">
        {this.props.globalLoader!.isLoading ? <div className="csod-vertical-align-container global-loader-container">
          <div className="csod-horizontal-align-container">
            <GlobalLoaderComponent isLoading={true} />
          </div>
        </div>
          : null}
        <HeaderComponent authData={{ ...this.props.authData}}/>
        <Switch>
            {this.renderRoute(PROVIDERS_PAGE_PATH,ProvidersGridComponent)}             
            {this.renderRoute(PACKGES_PAGE_PATH,PackagesGridComponent)}             
            {this.renderRoute(PROVIDER_DATA_PATH,ProviderDataGridComponent)}             
            {this.renderRoute(PROVIDER_FRAMEWORK_PAGE_PATH,FrameworkComponent)}             
            {this.renderRoute(ORGANIZATION_FRAMWORK_PAGE_PATH,FrameworkOrgComponent)}             
            {this.renderRoute(ORGANIZATIONS_PATH,OrganizationGridComponent)}       
            {this.renderRoute(ECA_BUNDLES_PATH,EcaBundleGridComponent)}             
            {this.renderRoute(ECA_COURSES_PATH,EcaCourseGridComponent)}             
            {this.renderRoute(ECA_ORG_CC_PATH,OrgCcaCoursesGridComponent)}         
        </Switch>
      </div>
  }
}