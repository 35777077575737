import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import "./FrameworkComponent.css";
import ProviderStore from '../../stores/ProvidersStore';
import DropdownComponent from '../common/inputs/DropdownComponent';
import SwitcherComponent from '../common/inputs/SwitcherComponent';
import TextInputComponent from '../common/inputs/TextInputComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import { TimestampType } from '../../models/ProviderDetails/ProviderDetailsExpansion';
import SSOConfigErrorModel from '../../models/SSOConfig/SSOConfigErrorModel';
import SSOConfigModel, { OUTBOUND_SSO_URL_PLACEHOLDER, AP_USERID_NAME, AP_USERNAME_NAME, AP_EMAIL_NAME, AP_FIRSTNAME_NAME, AP_LASTNAME_NAME, AP_MIDDLENAME_NAME, AP_USERDIVISION_NAME, AP_USERLOCATION_NAME, AP_LNGUAGE_NAME, AP_PHONEHOME_NAME, AP_WORKPHONE_NAME, AP_MANAGERID_NAME } from '../../models/SSOConfig/SSOConfigModel';
import LabelComponent from '../common/inputs/LabelComponent';
import { AdditionalSettingType } from '../../models/SSOConfig/AdditionalSettingsType';
import NameValueWithTypeAndPositionInput from '../../models/SSOConfig/NameValueWithTypeAndPositionInput';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import OutboundSAMLQueryStringParameterInput from '../../models/SSOConfig/OutboundSAMLQueryStringParameterInput';
import { v4 } from 'uuid';
import { ACS_URL_CY_DATA, ADDITIONAL_SETTINGS_DROPDOWN_CY_DATA, ADDITIONAL_SETTING_ADD_CY_DATA, ADDITIONAL_SETTING_DELETE_CY_DATA, ADDITIONAL_SETTING_KEY_CY_DATA, ADDITIONAL_SETTING_POSITION_CY_DATA, ADDITIONAL_SETTING_ROW_CY_DATA, ADDITIONAL_SETTING_TYPE_CY_DATA, ADDITIONAL_SETTING_VALUE_CY_DATA, ADVANCED_PROPERTIES_DROPDOWN_CY_DATA, AUDIENCE_CY_DATA, AUTHENTICATION_PARAMETERS_MAPPING_DROPDOWN_CY_DATA, AUTHENTICATION_REQUEST_DESTINATION_CY_DATA, AUTHENTICATION_REQUEST_ISSUER_CY_DATA, EMAIL_ADRESS_APM_CY_DATA, ENABLE_SSO_CY_DATA, FIRST_NAME_APM_CY_DATA, INCLUDE_ISSUER_IN_RESPONSE_CY_DATA, ISSUER_CY_DATA, LANGUAGE_APM_CY_DATA, LAST_NAME_APM_CY_DATA, MANAGER_ID_APM_CY_DATA, MIDDLE_NAME_APM_CY_DATA, OUTBOUND_SSO_URL_CY_DATA, PHONE_HOME_APM_CY_DATA, QUERYSTRING_PARAMETERS_DROPDOWN_CY_DATA, QUERYSTRING_PARAMETER_ADD_CY_DATA, QUERYSTRING_PARAMETER_DELETE_CY_DATA, QUERYSTRING_PARAMETER_NAME_CY_DATA, QUERYSTRING_PARAMETER_ROW_CY_DATA, QUERYSTRING_PARAMETER_SAML_ATTRIBUTE_CY_DATA, SIGNATURE_WITHIN_ASSERTION_CY_DATA, SP_INITIATED_DROPDOWN_CY_DATA, SP_INITIATED_ENABLE_CY_DATA, SSO_ID_OU_ID_CY_DATA, TIMESTAMP_FORMAT_CY_DATA, USERNAME_APM_CY_DATA, USER_DIVISION_APM_CY_DATA, USER_ID_APM_CY_DATA, USER_LOCATION_APM_CY_DATA, WORK_PHONE_APM_CY_DATA } from '../../cypressDataConstants/providerSsoConfigCyDataConstants';
import KeyValueWithTypeAndPositionErrorModel from '../../models/SSOConfig/KeyValueWithTypeAndPositionErrorModel';
import SSOConfigConstants from '../../constants/ssoConfigConstants';
import FrameworkHelpers from '../../helpers/framework/FrameworkHelpers';

interface SSOConfigurationProps {
    providers?: ProviderStore,
    ssoConfigErrorMessages?: SSOConfigErrorModel,
    isScrolledToProblemElement: boolean,
    onScrolledToProblemElement: () => void
    resetErrors: () => void
}

interface SSOConfigurationState {
    SSOID?: number,
    isSpInitiated: boolean,
    isSSOEnabled: boolean,
}

@inject('providers')
@observer
export default class SSOConfiguration extends Component<SSOConfigurationProps, SSOConfigurationState > {
    constructor(props: SSOConfigurationProps) {
        super(props);
        this.state = { 
            SSOID: this.props.providers?.CurrentSSOConfigModel.SSOIDOUID , 
            isSSOEnabled: this.props.providers!.CurrentSSOConfigModel!.EnableSSO, 
            isSpInitiated: this.props.providers?.CurrentSSOConfigModel.IsSpInitiatedEnabled == null? false : this.props.providers.CurrentSSOConfigModel.IsSpInitiatedEnabled,
        }
    }

    componentDidUpdate = () => {
        FrameworkHelpers.goToProblemElement(this.props.isScrolledToProblemElement,this.props.onScrolledToProblemElement);
        this.clearErrorStyling();
    }

    componentDidMount = () => {
        FrameworkHelpers.goToProblemElement(this.props.isScrolledToProblemElement,this.props.onScrolledToProblemElement);
    }

    private clearErrorStyling = () => {
        var inputs: any = document.querySelectorAll(".erases-at-disabling.disabled input, .erases-at-disabling.disabled select, .erases-at-disabling.disabled .error-text, .erases-at-disabling.disabled .error-border");
        for(let i = 0; i<inputs.length; i++) {
            inputs[i].value = "";
            if(inputs[i].className.includes("error-text"))  {
                inputs[i].className.replace("error-text", "");
            }
            if(inputs[i].className.includes("error-border")) {
                inputs[i].className.replace("error-border", "");
            }
        }
    }

    private onEnableSSOConfigurationChange = (value: boolean) => {
        if(!value) {
            this.props.resetErrors();
            this.props.providers!.CurrentSSOConfigModel = new SSOConfigModel();
            this.setState({SSOID: undefined});
        }
        this.props.providers!.CurrentSSOConfigModel!.EnableSSO = value;
        this.setState({isSSOEnabled: value, isSpInitiated: false});
    }
    private onSSOIDOUIDChanged = (value: string) => {
        var integer = parseInt(value);
        this.setState({SSOID: integer});
        this.props.providers!.CurrentSSOConfigModel!.SSOIDOUID = integer;
    }
    private onACSURLChanged = (value: string) => {
        this.props.providers!.CurrentSSOConfigModel!.ACSURL = value;
    }
    private onTimestampFormatChanged = (value: string) => {
        this.props.providers!.CurrentSSOConfigModel!.TimestampFormat = value as TimestampType;
    }
    private onIssuerChanged = (value: string) => {
        this.props.providers!.CurrentSSOConfigModel!.Issuer = value;
    }
    private onAudienceChanged = (value: string) => {
        this.props.providers!.CurrentSSOConfigModel!.Audience = value;
    }
    private onUserIDChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_USERID_NAME, value);
    }
    private onUsernameChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_USERNAME_NAME, value);
    }
    private onEmailAdressChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_EMAIL_NAME, value);
    }
    private onFirstNameChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_FIRSTNAME_NAME, value);
    }
    private onLastNameChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_LASTNAME_NAME, value);
    }
    private onMiddleNameChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_MIDDLENAME_NAME, value);
    }
    private onManagerIDChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_MANAGERID_NAME, value);
    }
    private onUserDivisionChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_USERDIVISION_NAME, value);
    }
    private onUserLocationChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_USERLOCATION_NAME, value);
    }
    private onLanguageChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_LNGUAGE_NAME, value);
    }
    private onPhoneHomeChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_PHONEHOME_NAME, value);
    }
    private onWorkPhoneChanged = (value: string) => {
        CodeHelpers.ChangeArrayItemValueByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_WORKPHONE_NAME, value);
    }
    private onIsInternalChanged = (value: boolean) => {
        this.props.providers!.CurrentSSOConfigModel.IsInternal = value;
    }

    private onSpInitiatedChange = (value: boolean) => {
        this.props.providers!.CurrentSSOConfigModel.IsSpInitiatedEnabled = value;
        if(!this.props.providers?.CurrentSSOConfigModel.IsSpInitiatedEnabled) {
            this.props.providers!.CurrentSSOConfigModel.AuthenticationRequestIssuer = "";
        }
        this.setState({isSpInitiated: value})
        this.clearErrorStyling();
    }
    private onAuthenticationRequestIssuerChange = (value: string) => {
        this.props.providers!.CurrentSSOConfigModel.AuthenticationRequestIssuer = value;
    }

    private onSignatureWithinAssertionChange = (value: boolean) => {
        this.props.providers!.CurrentSSOConfigModel.SignatureWithinAssertion = value;
    }

    private onIncludeIssuerInResponseChange = (value: boolean) => {
        this.props.providers!.CurrentSSOConfigModel.IncludeIssuerInResponse = value;
    }

    private GetOnAdditionalSettingKeyChange = (iterator: number) => {
        return (value: string) => {
            this.props.providers!.CurrentSSOConfigModel.AdditionalSettings![iterator].name = value;
        }
    }

    private GetOnAdditionalSettingTypeChange = (iterator: number) => {
        return (value: string) => {
            this.props.providers!.CurrentSSOConfigModel.AdditionalSettings![iterator].type = value as AdditionalSettingType;
        }
    }

    private GetOnAdditionalSettingPositionChange = (iterator: number) => {
        return (value: string) => {
            this.props.providers!.CurrentSSOConfigModel.AdditionalSettings![iterator].position = parseInt(value);
        }
    }

    private GetOnAdditionalSettingValueChange = (index: number) => {
        return (value: string) => {
            this.props.providers!.CurrentSSOConfigModel.AdditionalSettings![index].value = value;
        }
    }

    private GetOnQuerystringParameterNameChange = (index: number) => {
        return (value: string) => {
            this.props.providers!.CurrentSSOConfigModel.QuerystringParameters![index].QueryStringParameterName = value;
        }
    }

    private GetOnQuerystringParameterSamlAttributeNameChange = (index: number) => {
        return (value: string) => {
            this.props.providers!.CurrentSSOConfigModel.QuerystringParameters![index].SamlAttributeName = value;
        }
    }

    private OnAddNewRowForAdditionalSettings = () => {
        this.props.providers?.CurrentSSOConfigModel.AdditionalSettings.push(new NameValueWithTypeAndPositionInput());
        this.forceUpdate();
    }

    private OnAddNewRowForQuerystringParameters = () => {
        this.props.providers?.CurrentSSOConfigModel?.QuerystringParameters.push(new OutboundSAMLQueryStringParameterInput());
        this.forceUpdate();
    }

    private GetOnAdditionSettingsRemoveRow = (index: number) => {
        return () => {
            this.props.providers?.CurrentSSOConfigModel.AdditionalSettings.splice(index,1);
            this.forceUpdate();
        }
    }

    private GetOnQuerystringParametersRemoveRow = (index: number) => {
        return () => {
            this.props.providers?.CurrentSSOConfigModel.QuerystringParameters.splice(index,1);
            this.forceUpdate();
        }
    }

    private renderAdditionalSetting = (key: string, index: number, isHeaderHidden: boolean) => {
        var additionalSettingModel = this.props.providers?.CurrentSSOConfigModel.AdditionalSettings![index];
        var errorMessages = this.props.ssoConfigErrorMessages?.AdditionalSettingsErrorMessages? this.props.ssoConfigErrorMessages?.AdditionalSettingsErrorMessages[index] : null;
        if(!additionalSettingModel) {
            additionalSettingModel = new NameValueWithTypeAndPositionInput();
        }
        if(!errorMessages) {
            errorMessages = new KeyValueWithTypeAndPositionErrorModel();
        }
        return <div key={key} className = "csod-inputs-row-container no-right-margins four-columns-30-20-20-30" cy-data={ADDITIONAL_SETTING_ROW_CY_DATA}>
            <TextInputComponent title = "Key"        additionalClass={"less-margin-top"}isHeaderHidden = {isHeaderHidden}    important = {true} defaultValue = {additionalSettingModel?.name} widthClass={InputWidthTypes.Middle} onValueChanged={this.GetOnAdditionalSettingKeyChange(index)} cy_data={ADDITIONAL_SETTING_KEY_CY_DATA} errorMessage={errorMessages.name}/>
            <DropdownComponent  title = "Type"       additionalClass={"less-margin-top"}isHeaderHidden = {isHeaderHidden} placeholder = "Type" items={Object.values(AdditionalSettingType).filter(i => !CodeHelpers.IsStringANumber(i))} important = {true} defaultValue = {additionalSettingModel?.type} widthClass={InputWidthTypes.Small} onValueChanged={this.GetOnAdditionalSettingTypeChange(index)} cy_data={ADDITIONAL_SETTING_TYPE_CY_DATA} errorMessage={errorMessages.type}/>
            <TextInputComponent title = "Position"   additionalClass={"less-margin-top"}isHeaderHidden = {isHeaderHidden} type="number" important = {false} defaultValue = {additionalSettingModel?.position?.toString()} widthClass={InputWidthTypes.Small} onValueChanged={this.GetOnAdditionalSettingPositionChange(index)} cy_data={ADDITIONAL_SETTING_POSITION_CY_DATA}/>
            <TextInputComponent title = "Value"      additionalClass={"less-margin-top"}isHeaderHidden = {isHeaderHidden} important = {false} defaultValue = {additionalSettingModel?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.GetOnAdditionalSettingValueChange(index)} cy_data={ADDITIONAL_SETTING_VALUE_CY_DATA} errorMessage={errorMessages.value}/>
            <button className = "csod-remove-row-button" onClick={this.GetOnAdditionSettingsRemoveRow(index)} cy-data={ADDITIONAL_SETTING_DELETE_CY_DATA}/>
        </div>
    }

    private renderQuerystringParameter = (key: string, index: number, isHeaderHidden: boolean) => {
        var querystringParametersModel = this.props.providers?.CurrentSSOConfigModel.QuerystringParameters![index];
        var errorMessages = this.props.ssoConfigErrorMessages?.QuerystringParametersErrorMessages? this.props.ssoConfigErrorMessages?.QuerystringParametersErrorMessages[index] : null;
        if(!querystringParametersModel) {
            querystringParametersModel = new OutboundSAMLQueryStringParameterInput();
        }
        if(!errorMessages) {
            errorMessages = new OutboundSAMLQueryStringParameterInput();
        }
        return <div key={key} className = "csod-inputs-row-container no-right-margins two-columns-container" cy-data={QUERYSTRING_PARAMETER_ROW_CY_DATA}>
            <TextInputComponent 
                title = "Querystring Parameter Name"        
                additionalClass={"less-margin-top"}
                isHeaderHidden = {isHeaderHidden} 
                important = {true} 
                defaultValue = {querystringParametersModel?.QueryStringParameterName} 
                widthClass={InputWidthTypes.Large} 
                onValueChanged={this.GetOnQuerystringParameterNameChange(index)}
                cy_data={QUERYSTRING_PARAMETER_NAME_CY_DATA}
                errorMessage = {errorMessages.QueryStringParameterName}
            />
            <TextInputComponent  title = "SAML Attribute"       additionalClass={"less-margin-top"}isHeaderHidden = {isHeaderHidden} important = {false} defaultValue = {querystringParametersModel?.SamlAttributeName} widthClass={InputWidthTypes.Large} onValueChanged={this.GetOnQuerystringParameterSamlAttributeNameChange(index)} cy_data={QUERYSTRING_PARAMETER_SAML_ATTRIBUTE_CY_DATA} errorMessage = {errorMessages.SamlAttributeName}/>
            <button className = "csod-remove-row-button" onClick={this.GetOnQuerystringParametersRemoveRow(index)} cy-data={QUERYSTRING_PARAMETER_DELETE_CY_DATA}/>
        </div>
    }

    render() {
        var additionSettingsCount = 0;
        var querystringParametersCount = 0;
        return <div className = "csod-framework-tab-content">
            <div className = "csod-framework-layer">
                <SwitcherComponent title="Enable SSO Configuration" defaultValue={this.props.providers?.CurrentSSOConfigModel.EnableSSO} onValueChanged={this.onEnableSSOConfigurationChange} cy_data={ENABLE_SSO_CY_DATA}/>
            </div>
            <div className = {`csod-framework-layer erases-at-disabling ${this.state.isSSOEnabled? "" : "disabled"}`}>
                <div className = "csod-framework-header non-selectable">{SSOConfigConstants.GENERAL_PROPERTIES_HEADER}</div>
                <div className = "csod-inputs-row-container two-columns-container">
                    <TextInputComponent title = "SSO ID (OU ID)" type="number" step={1} important = {true} defaultValue = {this.props.providers?.CurrentSSOConfigModel.SSOIDOUID?.toString()} errorMessage={this.props.ssoConfigErrorMessages?.SSOIDOUIDErrorMessage} widthClass={InputWidthTypes.Middle} onValueChanged={this.onSSOIDOUIDChanged} description={SSOConfigConstants.SSOIDOUID_DESCRIPTION} cy_data={SSO_ID_OU_ID_CY_DATA}/>
                </div>
                <div className = "csod-inputs-row-container two-columns-container">
                    <LabelComponent isHidden={false} additionalClass={`${this.state.isSSOEnabled? "gray-text" : "light-gray-text" }`} title = "Outbound SSO URL" value = {`${OUTBOUND_SSO_URL_PLACEHOLDER}${this.state.SSOID != null? this.state.SSOID : ""}`} widthClass={InputWidthTypes.Middle} isAbleToCopy = {false} cy_data={OUTBOUND_SSO_URL_CY_DATA}/>
                </div>
                <div className = "csod-inputs-row-container two-columns-container">
                    <TextInputComponent title = "ACS URL" important = {true} defaultValue = {this.props.providers?.CurrentSSOConfigModel.ACSURL} errorMessage={this.props.ssoConfigErrorMessages?.ACSURLErrorMessage} widthClass={InputWidthTypes.Middle} onValueChanged={this.onACSURLChanged} type="url" cy_data={ACS_URL_CY_DATA}/>
                    <DropdownComponent title = "Timestamp Format" placeholder="Timestamp Format" important = {true} items={Object.values(TimestampType)} defaultValue = {this.props.providers?.CurrentSSOConfigModel.TimestampFormat} errorMessage={this.props.ssoConfigErrorMessages?.TimestampErrorMessage} widthClass={InputWidthTypes.Middle} onValueChanged={this.onTimestampFormatChanged} cy_data={TIMESTAMP_FORMAT_CY_DATA}/>
                </div>
                <div className = "csod-inputs-row-container two-columns-container">
                    <TextInputComponent title = "Issuer" important = {false} defaultValue = {this.props.providers?.CurrentSSOConfigModel.Issuer} widthClass={InputWidthTypes.Middle} onValueChanged={this.onIssuerChanged} regex={SSOConfigConstants.ISSUER_REGEX} example={"https://#SUBDOMAIN_PLACEHOLDER#.csod.com"} cy_data={ISSUER_CY_DATA}/>
                </div>
                <div className = "csod-inputs-row-container two-columns-container">
                    <TextInputComponent title = "Audience" important = {false} defaultValue = {this.props.providers?.CurrentSSOConfigModel.Audience} widthClass={InputWidthTypes.Middle} onValueChanged={this.onAudienceChanged} cy_data={AUDIENCE_CY_DATA}/>
                </div>
            </div>
            <div className = {`csod-accordion-framework-layer erases-at-disabling ${this.state.isSSOEnabled? "" : "disabled"}`} cy-data={AUTHENTICATION_PARAMETERS_MAPPING_DROPDOWN_CY_DATA}>
                <input className = "csod-accordion-chkbx" type="checkbox" id="authentication-accordion"></input>
                <label className = "csod-accordion-framework-header non-selectable" htmlFor="authentication-accordion">{SSOConfigConstants.AUTHENTICATION_PARAMETERS_HEADER}</label>
                <div className = "csod-accordion-content">
                    <div className = "csod-inputs-row-container two-columns-container">
                        <TextInputComponent title = "User ID" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_USERID_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onUserIDChanged} cy_data={USER_ID_APM_CY_DATA}/>
                        <TextInputComponent title = "Username" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_USERNAME_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onUsernameChanged} cy_data={USERNAME_APM_CY_DATA}/>
                    </div>
                    <div className = "csod-inputs-row-container two-columns-container">
                        <TextInputComponent title = "Email Adress" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_EMAIL_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onEmailAdressChanged} cy_data={EMAIL_ADRESS_APM_CY_DATA}/>
                        <TextInputComponent title = "First Name" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_FIRSTNAME_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onFirstNameChanged} cy_data={FIRST_NAME_APM_CY_DATA}/>
                    </div>
                    <div className = "csod-inputs-row-container two-columns-container">
                        <TextInputComponent title = "Last Name" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_LASTNAME_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onLastNameChanged} cy_data={LAST_NAME_APM_CY_DATA}/>
                        <TextInputComponent title = "Middle Name" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_MIDDLENAME_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onMiddleNameChanged} cy_data={MIDDLE_NAME_APM_CY_DATA}/>
                    </div>
                    <div className = "csod-inputs-row-container two-columns-container">
                        <TextInputComponent title = "Manager ID" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_MANAGERID_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onManagerIDChanged} cy_data={MANAGER_ID_APM_CY_DATA}/>
                        <TextInputComponent title = "User Division" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_USERDIVISION_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onUserDivisionChanged} cy_data={USER_DIVISION_APM_CY_DATA}/>
                    </div>
                    <div className = "csod-inputs-row-container two-columns-container">
                        <TextInputComponent title = "User Location" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_USERLOCATION_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onUserLocationChanged} cy_data={USER_LOCATION_APM_CY_DATA}/>
                        <TextInputComponent title = "Language" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_LNGUAGE_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onLanguageChanged} cy_data={LANGUAGE_APM_CY_DATA}/>
                    </div>
                    <div className = "csod-inputs-row-container two-columns-container">
                        <TextInputComponent title = "Phone Home" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_PHONEHOME_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onPhoneHomeChanged} cy_data={PHONE_HOME_APM_CY_DATA}/>
                        <TextInputComponent title = "Work Phone" important = {false} defaultValue = {CodeHelpers.GetArrayItemByKey(this.props.providers?.CurrentSSOConfigModel.authenticationParameters, AP_WORKPHONE_NAME)?.value} widthClass={InputWidthTypes.Middle} onValueChanged={this.onWorkPhoneChanged} cy_data={WORK_PHONE_APM_CY_DATA}/>
                    </div>
                </div>
            </div>
            <div className = {`csod-accordion-framework-layer erases-at-disabling ${this.state.isSSOEnabled? "" : "disabled"}`} cy-data={SP_INITIATED_DROPDOWN_CY_DATA}>
                <input className = "csod-accordion-chkbx" type="checkbox" id="sp-initiated-accordion"/>
                <label className = "csod-accordion-framework-header non-selectable" htmlFor="sp-initiated-accordion">{SSOConfigConstants.SP_INITIATED_HEADER}</label>
                <SwitcherComponent title="Enable" defaultValue={this.props.providers?.CurrentSSOConfigModel.IsSpInitiatedEnabled} onValueChanged={this.onSpInitiatedChange} additionalClass={"csod-enable-toggle-for-accordion"} cy_data={SP_INITIATED_ENABLE_CY_DATA}/>
                <div className = {`csod-accordion-content erases-at-disabling ${this.state.isSpInitiated? "" : "disabled"}`}>
                    <div className = "csod-inputs-row-container no-right-margins two-columns-container">
                        <TextInputComponent title = "Authentication Request Issuer" important = {true} defaultValue = {this.props.providers?.CurrentSSOConfigModel.AuthenticationRequestIssuer} widthClass={InputWidthTypes.Large} onValueChanged={this.onAuthenticationRequestIssuerChange} cy_data={AUTHENTICATION_REQUEST_ISSUER_CY_DATA} errorMessage = {this.props.ssoConfigErrorMessages?.AuthenticationRequestIssuerErrorMessage}/>
                        <LabelComponent title = "Authentication Request Destination" isHidden={false} additionalClass={`${this.state.isSSOEnabled? "gray-text" : "light-gray-text" }`} value = {`${OUTBOUND_SSO_URL_PLACEHOLDER}${this.state.SSOID != null? this.state.SSOID : ""}`} widthClass={InputWidthTypes.Large} isAbleToCopy = {false} cy_data={AUTHENTICATION_REQUEST_DESTINATION_CY_DATA}/>
                    </div>
                </div>
            </div>
            <div className = {`csod-accordion-framework-layer erases-at-disabling ${this.state.isSSOEnabled? "" : "disabled"}`} cy-data={ADVANCED_PROPERTIES_DROPDOWN_CY_DATA}>
                <input className = "csod-accordion-chkbx" type="checkbox" id="advanced-properties-accordion"/>
                <label className = "csod-accordion-framework-header non-selectable" htmlFor="advanced-properties-accordion">{SSOConfigConstants.ADVANCED_PROPERTIES_HEADER}</label>
                <div className = "csod-accordion-content">
                    <div className = "csod-inputs-row-container two-columns-container">
                        <SwitcherComponent title="Signature Within Assertion" defaultValue={this.props.providers?.CurrentSSOConfigModel.SignatureWithinAssertion} onValueChanged={this.onSignatureWithinAssertionChange} cy_data={SIGNATURE_WITHIN_ASSERTION_CY_DATA}/>
                    </div>
                    <div className = "csod-inputs-row-container two-columns-container">
                        <SwitcherComponent title="Include Issuer In Response" defaultValue={this.props.providers?.CurrentSSOConfigModel.IncludeIssuerInResponse} onValueChanged={this.onIncludeIssuerInResponseChange} cy_data={INCLUDE_ISSUER_IN_RESPONSE_CY_DATA}/>
                    </div>
                    <div className = "csod-inputs-row-container two-columns-container">
                        <SwitcherComponent title="Is Internal" defaultValue={this.props.providers?.CurrentSSOConfigModel.IsInternal} onValueChanged={this.onIsInternalChanged}/>
                    </div>
                </div>
            </div>
            <div className = {`csod-accordion-framework-layer erases-at-disabling ${this.state.isSSOEnabled? "" : "disabled"}`} cy-data={QUERYSTRING_PARAMETERS_DROPDOWN_CY_DATA}>
                <input className = "csod-accordion-chkbx" type="checkbox" id="querystring-parameters-accordion"></input>
                <label className = "csod-accordion-framework-header non-selectable" htmlFor="querystring-parameters-accordion">{SSOConfigConstants.QUERYSTRING_PARAMETERS_HEADER}</label>
                <div className = "csod-accordion-content">
                    <button className="csod-button gray less-rounded-corners"onClick={this.OnAddNewRowForQuerystringParameters} cy-data={QUERYSTRING_PARAMETER_ADD_CY_DATA}>Add new row</button>
                    {this.props.providers?.CurrentSSOConfigModel.QuerystringParameters.map(i => this.renderQuerystringParameter(v4(),querystringParametersCount++,querystringParametersCount!==1))}
                </div>
            </div>
            <div className = {`csod-accordion-framework-layer erases-at-disabling ${this.state.isSSOEnabled? "" : "disabled"}`} cy-data={ADDITIONAL_SETTINGS_DROPDOWN_CY_DATA}>
                <input className = "csod-accordion-chkbx" type="checkbox" id="additional-settings-accordion"></input>
                <label className = "csod-accordion-framework-header non-selectable" htmlFor="additional-settings-accordion">{SSOConfigConstants.ADDITIONAL_SETTINGS_HEADER}</label>
                <div className = "csod-accordion-content">
                    <button className="csod-button gray less-rounded-corners"onClick={this.OnAddNewRowForAdditionalSettings} cy-data={ADDITIONAL_SETTING_ADD_CY_DATA}>Add new row</button>
                    {this.props.providers?.CurrentSSOConfigModel.AdditionalSettings.map(i => this.renderAdditionalSetting(v4(),additionSettingsCount++,additionSettingsCount!==1))}
                </div>
            </div>
        </div>
    }
}