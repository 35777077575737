export enum ProviderOfferingType {
    OneToMany = 1,
    OneToOne,
    Both
}

const ONE_TO_MANY_STRING = "One-To-Many";
const ONE_TO_ONE_STRING = "One-To-One";
const BOTH_STRING = "Both";

export const mapToProviderOfferingType = (value: string): ProviderOfferingType | undefined=> {
    switch(value) {
        case ONE_TO_MANY_STRING: return ProviderOfferingType.OneToMany;
        case ONE_TO_ONE_STRING: return ProviderOfferingType.OneToOne;
        case BOTH_STRING: return ProviderOfferingType.Both;
        default: return undefined;
    }
}

export const providerOfferingTypeToString = (value: ProviderOfferingType | undefined): string => {
    switch(value) {
        case ProviderOfferingType.OneToMany: return ONE_TO_MANY_STRING;
        case ProviderOfferingType.OneToOne: return ONE_TO_ONE_STRING;
        case ProviderOfferingType.Both: return BOTH_STRING;
        default: return "";
    }
}