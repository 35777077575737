import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import "./FrameworkComponent.css";
import ProviderStore from '../../stores/ProvidersStore';
import { ProviderOfferingType } from '../../models/ProviderDetails/ProviderOfferingType';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import { TestSubscriptionOption } from '../../models/TestSubscription/TestSubscriptionOption';
import FrameworkHelpers from '../../helpers/framework/FrameworkHelpers';
import { ONE_TO_MANY_CSOD_TEST_PORTAL_CY_DATA, ONE_TO_MANY_SABA_TEST_PORTAL_CY_DATA, ONE_TO_ONE_CSOD_TEST_PORTAL_1_CY_DATA, ONE_TO_ONE_CSOD_TEST_PORTAL_2_CY_DATA } from '../../cypressDataConstants/providerTestSubscriptionCyDataConstants';

interface TestSubscriptionProps {
    providers?: ProviderStore,
    testSubscriptionErrorMessages?: {[id: number]: string},
    isScrolledToProblemElement: boolean,
    onScrolledToProblemElement: () => void
}

@inject('providers')
@observer
export default class TestSubscription extends Component<TestSubscriptionProps> {

    componentDidUpdate = () => {
        FrameworkHelpers.goToProblemElement(this.props.isScrolledToProblemElement,this.props.onScrolledToProblemElement);
    }

    componentDidMount = () => {
        FrameworkHelpers.goToProblemElement(this.props.isScrolledToProblemElement,this.props.onScrolledToProblemElement);
    }

    private getOnCIIDCChange = (testPortal: TestSubscriptionOption) => {
        return (event : any) => {
            this.props.providers!.CurrentTestSubscriptionConfig[testPortal].CIID = event.target.value;
        }
    }

    private getOnTestPortalCheckChange = (testPortal: TestSubscriptionOption) => {
        return (event : any) => {
            this.props.providers!.CurrentTestSubscriptionConfig[testPortal].isChecked = event.target.checked;
        }
    }

    renderTestSubscriptionFrameworkLayer(header: String, description: String, testSubscribtionFrameworkItems: {testSubscriptionOption: TestSubscriptionOption,  cy_data: string}[]) {
        
        return <div className = "csod-framework-layer">
            <div className = "csod-framework-header non-selectable margin-top-for-inputs">{header}</div>
            <div className = "csod-input-title-text non-selectable margin-top-for-inputs">{description}</div>
            {testSubscribtionFrameworkItems.map(i => {
                var errorMessage = this.props.testSubscriptionErrorMessages != null ? this.props.testSubscriptionErrorMessages[i.testSubscriptionOption] : "";
                var isErrorMessageEmpty = CodeHelpers.IsStringNullOrEmpty(errorMessage);
                
                return <div key={i.testSubscriptionOption} className = "csod-inputs-row-container three-columns-container" cy-data={i.cy_data}>
                <div className="csod-checkbox-container  margin-top-for-inputs">
                <label className="container">{this.props.providers?.CurrentTestSubscriptionConfig[i.testSubscriptionOption].name}
                    <input type="checkbox" defaultChecked={this.props.providers?.CurrentTestSubscriptionConfig[i.testSubscriptionOption].isChecked} onChange={this.getOnTestPortalCheckChange(i.testSubscriptionOption)}/>
                    <span className="checkmark"></span>
                </label>
                </div>
                <a className="csod-url-view margin-top-for-inputs" href={this.props.providers?.CurrentTestSubscriptionConfig[i.testSubscriptionOption].url}>{this.props.providers?.CurrentTestSubscriptionConfig[i.testSubscriptionOption].url}</a>
                {this.props.providers?.CurrentTestSubscriptionConfig[i.testSubscriptionOption].CIIDShow ? <div className="csod-inputs-row-container no-flex-wrap">
                    <div className="csod-input-title-text non-selectable  margin-top-for-inputs ">CIID</div>
                    <div className={`csod-inputs-column-container large-width ${!isErrorMessageEmpty ? "margin-top-64" : null}`}>
                        <input type="text" className= {`csod-input-textbox  margin-top-for-inputs ${!isErrorMessageEmpty? "error-border has-error" : null}`} defaultValue={this.props.providers?.CurrentTestSubscriptionConfig[i.testSubscriptionOption].CIID} maxLength={50} onChange={this.getOnCIIDCChange(i.testSubscriptionOption)}/>
                        {!isErrorMessageEmpty ? <div className = "error-text-color">{errorMessage}</div> : null}
                    </div>
                </div> : <div/>}
            </div>
            })}
        </div>
    }

    render() {
        return <div className = "csod-framework-tab-content">
            {(this.props.providers!.CurrentProvider!.ProviderOfferingType! === ProviderOfferingType.OneToOne || this.props.providers!.CurrentProvider!.ProviderOfferingType! === ProviderOfferingType.Both)? this.renderTestSubscriptionFrameworkLayer(
                "Create a Test One-to-One Subscription Offering Type",
                "Test one-to-one subscription is used to view if the provider’s courses in test portals",
                [
                    {testSubscriptionOption: TestSubscriptionOption.TestPortal1, cy_data: ONE_TO_ONE_CSOD_TEST_PORTAL_1_CY_DATA},
                    {testSubscriptionOption: TestSubscriptionOption.TestPortal2, cy_data: ONE_TO_ONE_CSOD_TEST_PORTAL_2_CY_DATA}
            ]) : null}
            {(this.props.providers!.CurrentProvider!.ProviderOfferingType! === ProviderOfferingType.OneToMany || this.props.providers!.CurrentProvider!.ProviderOfferingType! === ProviderOfferingType.Both)? this.renderTestSubscriptionFrameworkLayer(
                "Create a Test One-to-Many Subscription Offering Type",
                "Create a Test One-to-Many Subscription Offering Type",
                [
                    {testSubscriptionOption: TestSubscriptionOption.CSODTestPortal, cy_data: ONE_TO_MANY_CSOD_TEST_PORTAL_CY_DATA},
                    {testSubscriptionOption: TestSubscriptionOption.SabaTestPortal, cy_data: ONE_TO_MANY_SABA_TEST_PORTAL_CY_DATA}
            ]): null}
        </div>
    }
}