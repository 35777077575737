export enum AuthenticationType {
    None = 0, 
    oAuth2 = 1,
    Basic = 2,
    ApiKey = 3
}

export const stringToAuthType = (value: string) => {
    switch(value) {
        case AuthenticationType[AuthenticationType.None]: return AuthenticationType.None;
        case AuthenticationType[AuthenticationType.Basic]: return AuthenticationType.Basic;
        case AuthenticationType[AuthenticationType.oAuth2]: return AuthenticationType.oAuth2;
        case AuthenticationType[AuthenticationType.ApiKey]: return AuthenticationType.ApiKey;
        default: return undefined;
    }
}

export const authTypeToString = (value: AuthenticationType | undefined): string => {
    switch(value) {
        case AuthenticationType.None: return AuthenticationType[AuthenticationType.None];
        case AuthenticationType.Basic: return AuthenticationType[AuthenticationType.Basic];
        case AuthenticationType.oAuth2: return AuthenticationType[AuthenticationType.oAuth2];
        case AuthenticationType.ApiKey: return AuthenticationType[AuthenticationType.ApiKey];
        default: return "";
    }
}