import { AdditionalSettingType } from "./AdditionalSettingsType";
import NameValueWithTypeAndPositionInput, { INameValueWithTypeAndPositionInput } from "./NameValueWithTypeAndPositionInput";
import OutboundSAMLQueryStringParameterInput from "./OutboundSAMLQueryStringParameterInput";
import SsoData from "./SsoData";
import { KeyValue } from "../ProviderDetails/UpsertProviderModel";

export const OUTBOUND_SSO_URL_PLACEHOLDER: string = "https://#SUBDOMAIN_PLACEHOLDER#.csod.com/outboundsso.aspx?ou_id=";

export enum TimestampType {
    YYYYMMDDTHHMMSSZ = "yyyy-MM-ddTHH:mm:ssZ",
    YYYYMMDDTHHMMSSFZ = "yyyy-MM-ddTHH:mm:ss.fZ",
    YYYYMMDDTHHMMSSFFZ = "yyyy-MM-ddTHH:mm:ss.ffZ",
    YYYYMMDDTHHMMSSFFFZ = "yyyy-MM-ddTHH:mm:ss.fffZ",
    YYYYMMDDTHHMMSSFFFFZ = "yyyy-MM-ddTHH:mm:ss.ffffZ",
    YYYYMMDDTHHMMSSFFFFFZ = "yyyy-MM-ddTHH:mm:ss.fffffZ",
    YYYYMMDDTHHMMSSFFFFFFZ = "yyyy-MM-ddTHH:mm:ss.ffffffZ",
}

export const DEFAULT_ADDITIONAL_SETTINGS: INameValueWithTypeAndPositionInput[] =  [
    {
      name: "AssertionNamespaceAlias",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "saml"
    },
    {
      name: "AuthnContextClassRef",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "urn:oasis:names:tc:SAML:2.0:ac:classes:Password"
    },
    {
      name: "AuthnRequestNamespaceAlias",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "samlp"
    },
    {
      name: "AuthnRequestTimeframe",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "5"
    },
    {
      name: "IssuerNamespaceAlias",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "saml"
    },
    {
      name: "RelaystateParamName",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "Relaystate"
    },
    {
      name: "ResponseNamespaceAlias",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "samlp"
    },
    {
      name: "SAMLAssertion",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "<Assertion xmlns=\"urn:oasis:names:tc:SAML:2.0:assertion\" ID=\"\" IssueInstant=\"\"><Issuer></Issuer><Subject><NameID Format=\"urn:oasis:names:tc:2.0:nameidformat:persistent\"></NameID></Subject><Conditions NotBefore=\"\" NotOnOrAfter=\"\"><AudienceRestriction><Audience>getabstract</Audience></AudienceRestriction></Conditions><AuthnStatement AuthnInstant=\"\"><AuthnContext><AAuthnContextClassRef>urn:oasis:names:tc:2.0:ac:classes:Password</AAuthnContextClassRef></AuthnContext></AuthnStatement><AttributeStatement></AttributeStatement></Assertion>"
    },
    {
      name: "SAMLResponse",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "<Response xmlns=\"urn:oasis:names:tc:SAML:2.0:protocol\" ID=\"\" IssueInstant=\"\" Version=\"2.0\"><Status><StatusCode Value=\"urn:oasis:names:tc:2.0:status:Success\"/></Status></Response>"
    },
    {
      name: "SubjectConfirmationMethod",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "urn:oasis:names:tc:SAML:2.0:cm:bearer"
    },
    {
      name: "Timeframe",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "10"
    },
    {
      name: "Version",
      position: 0,
      type: "outbound" as AdditionalSettingType,
      value: "2.0"
    }
  ]

  export const AP_USERID_NAME = "currentuser_refid";
  export const AP_USERNAME_NAME = "currentuser_username";
  export const AP_EMAIL_NAME = "currentuser_email";
  export const AP_FIRSTNAME_NAME = "currentuser_firstname";
  export const AP_LASTNAME_NAME = "currentuser_lastname";
  export const AP_MIDDLENAME_NAME = "currentuser_middlename";
  export const AP_MANAGERID_NAME = "currentuser_managerid";
  export const AP_USERDIVISION_NAME = "currentuser_userdivision";
  export const AP_USERLOCATION_NAME = "currentuser_userlocation";
  export const AP_LNGUAGE_NAME = "currentuser_language";
  export const AP_PHONEHOME_NAME = "currentuser_phonehome";
  export const AP_WORKPHONE_NAME = "currentuser_workphone";

export default class SSOConfigModel {
  constructor(
      public EnableSSO: boolean = false,
      public SSOIDOUID?: number,  
      public ACSURL?: string,
      public TimestampFormat?: TimestampType,
      public Issuer?: string,
      public IsInternal: boolean = true,
      public Audience?: string,
      public authenticationParameters: KeyValue[] = [], 
      public IsSpInitiatedEnabled: boolean = false,
      public AuthenticationRequestIssuer: string = "",
      public SignatureWithinAssertion: boolean = false,
      public IncludeIssuerInResponse: boolean = false,
      public QuerystringParameters: OutboundSAMLQueryStringParameterInput[] = [],
      public AdditionalSettings: NameValueWithTypeAndPositionInput[] = DEFAULT_ADDITIONAL_SETTINGS
  ) { }

  public static ComposeSSOConfigModel = (ssoDataInput: SsoData) => {
    return new SSOConfigModel(
      true,
      ssoDataInput.ssoId,
      ssoDataInput.acsUrl,
      ssoDataInput.timeStampFormat as TimestampType, 
      ssoDataInput.issuer, 
      ssoDataInput.isInternal,
      ssoDataInput.audience,
      ssoDataInput.authenticationParameters,
      ssoDataInput.spInitiated,
      ssoDataInput.authRequestIssuer,
      ssoDataInput.signatureWithinAssertion? ssoDataInput.signatureWithinAssertion : false,
      ssoDataInput.includeIssuerInResponse? ssoDataInput.includeIssuerInResponse : false,
      ssoDataInput.queryStringParameters.map(i => new OutboundSAMLQueryStringParameterInput(i.querystringParameterName,i.samlAttributeName)),
      ssoDataInput.additionalSettings
    )
  }
}