import { observable, action } from 'mobx';
import GraphQLHelper from '../helpers/common/GraphQLHelper';
import EcaCourseGridViewModel from '../models/ExploreContentAnytimeModels/EcaCourseGridViewModel';
import EcaConstants from '../constants/ExploreContentAnytimeConstants';
import EcaCourseModel from '../models/ExploreContentAnytimeModels/EcaCourseModel';
import axios from 'axios';
import { StringifyOptions } from 'querystring';


export default class EcaStore {
    @observable Courses: any[] = observable([]);
    @observable CurrentCourse: EcaCourseModel | null = null;
    @observable CurrentPage: number = 1;
    @observable LastModifiedBy: String | null = null;
    @observable TotalPages: number = 1;
    @observable bundleId: string = "";
    LanguageNames: any =  new Intl.DisplayNames(['en'], { type: 'language' });
    ScriptNames:any  =new Intl.DisplayNames(['en'], { type: 'script' });
    RegionNames: any = new Intl.DisplayNames(['en'], { type: 'region' });


    @action
    loadCourses = async (bundleId: String) => {
        if (bundleId == null)
            return;

        console.log(bundleId)
        let dbCourses = await GraphQLHelper.GetCuratedCoursesAsUrl(bundleId, []);
        dbCourses.forEach(element => {
            element.Checked = false;
        });
        if (dbCourses != null) {
            this.Courses = dbCourses;
            this.TotalPages = Math.ceil(this.Courses.length / EcaConstants.ITEMS_PER_PAGE_COUNT);
        }
    }

    async downloadUrl(url: string): Promise<string> {
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            return '';
        }
    }

    async parallelDownload(urls: string[]): Promise<any[]> {
        const downloadPromises: Promise<any>[] = [];
        
        // Create an array of download promises
        for (const url of urls) {
            downloadPromises.push(this.downloadUrl(url));
        }
              // Wait for all promises to be resolved
        const results: any[] = await Promise.all(downloadPromises);
        
        return results;
    }

    distinctFaster(array){
        let uniqueObjectsMap = {};
        array.forEach(obj => {
            if(uniqueObjectsMap[obj.GlobalContentID])
            {
                uniqueObjectsMap[obj.GlobalContentID].IsActive = uniqueObjectsMap[obj.GlobalContentID].IsActive || obj.IsActive;
                uniqueObjectsMap[obj.GlobalContentID].IsForBundleRemoval = uniqueObjectsMap[obj.GlobalContentID].IsForBundleRemoval && obj.IsForBundleRemoval;
                uniqueObjectsMap[obj.GlobalContentID].BundleNames.push(obj.BundleName)
                uniqueObjectsMap[obj.GlobalContentID].BundleIds.push(obj.BundleID)
            }
            else
            {
                uniqueObjectsMap[obj.GlobalContentID] = obj;
                uniqueObjectsMap[obj.GlobalContentID].BundleNames = [obj.BundleName]
                uniqueObjectsMap[obj.GlobalContentID].BundleIds = [obj.BundleID]
            }
        });
        // Convert the object back to an array of unique objects
        return  Object.values(uniqueObjectsMap);
    }

    @action
    loadOrgCourses = async (orgId: String) => {

        let orgCourses= await GraphQLHelper.FetchOrgCuratedCourses(orgId);
        await this.parallelDownload(orgCourses.data.fetchCoursesPerOrg.BundlePresignedUrls).then((results) => {
            this.Courses = this.distinctFaster(results.filter(item => item !== "") .flat());
            if (orgCourses.data.fetchCoursesPerOrg.MetadataLanguages.length > 0){
                this.Courses = this.Courses.filter(x => orgCourses.data.fetchCoursesPerOrg.MetadataLanguages.some(value=>x.Languages.includes(value)))
            }
            this.TotalPages = Math.ceil(this.Courses.filter(x => x.IsActive && !x.IsForBundleRemoval).length / EcaConstants.ITEMS_PER_PAGE_COUNT);
        })
        return orgCourses.data.fetchCoursesPerOrg
    }

    @action

    FormatLanguageTag(languageTag:string) {
        let subtags = languageTag.split('-');
        try{
            let languageCode = subtags[0];
            let scriptCode = null;
            let regionCode = null;
        
            if (subtags.length === 2) {
                // If the second subtag is a script (4 letters) or a region (2 letters or digits)
                if (subtags[1].length === 4) { 
                    scriptCode = subtags[1];
                } else {
                    regionCode = subtags[1];
                }
            } else if (subtags.length === 3) { 
                scriptCode = subtags[1];
                regionCode = subtags[2];
            }
            let language = this.LanguageNames.of(languageCode);
            let script = scriptCode ? this.ScriptNames.of(scriptCode) : '';
            let region = regionCode ? this.RegionNames.of(regionCode) : '';
        
            return `${language}${script ? ' (' + script + ')' : ''}${region ? ' (' + region + ')' : ''}`;
        }
        catch{
            return languageTag;
        }
    }

    @action
    CheckAllCheckboxs = (checked, courses:any[] = []) => {
        if (courses.length > 0 && checked)
            courses.filter(x => x.IsActive && !x.IsForBundleRemoval).forEach(element => element.Checked = checked)
        else
            this.Courses.filter(x => x.IsActive && !x.IsForBundleRemoval).forEach(element => element.Checked = checked)
    }

    @action
    SetArrayChecked = (gcids) => {
        this.Courses.filter(x => x.IsActive && !x.IsForBundleRemoval && gcids.includes(x.GlobalContentID)).forEach(element => element.Checked = true)
    }

    @action
    ChangeCheckbox = (gcid, checked) => {
        try {
            this.Courses.find(x => x.GlobalContentID === gcid).Checked = checked
        } catch (err) {
            console.log(err)
        }

    }

    cleanData = () => {
        this.CurrentCourse = null;
    }

    @action
    loadCurrentCourse = (course: EcaCourseGridViewModel) => {
        this.CurrentCourse = this.Courses.filter(b => b.GlobalContentID === course.GlobalContentID)[0];
    }

    @action
    setNewCourse = (course: EcaCourseModel | null) => {
        this.CurrentCourse = course;
    }

    @action
    getCourse = (courseId: string) => {
        return this.Courses.find(b => b.GlobalContentID === courseId);
    }

    @action
    sortCourses = (compareFn?: ((a: EcaCourseModel, b: EcaCourseModel) => number) | undefined) => {
        this.Courses = this.Courses.slice().sort(compareFn);
    }
}