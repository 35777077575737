import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import "./HeaderComponent.css";
import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router';
import { PROVIDERS_PAGE_PATH, PACKGES_PAGE_PATH, PROVIDER_DATA_PATH, ORGANIZATIONS_PATH,ECA_BUNDLES_PATH, ECA_COURSES_PATH,  ECA_SUBSCRIPTION_PATH,ECA_ORG_CC_PATH } from '../../Settings';
import { validate as isValidUUID } from 'uuid';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';

interface HeaderComponentProps {
    routing?: RouterStore,
    globalLoader?: GlobalLoaderStore,
    authData?: any
}

interface headerComponentState{
    redirectProvider: boolean,
    redirectPackages: boolean,
    redirectOrganizations: boolean,
    redirectProviderData: boolean,
    redirectEcaBundles: boolean,
    redirectOrgCca: boolean,
    redirectEcaCourses: boolean,
    redirectEcaSubscriptions: boolean
}
const initialState = {
    redirectProvider: false,
    redirectPackages: false,
    redirectOrganizations: false,
    redirectProviderData: false,
    redirectEcaBundles: false,
    redirectOrgCca: false,
    redirectEcaCourses: false,
    redirectEcaSubscriptions: false
}

@inject("routing", "globalLoader")
@observer
export default class HeaderComponent extends Component<HeaderComponentProps,headerComponentState> {
    constructor(props: HeaderComponentProps) {
        super(props);
        this.state = initialState;
    }

    private LogOutClickHandle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.props.authData.resetUser();
        Auth.signOut();
    }
    redirectToProvider = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ ...initialState, redirectProvider: true });
    }
    redirectToPackages = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ ...initialState, redirectPackages: true });
    }
    redirectToEcaBundles = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {        
        event.preventDefault();
        this.setState({ ...initialState, redirectEcaBundles: true });
    }
    redirectToEcaCourses = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ ...initialState, redirectEcaCourses: true });
    }
    redirectToEcaSubscriptions = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ ...initialState, redirectEcaSubscriptions: true });
    }
    redirectToProviderData = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ ...initialState, redirectProviderData: true });
    }
    redirectToOrganizations = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ ...initialState, redirectOrganizations: true });
    }

    redirectToOrgCca  = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        this.setState({ ...initialState, redirectOrgCca: true });
    }

    showProviders = () => {
         { return (this.props.authData?.groups?.some((element:string) => ['Admins','Contentops','Readonly'].includes(element))) &&
         <div className="csod-nav-text" onClick={this.redirectToProvider}>PROVIDERS</div>}
    }
    showProvidersData = () => {
         { return (this.props.authData?.groups?.some((element:string) => ['Admins','Contentops','Readonly'].includes(element))) &&
         <div className="csod-nav-text" onClick={this.redirectToProviderData}>PROVIDERS DATA</div>}
    }
    showPackages = () => {
         { return (this.props.authData?.groups?.some((element:string) => ['Admins','Contentops','Readonly'].includes(element))) &&
         <div className="csod-nav-text" onClick={this.redirectToPackages}>PACKAGES</div>}
    }
    showEcaBundles = () => {         
        { return (this.props.authData?.groups?.some((element:string) => ['Admins','Contentops'].includes(element))) &&
        <div className="csod-nav-text" onClick={this.redirectToEcaBundles}>AICC DOWNLOAD</div>}
    }

    showOrganizations = () => {
         { return (this.props.authData?.groups?.some((element:string) => ['Admins','OrganizationManage'].includes(element))) &&
         <div className="csod-nav-text" onClick={this.redirectToOrganizations}>ORGANIZATION</div>}
    }
    showOrgsCca = () => {
        if(this.props.authData?.groups?.some((element:string) => isValidUUID(element))){
            if(!this.state.redirectOrgCca)
                this.setState({ ...initialState, redirectOrgCca: true });
        }
            
        { return (this.props.authData?.groups?.some((element:string) => isValidUUID(element) || ['Admins'].includes(element) || ['Contentops'].includes(element)  )) &&
        <div className="csod-nav-text" onClick={this.redirectToOrgCca}>CATALOG</div>}
   }

    render() {
        return this.props.authData?.attributes == null ? null :
            <div>
                <div className="csod-header-container">
                    <div className="csod-app-logo non-selectable" />
                    <div className="csod-header-user-inputs-container">
                        <div className="csod-nav-link non-selectable">         
                            {this.showProviders()}   
                            {this.showProvidersData()}             
                            {this.showPackages()}
                            {this.showOrganizations()}
                            {/* {this.showEcaBundles()} */}
                            {this.showOrgsCca()}
                        </div>
                        <div className="csod-username non-selectable" >{this.props.authData!.attributes!.email}</div>
                        <div className="csod-logout non-selectable" onClick={this.LogOutClickHandle}>LOG OUT</div>
                    </div>
                </div>
                {this.state.redirectProvider ? <Redirect push={true} to={PROVIDERS_PAGE_PATH} /> : null}
                {this.state.redirectProviderData ? <Redirect push={true} to={PROVIDER_DATA_PATH} /> : null}
                {this.state.redirectPackages ? <Redirect push={true} to={PACKGES_PAGE_PATH} /> : null}
                {this.state.redirectOrganizations ? <Redirect push={true} to={ORGANIZATIONS_PATH} /> : null}
                {this.state.redirectEcaBundles ? <Redirect push={true} to={ECA_BUNDLES_PATH} /> : null}                
                {this.state.redirectOrgCca ? <Redirect push={true} to={ECA_ORG_CC_PATH} /> : null}                
            </div>
    }
}