import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import EcaGridViewModel from '../../models/ExploreContentAnytimeModels/EcaBundleGridViewModel';
import "./EcaBundleItem.css";
import { providerOfferingTypeToString } from '../../models/ProviderDetails/ProviderOfferingType';
import { TrainingType } from '../../models/ProviderDetails/TrainingType';
import EcaConstants from '../../constants/ExploreContentAnytimeConstants';
import EcaStore from '../../stores/EcaBundleStore';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import { SubjectMapping } from '../../models/ProviderDetails/ProviderDetailsExpansion';
import SSOConfigModel from '../../models/SSOConfig/SSOConfigModel';
import ProviderToolData from '../../models/TestSubscription/ProviderToolData';
import SsoData from '../../models/SSOConfig/SsoData';
import UpsertProviderModel from '../../models/ProviderDetails/UpsertProviderModel';
import { AuthenticationType } from '../../models/ProviderDetails/AuthenticationType';
import SyncStatusSuccessImg from '../../resources/csod-green-checkmark.svg';
import SyncStatusFailedImg from '../../resources/csod-red-cross.svg';
import { StringDecoder } from 'string_decoder';
import { Auth } from 'aws-amplify';
import  { GenericSuccessPopupComponent } from '../common/popups/PopupComponent';

interface EcaItemProps {
    RedirectToCourses: (id: string) => void,
    Bundle: EcaGridViewModel,
    providers?: EcaStore,
    globalLoader?: GlobalLoaderStore,
    cy_data?: string
}

interface EcaItemState {
    Bundle: EcaGridViewModel | null,
    Email: string,
    showPopup: boolean,
    modalTitle: string,
    modalMessage: string
}

@inject("bundles", "globalLoader")
@observer
export default class EcaItem extends Component<EcaItemProps, EcaItemState> {
    constructor(props: EcaItemProps) {
        super(props);


        this.state = {
            Bundle: props.Bundle,
            Email: "",
            showPopup: false,
            modalTitle: "",
            modalMessage: ""
        }
        console.log("End Constructor", this.state)

    }

    async componentDidMount() {
        let auth = await Auth.currentAuthenticatedUser()
        let email = auth.attributes["email"];
        this.setState({
            Bundle: this.props.Bundle,
            Email: email,
            showPopup: false,
            modalTitle: "",
            modalMessage: ""
        });
    }

    getModal = () => {
        return <GenericSuccessPopupComponent Title={this.state.modalTitle} Message={this.state.modalMessage} OnClose={() => { this.setState({ showPopup: false }) }} OnDone={() => { this.setState({ showPopup: false }) }} />
    }
    downloadBundle = async (type: string) => {
        GraphQLHelper.GetPersignedUrl(this.props.Bundle.Id, this.props.Bundle.Name, [], type, true, this.state.Email); //Empty courses array because we don't need it in bundle store
        let title = "Download Via Email"
        let message = "Download Link Sent To:" + this.state.Email
        this.setState({ showPopup: true, modalMessage: message, modalTitle: title })
    }



    private onProviderClick = (id) => {

        this.props.RedirectToCourses(id);
        return;

    }


    render() {
        if (this.state.showPopup)
            return this.getModal()

        return <tr cy-data={this.props.cy_data}>
            <td onClick={() => this.onProviderClick(this.props.Bundle.Id)} data-th={EcaConstants.ECA_GRID_ID_TITLE} className='csod-table-td csod-provider-name'>{this.props.Bundle.Id}</td>
            <td onClick={() => this.onProviderClick(this.props.Bundle.Id)} data-th={EcaConstants.ECA_GRID_NAME_TITLE} className='csod-table-td csod-provider-name'>{this.props.Bundle.Name}</td>
            <td onClick={() => this.onProviderClick(this.props.Bundle.Id)} data-th={EcaConstants.ECA_GRID_DESCRIPTION_TITLE} className='csod-table-td' >{this.props.Bundle.Description}</td>
            {/* <td onClick={() => this.onProviderClick(this.props.Bundle.Id)} data-th={EcaConstants.ECA_GRID_NO_OF_COURSES_TITLE} className='csod-table-td' >{this.props.Bundle.NoOfCourses}</td> */}
            <td data-th={EcaConstants.ECA_GRID_DOWNLOAD_AICC_PACKAGES_TITLE} className='csod-table-td'>{this.props.Bundle.DownloadAiccPackages}  <a onClick={() => this.downloadBundle("zip")} href="#" > Download AICC</a> </td>
            <td data-th={EcaConstants.ECA_GRID_DOWNLOAD_AICC_METADATA_TITLE} className='csod-table-td'>{this.props.Bundle.DownloadAiccMetaData} <a onClick={() => this.downloadBundle("csv")} href="#" > Download CSV</a> </td>
        </tr>
    }
}




