import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { ISignInProps } from 'aws-amplify-react/lib-esm/Auth/SignIn';
import { Auth } from 'aws-amplify';
import LoaderComponent from '../common/LoaderComponent';
import ForgotPassword from './ForgotPassword';
import '@aws-amplify/ui/dist/style.css';
import "./LoginComponent.css"
import CodeHelpers from '../../helpers/common/CodeHelpers';

const SUPPORT_EMAIL = "ProvidersToolSupport@csod.com";
const LOGIN_TITLE = "CDS Management Tool";
const CONTACT_FOOTER = "Having trouble logging in?";
const EMAIL_AUTH_ERROR_MESSAGE = "Please, enter your email";
const PASSWORD_AUTH_ERROR_MESSAGE = "Please, enter your password";

export default class LoginComponent extends SignIn {
    constructor(props: ISignInProps) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    }

    componentDidMount = () => {
        this.setState({ loading: false ,forgotPassword:false})
    }

    loginButtonClickHandle = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (CodeHelpers.IsStringNullOrEmpty(this.inputs.username)) {
            document.getElementsByClassName("csod-auth-error-message")[0]!.innerHTML = EMAIL_AUTH_ERROR_MESSAGE;
        } else if (CodeHelpers.IsStringNullOrEmpty(this.inputs.password)) {
            document.getElementsByClassName("csod-auth-error-message")[0]!.innerHTML = PASSWORD_AUTH_ERROR_MESSAGE;
        } else {
            this.setState({ loading: true });
            super.signIn(event).finally(() => { this.setState({ loading: false }) })
        }
    }
   

    changeShowForgotPassword = (showForgotPassword:boolean) =>{
        this.setState({forgotPassword:showForgotPassword})

    }
    renderforgotPassword = () =>{
        return <ForgotPassword changeShowForgotPassword={this.changeShowForgotPassword}/>
    }
    renderLogin = () => {

        return <div className="csod-auth-components-container">
            <div className="csod-outer-auth-container">
                <form className="csod-auth-container" onSubmit={this.loginButtonClickHandle}>
                    <div className="csod-app-logo non-selectable" />
                    <div className="csod-header-text-1 non-selectable">{LOGIN_TITLE}</div>
                    <div className="csod-auth-error-message" />
                    <div className="csod-auth-inputs-container">
                        <div className="csod-text-box-container hard-rounded-corners" >
                            <input id="username" key="username" name="username" type="email" className="csod-text-box" placeholder="Email Adress" onChange={this.handleInputChange} />
                        </div>
                        <div className="csod-text-box-container hard-rounded-corners" >
                            <input id="password" key="password" name="password" type="password" className="csod-text-box" placeholder="Password" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <button type="submit" className="csod-button login crimson non-selectable rounded-corners m10" >{this.state.loading ? <LoaderComponent isLoading={this.state.loading} /> : "Login"}</button>
                    <button onClick={()=> this.changeShowForgotPassword(true)} className="csod-button login gray non-selectable rounded-corners">Forgot password</button>

                    <div className="csod-remind-text non-selectable">
                        <span>
                            <a className="App-link" href={'mailto:' + SUPPORT_EMAIL}>{CONTACT_FOOTER}</a>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    }

    showComponent() {
      return  this.state.forgotPassword? this.renderforgotPassword() : this.renderLogin ()
    }
}