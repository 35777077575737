
export default class OrganizationDetailsErrorModel {
    constructor(
        public nameErrorMessage: string = "", 
        public descriptionErrorMessage: string = "", 
        public learningPlatformErrorMessage: string = "", 
        public salesForceIdErrorMessage: string = "",
        public learningHostUrlErrorMessage: string = "", 
        public aiccUrlErrorMessage: string = "", 
        public tenantIdentifierErrorMessage: string = "", 
        public organizationCorpErrorMessage: string = "",
        public ccaBundleErrorMessage: string = "", 
        public createdDateErrorMessage: string = "", 
        public integrationTypeErrorMessage: string = "",
        public ssoTypeErrorMessage: string = "",
        public envTypeErrorMessage: string = "",
        public ssoIdErrorMessage: string = "", 
        public subscriberUriErrorMessage: string = "",
        public ssoExternalLmsErrorMessage: string = "", 
        public ssoCorpNameErrorMessage: string = "", 
        public ssoIssuerErrorMessage: string = "", 
        public ssoUserIdentifierTypeErrorMessage:string ="",
        public ssoSamlValidationConfigurationErrorMessage: string = "",
        public ssoSamlRequestEncryptionErrorMessage:string="",
        public ssoSamlResponsePbkErrorMessage: string = "",
        public ssoSamlRequestPbkErrorMessage: string = "",
        public ssoDestinationErrorMessage: string = "", 
        public ssoAssertConsumersServiceUrlErrorMessage: string = "", 
        public ssoAudienceErrorMessage: string = "", 
        public ssoIdpSignedRequestErrorMessage: string = "", 
        public authenticationUserNameErrorMessage: string = "", 
        public authenticationPasswordErrorMessage: string = "", 
        public authenticationClientIdErrorMessage: string = "", 
        public authenticationClientSecretErrorMessage: string = "", 
        public xapiAuthTokenUrlErrorMessage: string = "", 
        public xapiClientIdErrorMessage: string = "", 
        public xapiClientSecretErrorMessage: string = "", 
        public xapiScopeErrorMessage: string = "", 
        public xapiAuthTypeErrorMessage: string = "",
        public xapiGrantTypeErrorMessage: string = "", 
        public xapiActivityStatementUrlErrorMessage: string = "",
        public xapiAccountHomepageErrorMessage: string = "",
        public tokenFreeformHeaderErrorMessage: string = "",
        public tokenFreeformBodyErrorMessage: string = ""
    ) {
     } 
}