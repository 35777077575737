export enum TrainingType {
    MOOC = "MOOC",
    Asset = "Asset",
    AICC = "AICC",
    LTI = "LTI"
}

export const mapToTrainingType = (value: string): TrainingType | undefined=> {
    switch(value) {
        case TrainingType.MOOC: return TrainingType.MOOC;
        case TrainingType.Asset: return TrainingType.Asset;
        case TrainingType.AICC: return TrainingType.AICC;
        case TrainingType.LTI: return TrainingType.LTI;
        default: return undefined;
    }
}