import ErrorMessagesWithProblemElementId from "./ErrorMessagesWithProblemElementId"

export default class ProviderDetailsErrorModel extends ErrorMessagesWithProblemElementId {
    constructor(
        public nameErrorMessage: string = "", 
        public abbreviationErrorMessage: string = "", 
        public CIIDErrorMessage: string = "", 
        public SyncURLErrorMessage: string = "", 
        public TrainingTypeErrorMessage: string = "", 
        public OfferingTypeErrorMessage: string = "", 
        public AuthenticationTypeErrorMessage: string = "",
        public UsernameErrorMessage: string = "",
        public PasswordErrorMessage: string = "",
        public AuthenticationURLErrorMessage: string = "",
        public ClientIDErrorMessage: string = "",
        public ClientSecretKeyErrorMessage: string = "",
        public SyncHourErrorMessage: string = "",
        public EmailsErrorMessage: string = "",
        public elementId: string = "",
        public ProviderNotesErrorMessage: string = ""
    ) {
        super(elementId);
     } 
}