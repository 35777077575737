import ProviderModel from "../ProviderDetails/ProviderModel";
import { ProviderOfferingType } from "../ProviderDetails/ProviderOfferingType";
import { TrainingType } from "../ProviderDetails/TrainingType";

export default class ProviderGridViewModel {
    constructor(
        public SyncStatus: string = "",
        public Name: string = "",
        public ID: string = "",
        public Abbrev: string = "",
        public OfferingType: ProviderOfferingType = ProviderOfferingType.Both,
        public ProviderTrainingType: TrainingType = TrainingType.MOOC
    ) { }
    
    public static Map(source: ProviderModel) : ProviderGridViewModel {
        return new ProviderGridViewModel(source.SyncStatus,source.Name, source.Id, source.Abbreviation, source.ProviderOfferingType, source.Type);
    } 
}