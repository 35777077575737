import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProviderDataGridViewModel from '../../models/ProviderDataGrid/ProviderDataGridViewModel';
import "./ProviderDataItem.css";
import ProviderDataConstants from '../../constants/ProviderDataConstants';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import ProviderDataStore from '../../stores/ProviderDataStore';


interface ProviderDataItemProps {
    ProviderData: ProviderDataGridViewModel,
    RedirectToUpdate: () => void,
    ProviderDataStore?: ProviderDataStore,
    globalLoader?: GlobalLoaderStore,
    cy_data?: string
}

interface ProviderDataItemState {
    ProviderData: ProviderDataGridViewModel | null;
}

@inject("ProviderData", "globalLoader")
@observer
export default class ProviderDataItem extends Component<ProviderDataItemProps, ProviderDataItemState> {
    constructor(props: ProviderDataItemProps) {
        super(props);
        this.state = {
            ProviderData: props.ProviderData
        }
    }
    render() {
        let syncDate = this.props.ProviderData.SyncDate ? new Date(this.props.ProviderData.SyncDate).toUTCString() : "";
        let successDate = this.props.ProviderData.LastSuccessfulSyncDate ? new Date(this.props.ProviderData.LastSuccessfulSyncDate).toUTCString() : "";
        return <tr cy-data={this.props.cy_data}>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_STATE_TYPE} className='csod-table-td'>{this.props.ProviderData.StateType}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_PROVIDER_ID} className='csod-table-td'>{this.props.ProviderData.ProviderId}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_TRAINING_TYPE} className='csod-table-td' >{this.props.ProviderData.TrainingType}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_SYNC_DATE} className='csod-table-td'>{syncDate}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_URL} className='csod-table-td'>{this.props.ProviderData.Url}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_LAST_SUCCESSFUL_SYNC_DATE} className='csod-table-td'>{successDate}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_TRAININGS_COUNT} className='csod-table-td'>{this.props.ProviderData.TrainingsCount}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_CORRELATION_ID} className='csod-table-td'>{this.props.ProviderData.CorrelationId}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_ERROR_TYPE} className='csod-table-td'>{this.props.ProviderData.ErrorType}</td>
            <td data-th={ProviderDataConstants.PROVIDER_DATA_GRID_ERROR_MESSAGE} className='csod-table-td'>{this.props.ProviderData.ErrorMessage}</td>
        </tr>
    }
}