import { observable, action } from 'mobx';
import GraphQLHelper from '../helpers/common/GraphQLHelper';
import { FrameworkModes } from '../models/ProviderFramework/FrameworkMode';
import ProviderModel from "../models/ProviderDetails/ProviderModel";

import ProviderDataDetailsExpansion from '../models/ProviderDataDetails/ProviderDataModel';
import ProviderDataGridViewModel from '../models/ProviderDataGrid/ProviderDataGridViewModel';
import ProviderDataModel from '../models/ProviderDataDetails/ProviderDataModel';
import SSOConfigModel from '../models/SSOConfig/SSOConfigModel';
import { TestSubscriptionOption } from '../models/TestSubscription/TestSubscriptionOption';
import ProviderDataConstants from '../constants/ProviderDataConstants';


export default class ProviderDataStore {
    @observable ProviderData: ProviderDataModel[] = observable([]);
    @observable CurrentProviderData: ProviderDataModel | null = null;
    @observable CurrentSSOConfigModel: SSOConfigModel = new SSOConfigModel();
    @observable CurrentPage: number = 1;
    @observable LastModifiedBy: String | null = null;
    @observable ProviderDataModified: String | null = null;
    @observable TotalPages: number = 1;
    @observable FrameworkMode: FrameworkModes = FrameworkModes.Create;

    @action
    loadProviderData = async () => {
        return GraphQLHelper.FetchProviderDataByQuery().then((res: any) => {           
            if(res != null){
                this.ProviderData = res.data.providerDataList;
                this.TotalPages = Math.ceil(this.ProviderData.length / ProviderDataConstants.ITEMS_PER_PAGE_COUNT);
            }
        })
        .catch(err => {
            console.log(err.message);
        });

    }

    @action
    loadCurrentProviderData = (ProviderData: ProviderDataGridViewModel) => {
        return true; //this.CurrentProviderData = this.ProviderData.filter(p => p.Id === ProviderData.ID && p.Type === ProviderData.ProviderDataTrainingType)[0];
    }

    @action
    setNewProviderData = (ProviderData: ProviderDataModel | null) => {
        this.CurrentProviderData = ProviderData;
    }

    @action
    getProviderData = (ProviderDataId: string) => {
        return this.ProviderData.find(p => p.PK === ProviderDataId);
    }

    @action
    sortProviderData = (compareFn?: ((a: ProviderDataModel, b: ProviderDataModel) => number) | undefined) => {
        this.ProviderData = this.ProviderData.slice().sort(compareFn);
    }
}