import React, { Component } from 'react';
import "./RadioButtonGroupComponent.css";
import InfoIcon from '@material-ui/icons/Info';
import { IconButton  } from '@material-ui/core';

interface RadioButtonGroupComponentState {
    checkedValue: string
}

interface RadioButtonGroupComponentProps {
    groupTitle: string, 
    groupName: string, 
    names: string[], 
    tooltipLink: string,
    customRadioTitle?: string,
    defaultValue: string | undefined,
    valueUsedIfDefaultIsNull: string,
    isEditButtonShowed?: boolean,
    onValueChanged: (value: string) => void,
    onEditClick: (event: React.MouseEvent<HTMLElement,MouseEvent>) => void,
    cy_data?: string
}

export default class RadioButtonGroupComponent extends Component<RadioButtonGroupComponentProps, RadioButtonGroupComponentState> {
    constructor(props: RadioButtonGroupComponentProps) {
        super(props);
        this.state = { checkedValue: this.props.defaultValue != null? this.props.defaultValue : this.props.valueUsedIfDefaultIsNull}
    }

    onRadioClick = (event:any) => {
        let value = event.target.value as string;
        if(value !== this.state.checkedValue) {
            this.setState({checkedValue: value});
            this.props.onValueChanged(value);
        }
    }

    onCustomRadioClick = (event: any) => {
        if(this.props.isEditButtonShowed != null? !this.props.isEditButtonShowed : true) {
            this.props.onEditClick(event);
        }
        this.onRadioClick(event);
    }

    render() {
        return <div className = "csod-inputs-column-container" cy-data={this.props.cy_data}>
            <div className="csod-radio-display-container">
                <div className = "csod-input-title-text alternative-margin-for-inputs non-selectable">{this.props.groupTitle}</div>
                {   this.props.tooltipLink ? 
                    <IconButton size="small" style={{top:'-3px'}}>
                        <a href={this.props.tooltipLink} target="_blank"><InfoIcon></InfoIcon></a>
                    </IconButton> : null
                }
            </div>
            
            <div className = "csod-inputs-row-container three-columns-container">
                {this.props.names.map(name => {
                    return <div key={name} className = "csod-inputs-row-container">
                        <label className="csod-input-radio-button-container">{name}
                            <input type="radio" name={this.props.groupName} defaultChecked={name === this.state.checkedValue? true : false} value={name} onClick={this.onRadioClick}/>
                            <span className="radio-button-checkmark"></span>
                        </label>
                    </div>
                })}
                {this.props.customRadioTitle != null ? <div className = "csod-inputs-row-container .two-columns-100-first-container no-flex-wrap">
                    <label className="csod-input-radio-button-container gray-text non-selectable">{this.props.customRadioTitle}
                        <input type="radio" name={this.props.groupName} defaultChecked={this.props.customRadioTitle === this.state.checkedValue? true: false} value={this.props.customRadioTitle} onClick={this.onCustomRadioClick}/>
                        <span className="radio-button-checkmark"></span>
                    </label>
                    {this.state.checkedValue === this.props.customRadioTitle || this.props.isEditButtonShowed? <button className = "csod-edit-button" onClick={this.props.onEditClick}/> : null}
                </div> : null}
            </div>
        </div>
    }
}