import React, { Component } from 'react';
import CodeHelpers from '../../../helpers/common/CodeHelpers';
import "./SwitcherComponent.css";

interface SwitcherComponentState {
    isChecked: boolean
}

interface SwitcherComponentProps {
    title: string,
    defaultValue: boolean | undefined,
    disabled?: boolean,
    additionalClass?: string,
    onValueChanged: (value: boolean) => void,
    cy_data?: string
}

export default class SwitcherComponent extends Component<SwitcherComponentProps, SwitcherComponentState> {

    constructor(props: SwitcherComponentProps) {
        super(props)
        this.state = {isChecked:  this.props.defaultValue != null? this.props.defaultValue : false}
    }

    isManualUpdated: boolean = false;

    onSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.isManualUpdated = true;
        this.setState({isChecked: event.target.checked})
        this.props.onValueChanged(event.target.checked);
    }

    componentDidUpdate = () => {
        if(this.props.defaultValue != null && this.state.isChecked !== this.props.defaultValue && !this.isManualUpdated) {
            this.setState({isChecked: this.props.defaultValue})
        }
        this.isManualUpdated = false;
    }

    render() {
        return <div className = {`csod-inputs-row-container margin-top-for-inputs ${this.props.additionalClass? this.props.additionalClass : ""} ${this.props.disabled != null && this.props.disabled? "disabled" : "" }`} cy-data={!CodeHelpers.IsStringNullOrEmpty(this.props.cy_data)? this.props.cy_data : ""}>
            <div className = "csod-input-title-text alternative-margin-for-inputs non-selectable">{this.props.title}</div>
            <label className="switch alternative-margin-for-inputs">
                <input type="checkbox" checked = {this.state.isChecked} onChange={this.onSwitch} disabled={this.props.disabled? this.props.disabled : false}/>
                <span className="slider round">
                    <span className="slider-cross"/>
                    <span className="slider-checkmark"/>
                </span>
            </label>
        </div>
    }
}