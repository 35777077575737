import EcaModel from "./EcaBundleModel";

export default class EcaBundleGridViewModel {
    constructor(
        public Name: string = "",        
        public Id: string = "",
        public Description: string = "",
        public NoOfCourses: string = "",
        public DownloadAiccPackages: string = "",
        public DownloadAiccMetaData: string = "",
    ) { }

    public static Map(source: EcaModel): EcaBundleGridViewModel {
        return new EcaBundleGridViewModel(source.Name, source.Id, source.Description, source.NoOfCourses, source.DownloadAiccPackages,
            source.DownloadAiccMetaData);
    }
}