import OutboundSAMLQueryStringParameterInput from "./OutboundSAMLQueryStringParameterInput";
import KeyValueWithTypeAndPositionErrorModel from "../SSOConfig/KeyValueWithTypeAndPositionErrorModel";

export default class SSOConfigErrorModel {
    constructor(
        public SSOIDOUIDErrorMessage: string = "", 
        public ACSURLErrorMessage: string = "", 
        public TimestampErrorMessage: string = "", 
        public AuthenticationRequestIssuerErrorMessage: string = "",
        public AdditionalSettingsErrorMessages: { [id: number]: KeyValueWithTypeAndPositionErrorModel; } | null = {},
        public QuerystringParametersErrorMessages: { [id: number]: OutboundSAMLQueryStringParameterInput; } | null = {}
    ) { } 
}