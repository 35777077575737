export default class EcaConstants {
    public static readonly ITEMS_PER_PAGE_COUNT = 100;

    //bundles
    public static readonly ECA_GRID_NAME_TITLE: string = "Name";
    public static readonly ECA_GRID_ID_TITLE: string = "ID";
    public static readonly ECA_GRID_DESCRIPTION_TITLE: string = "Description";
    public static readonly ECA_GRID_NO_OF_COURSES_TITLE: string = "NO. Of Courses";
    public static readonly ECA_GRID_DOWNLOAD_AICC_PACKAGES_TITLE: string = "Download AICC Packages";
    public static readonly ECA_GRID_DOWNLOAD_AICC_METADATA_TITLE: string = "Download AICC MetaData";

    //course
    public static readonly ECA_GRID_TITLE_TITLE: string = "Title";
    public static readonly ECA_GRID_PROVIDER_TITLE: string = "Providers";
    public static readonly ECA_GRID_GCID_TITLE: string = "GCID";
    public static readonly ECA_GRID_LANGUAGE_TITLE: string = "Language";
    public static readonly ECA_GRID_SUBJECT_TITLE: string = "Subjects";
    public static readonly ECA_GRID_DURATION_TITLE: string = "Duration";
    public static readonly ECA_GRID_UPDATE_DATE_TITLE: string = "Update Date";
    public static readonly ECA_GRID_DOWNLOAD_AICC_TITLE: string = "Download AICC";
    public static readonly ECA_GRID_DOWNLOAD_METADATA_TITLE: string = "Download MetaData";
    public static readonly ECA_GRID_METADATA_LANGUAGE_TITLE: string = "Languages";



    //Subscriptions
    public static readonly ECA_GRID_SUBSCRIBER_URI_TITLE: string = "SubscriberUri";
    public static readonly ECA_GRID_CCA_BUNDLE_NAME_TITLE: string = "CCA Bundle Name";    

}