import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProviderStore, { DEFAULT_TEST_SUBSCRIPTION_CONFIG } from '../../stores/ProvidersStore';
import EcaGridViewModel from '../../models/ExploreContentAnytimeModels/EcaBundleGridViewModel';
import './ExploreContentAnytimeGridComponent.css';
import EcaConstants from '../../constants/ExploreContentAnytimeConstants';
import PaginationComponent from '../providers/PagintationComponent';
import EcaModel from '../../models/ExploreContentAnytimeModels/EcaBundleModel';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import EcaStore from  '../../stores/EcaBundleStore'
import EcaCourseStore from  '../../stores/EcaCourseStore'
import React from 'react';
import EcaBundleItem from './EcaBundleItem'
import { RouterStore } from 'mobx-react-router';
import { ECA_COURSES_PATH } from '../../Settings';
import { Redirect } from 'react-router';
import { PROVIDERS_GRID_CY_DATA, PROVIDERS_GRID_ITEM_CY_DATA, PROVIDERS_GRID_PAGINATION_CY_DATA, PROVIDERS_SEARCH_BUTTON_CY_DATA, PROVIDERS_SEARCH_INPUT_CY_DATA } from '../../cypressDataConstants/providersGridCyDataConstants';
interface EcaGridComponentProps {
    bundles?: EcaStore,
    courses?:EcaCourseStore,
    globalLoader?: GlobalLoaderStore,
    routing?: RouterStore,
    
}

interface EcaGridComponentState {
    filterString: string,
    appliedFilterString: string,
    bundleInfo?: EcaGridViewModel,
    redirectToCourses: boolean    
}

const SEARCH_TITLE: string = "Search";
const ADD_NEW_PROVIDER: string = "New provider";

@inject('bundles', 'globalLoader', 'routing')
@observer
export default class EcaGridComponent extends Component<EcaGridComponentProps, EcaGridComponentState> {
    constructor(props: EcaGridComponentProps) {
        super(props);
;
        this.state = {
            filterString: "",
            appliedFilterString: "",
            redirectToCourses: false            
        }


    }
    
    componentDidMount() {       
        if(this.props.bundles?.Bundles.length == 0)
        {
            this.props.globalLoader!.startLoading();
            this.props.bundles!.loadBundles().finally(()=>this.props.globalLoader!.finishLoading());
        }
        
    }

    handleOnChangePage = (newCurrentPage: number) : Promise<any> => {
        return new Promise<any>((resolve) => {
            this.props.bundles!.CurrentPage = newCurrentPage;
            resolve(null);
        })
    }

    sortBundlesByName = (isDescending: boolean) => {
      
       this.props.bundles!.sortBundles((a,b) => {return isDescending? -(a.Name!.localeCompare(b.Name!)): (a.Name!.localeCompare(b.Name!))});
    }

    sortBundlesById = (isDescending: boolean) => {
      
       this.props.bundles!.sortBundles((a,b) => {return isDescending? -(a.Id!.localeCompare(b.Id!)): (a.Id!.localeCompare(b.Id!))});
    }

    onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === "") {
            this.setState({filterString: "", appliedFilterString: ""});
        } else {
            this.setState({filterString: e.target.value});
        }
    }

    onFilterApply = (event: any) => {
        event.preventDefault();
        this.setState({ appliedFilterString: this.state.filterString});
    }


    filterData = (isFilterByAppliedFilterString: boolean): EcaModel[] => {
        var filterString: string = (isFilterByAppliedFilterString? this.state.appliedFilterString : this.state.filterString).toLowerCase();
        

        if(this.props.bundles?.Bundles != null && this.props.bundles?.Bundles.length != 0) {
            return this.props.bundles!.Bundles.filter(i => 
                i.Name!.toLowerCase().match(filterString) || 
                i.Id!.toLowerCase().match(filterString)
            );
        }
        return [];
    }

    insertSearchTips = () => {
        var key: number = 0;
        return <datalist id="providers" className = "csod-search-tips"> 
            {this.props.bundles?.Bundles.map(i => <option key={key++} value={i.Name}/>).slice(0,7)}
        </datalist>
    }

    

    redirectToCourses = (id:string) => {
        this.props.bundles?.updateSelectedBundle(id);
        this.setState({redirectToCourses: true});
    }

    ResetCurrentSort = (idString:string) => {
        var elements = document.getElementsByClassName("sortable");
        while (elements.length)
            elements[0].classList.remove("sortable");
        
        var sortTarget = document.getElementById(idString);
        sortTarget?.classList.add("sortable");
    }

    renderGridHeaderItem = (title: string, onSort?: (isDescending: boolean)=>void) => {
        if (!onSort )
            return   <th scope='col'>
                <div className="csod-grid-header-container">
                    <div className = "csod-sorting-container">{title}</div>
                </div>
            </th>;
        else 
            return <th scope='col'>
                <div className="csod-grid-header-container">
                    {title}
                    <div className = "csod-sorting-container">
                    <div id={title + 'up'} className="csod-sort-up smallpin"  onClick= {() => { onSort(true); 
                            this.ResetCurrentSort(title + 'up')} }><div className="csod-icon-sort-up">▲</div></div>
                        <div id={title + 'down'} className="csod-sort-down smallpin" onClick= {() =>{ onSort(false)
                            this.ResetCurrentSort(title + 'down')} }><div className="csod-icon-sort-down">▼</div></div>
                    </div>
                </div>
            </th>;
    }
    
    render() {
        if (this.props.globalLoader!.isLoading) {
            return null;
        }
        if (this.state.redirectToCourses) {
            return <Redirect push={true} to = {ECA_COURSES_PATH}/>
        }
        var lastItemPerCurrentPageNumber = (this.props.bundles!.CurrentPage - 1) * EcaConstants.ITEMS_PER_PAGE_COUNT + EcaConstants.ITEMS_PER_PAGE_COUNT;
        var BundleList: EcaModel[] = this.filterData(true);
        var currentPage = this.props.bundles!.CurrentPage;

        if(lastItemPerCurrentPageNumber > this.props.bundles!.Bundles!.length){
            lastItemPerCurrentPageNumber = this.props.bundles!.Bundles!.length;
        } 
        return <div className = "csod-eca-container">
                <div className = "csod-search-container">
                    <div className = "csod-providers-count-title">Content Anytime Bundles({this.props.bundles!.Bundles.length})</div>
                    <div className = "csod-search-add-new-container">
                        <form className = "csod-search-form" onSubmit={this.onFilterApply}>
                            <div className = "csod-search-field-tips-container">
                                <input type = "text" list="providers" className = "csod-input-search-tb less-rounded-corners" placeholder = {SEARCH_TITLE} value={this.state.filterString} onChange={this.onFilterTextChange} cy-data={PROVIDERS_SEARCH_INPUT_CY_DATA}/>
                                {this.insertSearchTips()}
                            </div>
                            <button type="submit"  className = "csod-button less-rounded-corners gray" onClick={this.onFilterApply} cy-data={PROVIDERS_SEARCH_BUTTON_CY_DATA}>{SEARCH_TITLE}</button>
                        </form>
                    </div>
                </div>
                {BundleList.length === 0 ? null :
                <div>
                    <table className = "csod-providers-grid" cy-data={PROVIDERS_GRID_CY_DATA}>
                        <thead>
                            <tr>
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_ID_TITLE, this.sortBundlesById)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_NAME_TITLE , this.sortBundlesByName)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DESCRIPTION_TITLE)}
                                {/* {this.renderGridHeaderItem(EcaConstants.ECA_GRID_NO_OF_COURSES_TITLE)} */}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DOWNLOAD_AICC_PACKAGES_TITLE)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DOWNLOAD_AICC_METADATA_TITLE)}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                BundleList.slice((this.props.bundles!.CurrentPage - 1) * EcaConstants.ITEMS_PER_PAGE_COUNT, lastItemPerCurrentPageNumber).map((bundle) =>
                                <EcaBundleItem Bundle = {EcaGridViewModel.Map(bundle)} RedirectToCourses={()=>this.redirectToCourses(bundle.Id)}  key={bundle.Id}  cy_data={PROVIDERS_GRID_ITEM_CY_DATA}/>
                            )}
                        </tbody>
                    </table>
                    {this.props.bundles!.Bundles.length <= EcaConstants.ITEMS_PER_PAGE_COUNT? null : <PaginationComponent currentPageIndex={currentPage} listPages={this.props.bundles!.TotalPages} handleChangePage={this.handleOnChangePage} cy_data={PROVIDERS_GRID_PAGINATION_CY_DATA}/>}
                </div>} 
            </div>
    }
}