export const CANCEL_BUTTON_CY_DATA = "provider-framework-cancel-button";
export const SUBMIT_BUTTON_CY_DATA = "provider-framework-submit-button";

export const PROVIDER_DETAILS_TAB_CY_DATA = "provider-details-tab";
export const TEST_SUBSCRIPTIONS_TAB_CY_DATA = "test-subscriptions-tab";
export const SSO_CONFIGURATION_TAB_CY_DATA = "sso-configuration-tab";

export const CANCEL_POPUP_CY_DATA = "provider-framework-cancel-popup";
export const SUCCESS_POPUP_CY_DATA = "provider-framework-success-popup";
export const ERROR_POPUP_CY_DATA = "provider-framework-error-popup";

