import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Select from "react-select";
import ProviderStore, { DEFAULT_TEST_SUBSCRIPTION_CONFIG } from '../../stores/ProvidersStore';
import EcaCourseGridViewModel from '../../models/ExploreContentAnytimeModels/EcaCourseGridViewModel';
import './ExploreContentAnytimeGridComponent.css';
import EcaConstants from '../../constants/ExploreContentAnytimeConstants';
import PaginationComponent from '../providers/PagintationComponent';
import EcaModel from '../../models/ExploreContentAnytimeModels/EcaCourseModel';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import EcaCourseStore from '../../stores/EcaCourseStore'
import EcaBundleStore from '../../stores/EcaBundleStore'
import React from 'react';
import { Redirect } from 'react-router';
import EcaCourseItem from './EcaCourseItem'
import { RouterStore } from 'mobx-react-router';
import { ECA_BUNDLES_PATH } from '../../Settings';
import { PROVIDERS_GRID_CY_DATA, PROVIDERS_GRID_ITEM_CY_DATA, PROVIDERS_GRID_PAGINATION_CY_DATA, PROVIDERS_SEARCH_BUTTON_CY_DATA, PROVIDERS_SEARCH_INPUT_CY_DATA } from '../../cypressDataConstants/providersGridCyDataConstants';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import { Auth } from 'aws-amplify';
import TextInputComponent from '../common/inputs/TextInputComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import PopupComponent, { GenericSuccessPopupComponent } from '../common/popups/PopupComponent';


interface EcaCourseGridComponentProps {
    courses?: EcaCourseStore,
    bundles?: EcaBundleStore,
    globalLoader?: GlobalLoaderStore,
    routing?: RouterStore,
}

interface EcaCourseGridComponentState {
    filterString: string,
    appliedFilterString: string,
    courseInfo?: EcaCourseGridViewModel,
    redirectToFramework: boolean,
    checkedSelectAllCourses: boolean,
    showPopup: boolean,
    modalTitle: string,
    modalMessage: string,
    selectedFile: Blob,
    fileGcids:string[],
    fileInavlidGcids:string[],
    filterSelected:boolean,
    refinedSearchDescription:string,
    refinedSearchSubject:string[],
    refinedSearchProvider:any[],
    refinedSearchLanguages:string[],
    refinedSearchDateStart:string,
    refinedSearchDateEnd:string,
    refinedSearchClear:number, 


}

const SEARCH_TITLE: string = "Search";




@inject('courses', 'globalLoader', 'routing', 'bundles')
@observer
export default class EcaGridComponent extends Component<EcaCourseGridComponentProps, EcaCourseGridComponentState> {
    constructor(props: EcaCourseGridComponentProps) {
        super(props);
        this.state = {
            filterString: "",
            appliedFilterString: "",
            redirectToFramework: false,
            checkedSelectAllCourses: false,
            filterSelected:false,
            refinedSearchDescription:'',
            refinedSearchSubject:[],
            refinedSearchProvider:[],
            refinedSearchLanguages:[],
            refinedSearchDateStart:'',
            refinedSearchDateEnd:'',
            refinedSearchClear: 0
        }
    }





    getBundleName = () => {

        let bundleId = this.props.bundles?.SelectedBundle;
        let bundle = this.props.bundles.Bundles.find(f => f.Id === bundleId)

        return bundle.Name
    }
    async downloadInactiveCourses() {
        let allSelectedCourses = this.props.courses?.Courses.filter(x => !x.IsActive || x.IsForBundleRemoval)
        let gcids = allSelectedCourses.map(x => x.GlobalContentID)
        await this.getGcidsAsPresignedUrl(gcids, "inactive");
    }

    async downloadSelectedCourses(type) {

        let allSelectedCourses = this.props.courses?.Courses.filter(x => x.Checked)
        let gcids = allSelectedCourses.map(x => x.GlobalContentID)
        await this.getGcidsAsPresignedUrl(gcids, type);
    }

    async getGcidsAsPresignedUrl(gcids, type) {

        if (gcids.length == 0) {
            let message = "No courses were selected for download"
            let title = "Operation Failed"
            this.setState({ showPopup: true, modalMessage: message, modalTitle: title })
            return;
        }

        let email = Auth.Credentials.Auth.user.attributes.email
        let bundle = this.props.bundles.Bundles.find(f => f.Id === this.props?.bundles?.SelectedBundle)

        GraphQLHelper.GetPersignedUrl(this.props?.bundles?.SelectedBundle, bundle?.Name, gcids, type, true, email);
        let title = "Download Via Email"
        let message = "Download Link Sent To:" + email
        this.setState({ showPopup: true, modalMessage: message, modalTitle: title })
    }

    isValidUrl = (urlString: string) => {
        var inputElement = document.createElement('input');
        inputElement.type = 'url';
        inputElement.value = urlString;

        if (!inputElement.checkValidity()) {
            return false;
        } else {
            return true;
        }
    }

    selectAllCourses = () => {

        let isChecked = !this.state.checkedSelectAllCourses;
        this.props.courses?.CheckAllCheckboxs(isChecked);
        this.setState({ checkedSelectAllCourses: isChecked })

    }
    componentDidMount() {
        this.props.globalLoader!.startLoading();

        try {
            this.props.courses!.loadCourses(this.props.bundles?.SelectedBundle).finally(() => this.props.globalLoader!.finishLoading());
        }
        catch {
        }

    }

    handleOnChangePage = (newCurrentPage: number): Promise<any> => {
        return new Promise<any>((resolve) => {
            this.props.courses!.CurrentPage = newCurrentPage;
            resolve(null);
        })
    }

    sortCoursesByTitle = (isDescending: boolean) => {
        this.props.courses!.sortCourses((a, b) => { return isDescending ? -(a.Title!.localeCompare(b.Title!)) : (a.Title!.localeCompare(b.Title!)) });
    }

    sortCoursesById = (isDescending: boolean) => {
        this.props.courses!.sortCourses((a, b) => { return isDescending ? -(a.GlobalContentID!.localeCompare(b.GlobalContentID!)) : (a.GlobalContentID!.localeCompare(b.GlobalContentID!)) });
    }

    sortCoursesByLanguage = (isDescending: boolean) => {
        this.props.courses!.sortCourses((a, b) => { return isDescending ? -(a.Languages[0]!.localeCompare(b.Languages[0]!)) : (a.Languages[0]!.localeCompare(b.Languages[0]!)) });
    }

    sortCoursesByUpdateDate = (isDescending: boolean) => {
        this.props.courses!.sortCourses((a, b) => { return isDescending ? -(a.UpdateDate!.localeCompare(b.UpdateDate!)) : (a.UpdateDate!.localeCompare(b.UpdateDate!)) });
    }

    onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            this.setState({ filterString: "", appliedFilterString: "" });
        } else {
            this.setState({ filterString: e.target.value });
        }
    }

    refineDescription = (event: any) => {
        this.setState({refinedSearchDescription:event.target.value})
    }
    refineSubject = (event: any) => {
        this.setState({refinedSearchSubject:event.target.value})
    }
  
    refineLanguages = (event: any) => {        
        this.setState({refinedSearchLanguages:event.target.value})
    }
    refineDateStart = (date: any) =>{        
        this.setState({refinedSearchDateStart:date})
    }
    refineDateEnd = (date: any) =>{        
        this.setState({refinedSearchDateEnd:date})
    }



    onFilterApply = (event: any) => {
        event.preventDefault();
        this.setState({ appliedFilterString: this.state.filterString });
    }

    filterProviders = (isFilterByAppliedFilterString: boolean, ignoreChecked: boolean = false): EcaModel[] => {
        var filterString: string = (isFilterByAppliedFilterString ? this.state.appliedFilterString : this.state.filterString).toLowerCase();
        if (this.props.courses?.Courses != null) {
            let res = this.props.courses!.Courses.filter(i =>
                i.Title!.toLowerCase().match(filterString) ||
                i.GlobalContentID!.toLowerCase().match(filterString)
            );
            if (this.state.filterSelected && !ignoreChecked)
                res = res.filter(i => i.Checked)
            
            return res;

        }
        return [];
    }

    getSubjectList = (courses: any[])=>{ 
        let subjectList: any[]= [];

        for (let index = 0; index < courses.length; index++) {
            const element = courses[index].Subjects;
            element.forEach(item => {
                subjectList.push(item);
            });
        }
        let res = subjectList.map((subject, index) => ({ label: subject, value: index }));
        return res;
    }
    getProviderList = (courses: any[])=>{

         let result: any[] = [];
         for (let index = 0; index < courses.length; index++) {             
            const provider = courses[index].Provider;
            if (!provider)
                continue;

            if (!result.includes(provider.Name))
                result.push(provider.Name)
        }
        return result.map((subject, index) => ({ label: subject, value: index }));;
    }
    getLanguageList = (courses: any[]) => {
        let languageList: any[] = [];

        for (let index = 0; index < courses.length; index++) {
            const element = courses[index].Languages;
            element.forEach(item => {
                languageList.push(item);
            });
        }
        let res = languageList.map((subject, index) => ({ label: subject, value: index }));
        return res;
    }

    onRefinedSearchSubjectsChange = (value: any) => {
        this.setState({ refinedSearchSubject: value })
    }

    onRefinedSearchProviderChange = (value: any) => {
        this.setState({ refinedSearchProvider: value })
    }
    onRefinedSearchLanguageChange = (value: any) => {
        this.setState({ refinedSearchLanguages: value })
    }
    onRefinedSearchDateStartChange = (value: any) => {
        this.setState({ refinedSearchDateStart: value })
    }
    onRefinedSearchDateEndChange = (value: any) => {
        this.setState({ refinedSearchDateEnd: value })
    }


    refinedSearchFilterCourses = (courses: any[]) => {

        if (courses == undefined)
            return [];

        //filter description
        if (this.state.refinedSearchDescription.length != 0)
            courses = courses.filter(i => i.Description.toLowerCase().match(this.state.refinedSearchDescription.toLowerCase()))

        //filter subject
        let subjectList = this.state.refinedSearchSubject.map(x => x.label);
        if (this.state.refinedSearchSubject.length != 0) {
            courses = courses.filter(course => {
                if (subjectList.some(r => course.Subjects.includes(r)))
                    return course;
            })

        }
        //filter provider
        if (this.state.refinedSearchProvider.length != 0)
            courses = courses.filter(course => this.state.refinedSearchProvider.map(x => x.label).includes(course.Provider.Name))

        //filter language
        let languageList = this.state.refinedSearchLanguages.map(x => x.label);
        if (this.state.refinedSearchLanguages.length != 0) {
            courses = courses.filter(course => {
                if (languageList.some(r => course.Languages.includes(r)))
                    return course;
            })
        }
        //filter date
        if (this.state.refinedSearchDateStart.length > 0) {
            courses = courses.filter(course => this.state.refinedSearchDateStart < course.LastModifiedUTC)
        }
        if (this.state.refinedSearchDateEnd.length > 0) {
            courses = courses.filter(course => this.state.refinedSearchDateEnd > course.LastModifiedUTC)
        }
        return courses; 
    }


    insertSearchTips = () => {
        var key: number = 0;
        return <datalist id="providers" className="csod-search-tips">
            {this.props.courses?.Courses.map(i => <option key={key++} value={i.Title} />).slice(0, 7)}
        </datalist>
    }

    addCourseToSelectedCourses = (gcid, isChecked) => {
        this.props.courses?.ChangeCheckbox(gcid, isChecked)
    }

    ResetCurrentSort = (idString: string) => {
        var elements = document.getElementsByClassName("sortable");
        while (elements.length)
            elements[0].classList.remove("sortable");

        var sortTarget = document.getElementById(idString);
        sortTarget?.classList.add("sortable");
    }

    renderGridHeaderItem = (title: string, onSort?: (isDescending: boolean) => void) => {
        if (!onSort)
            return <th scope='col'>
                <div className="csod-grid-header-container">
                    <div className="csod-sorting-container">{title}</div> 
                </div>
            </th>;
        else
            return <th scope='col'>
                <div className="csod-grid-header-container">
                    {title}
                    <div className="csod-sorting-container">
                        <div id={title + 'up'} className="csod-sort-up smallpin" onClick={() => {
                            onSort(true);
                            this.ResetCurrentSort(title + 'up')
                        }}><div className="csod-icon-sort-up">▲</div></div>
                        <div id={title + 'down'} className="csod-sort-down smallpin" onClick={() => {
                            onSort(false)
                            this.ResetCurrentSort(title + 'down')
                        }}><div className="csod-icon-sort-down">▼</div></div>
                    </div>
                </div>
            </th>;
    }



    selectCourse = (gcid, isChecked) => {
        this.props.courses?.ChangeCheckbox(gcid, isChecked);
    }

    renderTableCheckbox = (id: string) => {
        
        return <th scope='col'>
            <div className="csod-grid-header-container">
                <div className="csod-sorting-container">{id}
                    <input type="checkbox" /></div>
            </div>
        </th>;

    }



    noCoursesFound = () => {
        return
    }



    getModal = () => {
        return <GenericSuccessPopupComponent Title={this.state.modalTitle} Message={this.state.modalMessage} OnClose={() => { this.setState({ showPopup: false }) }} OnDone={() => { this.setState({ showPopup: false }) }} />
    }



    onFileChange = (event) => {
        // Update the state
        this.setState({
            selectedFile: event.target.files[0],
        });        
    };

    onFileUpload = () => {
        try {

            this.getfileData();
        } catch (e) {
                this.setState({
                    showPopup:true,
                    modalTitle:"Invalid File Upload",
                    modalMessage:"Unable to use uploaded file"
                })
        }


    };
    getfileData = () => {
        let valid: string[] = [];        
        if (!this.state.selectedFile.name.includes('.csv'))
            throw new Error('Invalid File Format');

        this.state.selectedFile.text().then(data => {
            {
                let array = data.split("\n")
                array.forEach(fullItem => {
                    let item = fullItem.replaceAll("\r", "")
                    if (item.includes("_"))
                        valid.push(item);
                })
            }

            var CourseList = this.filterProviders(true, true);
            for (let i = 0; i < CourseList.length; i++) {
                const element = CourseList[i];                
                if (valid.includes(element.GlobalContentID))
                    element.Checked = true;
                else
                    element.Checked = false;

            }

        })
        this.setState({
            showPopup: true,
            modalTitle: "Upload succeeded, courses are checked",
            modalMessage: ''
        })

    }
 
    fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div>
                    <label>All Values from file</label>
                    <p>
                        {this.getfileData()}

                    </p>
                  
                </div>

            );
        }
    };

    filterSelectedCourses = () => {
        this.setState({ filterSelected: !this.state.filterSelected })
    }

    clearRefinedSearch = () =>{
        this.setState({
            refinedSearchDateEnd:'',
            refinedSearchDateStart:'',
            refinedSearchDescription:'',
            refinedSearchLanguages:[],
            refinedSearchProvider:[],
            refinedSearchSubject:[],
            refinedSearchClear: this.state.refinedSearchClear+1,
        })
    }
    render() {
      
        if (this.props.globalLoader!.isLoading) {
            return null;
        }
        if (!this.props.bundles?.SelectedBundle) {
            return <Redirect push={true} to={ECA_BUNDLES_PATH} />
        }
        var lastItemPerCurrentPageNumber = (this.props.courses!.CurrentPage - 1) * EcaConstants.ITEMS_PER_PAGE_COUNT + EcaConstants.ITEMS_PER_PAGE_COUNT;        
        var CourseList: any[] = this.filterProviders(true);        
        CourseList = this.refinedSearchFilterCourses(CourseList)
        CourseList = CourseList.filter(x => x.IsActive && !x.IsForBundleRemoval)
        

        
        var currentPage = this.props.courses!.CurrentPage;

        if (lastItemPerCurrentPageNumber > this.props.courses!.Courses!.length) {
            lastItemPerCurrentPageNumber = this.props.courses!.Courses!.length;
        }
        if (this.state.showPopup)
            return this.getModal()

        return <div className="csod-eca-container">
            <div className="csod-search-container">
                <div className="csod-providers-count-title">{this.getBundleName()}
                    <div className='csod-bundle-numbers'>
                        Courses In bundle ({this.props.courses!.Courses.length})
                    </div>
                </div>
              
                <div className="csod-search-add-new-container">
                    <form className="csod-search-form" onSubmit={this.onFilterApply}>
                        <div className="csod-search-field-tips-container">
                            <input type="text" list="providers" className="csod-input-search-tb less-rounded-corners" placeholder={SEARCH_TITLE} value={this.state.filterString} onChange={this.onFilterTextChange} cy-data={PROVIDERS_SEARCH_INPUT_CY_DATA} />
                            {this.insertSearchTips()}
                        </div>
                        <button type="submit" className="csod-button less-rounded-corners gray" onClick={this.onFilterApply} cy-data={PROVIDERS_SEARCH_BUTTON_CY_DATA}>{SEARCH_TITLE}</button>
                    </form>
                </div>
                <div className='advanced-search-wrapper'>
                    <form key={this.state.refinedSearchClear} >

                        <label> Refine your search </label>
                        <div className='advanced-search-buttons'>
                        <input type="text" list="providers" className="csod-input-search-tb less-rounded-corners" placeholder={'Description'} value={this.state.refinedSearchDescription} onChange={this.refineDescription} />
              


                           <div className='select-input'>
                           <Select placeholder="Subjects" myFontSize="20px" isMulti={true}
                                important={true} 
                                options={this.getSubjectList(this.props.courses!.Courses)}                               
                                onChange={this.onRefinedSearchSubjectsChange} 
                                className='select-class'
                                />

                           </div>

                           <div className='select-input'>
                           <Select placeholder="Provider" myFontSize="20px" isMulti={true}
                                important={true} 
                                options={this.getProviderList(this.props.courses!.Courses)}                               
                                onChange={this.onRefinedSearchProviderChange} 
                                className='select-class'
                                />

                           </div>


                           <div className='select-input'>
                           <Select placeholder="Language" myFontSize="20px" isMulti={true}
                                important={true} 
                                options={this.getLanguageList(this.props.courses!.Courses)}                               
                                onChange={this.onRefinedSearchLanguageChange} 
                                className='select-class'
                                />

                           </div>
                          

                    <div className="csod-inputs-column-container date">
                                <TextInputComponent title="Modified From"
                                    defaultValue={this.state.refinedSearchDateStart}
                                    type="date"
                                    additionalClass='tooltip'
                                    widthClass={InputWidthTypes.Small} important={false}
                                    onValueChanged={value => this.onRefinedSearchDateStartChange(value)} isAbleToCopy={false}/>
                     </div>
                     <div className="csod-inputs-column-container date">
                                <TextInputComponent title="Modified To"
                                additionalClass='tooltip'
                                defaultValue={this.state.refinedSearchDateEnd}
                                type="date"                                
                                widthClass={InputWidthTypes.Small} important={false}
                                onValueChanged={value => this.onRefinedSearchDateEndChange(value)} isAbleToCopy={false} value={''}/>
                     </div>
                    

                        </div>

                    </form>
                    <div className='clear-button-wrapper'>
                        <button onClick={() => this.clearRefinedSearch()} type="button" className='clear-button button csod-button less-rounded-corners '>  Clear </button>
                    </div>
                </div>
              <div className='upload-file-container'>
              <div className="csod-search-container">
                    <form className='file-input-form'>
                        <p className='file-input-label'>
                            <b>Upload csv file of selected courses</b><br />
                            The file should have only one column that contains GCID, max 5000 courses
                        </p>

                      
                        <div className='file-input-buttons'>
                       <div>
                       <input type="file" id='fileUploader' onChange={this.onFileChange} />
                            <label htmlFor='fileUploader' className='csod-button less-rounded-corners file-input' > Select File</label>
                            {this.state.selectedFile ? <label className='fileUploaderInfo'>{this.state.selectedFile.name}</label> : <label className='fileUploaderInfo'> Please Select a file</label> }

                       </div>
                          <div className='upload-button-wrapper'>
                          <button type="button" className="csod-button less-rounded-corners blue " onClick={this.onFileUpload}>
                                Upload</button>
                          </div>
                       
                        </div>

                        
                    </form>

                </div>
                    <form>
                        <button type="button" className="csod-button less-rounded-corners blue download-aicc" onClick={() => this.downloadSelectedCourses("zip")}
                        >{"Download Selected AICC Packages"}</button>
                        <button type="button" className="csod-button less-rounded-corners blue download-aicc" onClick={() => this.downloadSelectedCourses("csv")}
                        >{"Download Selected Meta Data CSV"}</button>
                         <button type="button" className="csod-button less-rounded-corners crimson download-aicc download-retired" onClick={() => this.downloadInactiveCourses()}
                        >{"Download Retired Courses"}</button>
                    </form>
              </div>

            </div>
            <p className='courses-selected-text'>
                Courses Selected ({this.props.courses!.Courses.filter(i => i.Checked).length})
            </p>
            <div id='filter-selected-wrapper'>
                <input id="filter-checked-courses" type="checkbox" defaultChecked={this.state.filterSelected} onChange={this.filterSelectedCourses} />
                <span>Filter Selected</span>
            </div>
            {CourseList.length === 0 ? <h2>No Courses Found For Bundle: {this.props.bundles?.SelectedBundle}</h2> :
                <div>


                    <table className="csod-providers-grid" cy-data={PROVIDERS_GRID_CY_DATA}>
                        <thead>
                            <tr>
                                <th scope='col' className='select-all-courses-th'> 
                                <label className={"select-all-courses"} >
                                    <input id="select-all-courses" type="checkbox" defaultChecked={false} onChange={this.selectAllCourses} />
                                    <span>Select All</span>
                                </label>
                                </th>
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_TITLE_TITLE, this.sortCoursesByTitle)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_ID_TITLE, this.sortCoursesById)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DESCRIPTION_TITLE)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_PROVIDER_TITLE)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_LANGUAGE_TITLE, this.sortCoursesByLanguage)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_SUBJECT_TITLE)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DURATION_TITLE)}
                                {this.renderGridHeaderItem("Last Modified", this.sortCoursesByUpdateDate)}
                                {/* {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DOWNLOAD_AICC_TITLE)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DOWNLOAD_METADATA_TITLE)} */}
                            </tr>
                        </thead>
                        <tbody>
                        
                            {
                                CourseList.slice((this.props.courses!.CurrentPage - 1) * EcaConstants.ITEMS_PER_PAGE_COUNT, lastItemPerCurrentPageNumber).map((course) =>
                                    <EcaCourseItem Course={course} key={course.GlobalContentID} cy_data={PROVIDERS_GRID_ITEM_CY_DATA} Checked={course.Checked} ChangeCheckbox={this.selectCourse} />
                                )}
                        </tbody>
                    </table>
                    {this.props.courses!.Courses.length <= EcaConstants.ITEMS_PER_PAGE_COUNT ? null : <PaginationComponent currentPageIndex={currentPage} listPages={this.props.courses!.TotalPages} handleChangePage={this.handleOnChangePage} cy_data={PROVIDERS_GRID_PAGINATION_CY_DATA} />}
                </div>}
        </div>
    }
}