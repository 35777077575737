export default class ProviderDataConstants {
    public static readonly ITEMS_PER_PAGE_COUNT = 100;
    public static readonly PROVIDER_DATA_GRID_STATE_TYPE: string = "State Type";
    public static readonly PROVIDER_DATA_GRID_PROVIDER_ID: string = "Provider ID";
    public static readonly PROVIDER_DATA_GRID_TRAINING_TYPE: string = "Training Type";
    public static readonly PROVIDER_DATA_GRID_SYNC_DATE: string = "Sync Date";
    public static readonly PROVIDER_DATA_GRID_URL: string = "URL";
    public static readonly PROVIDER_DATA_GRID_LAST_SUCCESSFUL_SYNC_DATE: string = "Successful Sync";
    public static readonly PROVIDER_DATA_GRID_TRAININGS_COUNT: string = "Trainings Count";
    public static readonly PROVIDER_DATA_GRID_CORRELATION_ID: string = "Correlation ID";
    public static readonly PROVIDER_DATA_GRID_ERROR_TYPE: string = "Error Type";
    public static readonly PROVIDER_DATA_GRID_ERROR_MESSAGE: string = "Error Message";
    
}