import React, { ChangeEvent, Component } from 'react';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui/dist/style.css';
import "./LoginComponent.css"
class ForgotPassword extends Component<any, any> {
    constructor(props: any) {
        super(props);
        const { changeShowForgotPassword } = this.props;
        this.state = {
            username: '',
            verificationCode: '',
            newPassword: '',
            codeSent: false,
            error: null
        };
    }

    handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget ;
        this.setState({ [name]: value });
    };

    sendForgotPasswordRequest = async () => {
        const { username } = this.state;
        try {
            await Auth.forgotPassword(username);
            this.setState({ codeSent: true , error:null});             
        } catch (error: any) {
            this.setState({ error: error.message });
        }
    };

    resetPassword = async () => {
        const { username, verificationCode, newPassword } = this.state;
        try {
            await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
            // Password reset successful
            this.props.changeShowForgotPassword(false);
        } catch (error:any) {
            this.setState({ error: error.message });
        }
    };

    render() {
        const { username, verificationCode, newPassword, codeSent, error } = this.state;

        return (
            <div className="csod-auth-components-container">
                <div className="csod-outer-auth-container">
                    <form className="csod-auth-container">
                        <div className="csod-app-logo non-selectable" />
                        <div className="csod-header-text-1 non-selectable">{"Forgot Password"}</div>

                        <div className="csod-auth-inputs-container" >
                            {!codeSent ? (
                                <div>
                                    <div className="csod-text-box-container hard-rounded-corners">
                                        <input type="text" name="username" placeholder="Email" className="csod-text-box" value={username} onChange={this.handleInputChange} />
                                    </div>
                                    <br />
                                    <button type="button" className="csod-button send-verification crimson non-selectable rounded-corners" onClick={this.sendForgotPasswordRequest}>Send Verification Code</button>
                                </div>
                            ) : (
                                <div >
                                    <div className="csod-text-box-container hard-rounded-corners">
                                        <input type="text" name="verificationCode" placeholder="Verification Code" className="csod-text-box" value={verificationCode} onChange={this.handleInputChange} />
                                    </div>
                                    <div className="csod-text-box-container hard-rounded-corners">

                                        <input type="password" name="newPassword" placeholder="New Password" className="csod-text-box" value={newPassword} onChange={this.handleInputChange} />
                                    </div>
                                    <br />
                                    <button type="button" className="csod-button send-verification crimson non-selectable rounded-corners" onClick={this.resetPassword}>Reset Password</button>
                                </div>
                            )}
                            {error && <div className="csod-auth-error-message">{error}</div>}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
