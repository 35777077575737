import React, { Component } from 'react';
import { v4 } from 'uuid';
import CodeHelpers from '../../../helpers/common/CodeHelpers';
import { FrameworkModes } from '../../../models/ProviderFramework/FrameworkMode';
import ValidationApiErrorModel from '../../../models/ProviderDetails/ValidationApiErrorModel';
import TreeGridComponent from '../inputs/TreeGridComponent';
import "./PopupComponent.css";
import PopupConstants from '../../../constants/popupConstants';

interface PopupButtonProps {
    ButtonText: string,
    AdditionalClassForButton: string,
    OnClick: () => void
}

interface PopupComponentProps {
    Title?:string,
    OnClose: () => void,
    ErrorMessage?: string,
    AdditionalClass?: string,
    ButtonsProps: PopupButtonProps[],
    cy_data?: string
}

export default class PopupComponent extends Component<PopupComponentProps> {

    onButtonClick = (event: () => void) => {
        document.body.style.overflow = "auto";
        event();
    }

    renderPopupContent = () => {
    }

    renderButtons = () => {
        return <div className="csod-horizontal-align-container margin-right-neg-12">
            {this.props.ButtonsProps.map((i) => {
                return <button key={i.ButtonText} className = {`csod-button-popup-done ${i.AdditionalClassForButton} less-rounded-corners margin-right-12 white-text`} onClick={() => {this.onButtonClick(i.OnClick)}}>{i.ButtonText}</button>
            })}
        </div>
    }

    render() {
        document.body.style.overflow = "hidden";
        return <div className = "csod-popup-background" cy-data={!CodeHelpers.IsStringNullOrEmpty(this.props.cy_data)? this.props.cy_data: ""}>
            <div className="csod-vertical-align-container" >
                <div className="csod-horizontal-align-container margin-left-right-64">
                    <div className = "csod-popup-container" >
                        <button className = "csod-button-close " onClick={()=>{this.onButtonClick(this.props.OnClose)}}/> 
                        {!(CodeHelpers.IsStringNullOrEmpty(this.props.Title)) ? <div className = "csod-input-title-text non-selectable">{this.props.Title}</div> : null}
                        <div className = {`csod-popup-inner-container ${this.props.AdditionalClass != null ? this.props.AdditionalClass : ""}`}>
                            {this.props.children}
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

interface SuccessPopupComponentProps {
    FrameworkMode?: FrameworkModes,
    ProviderName?: string,
    OnClose: () => void,
    OnDone: () => void,
    cy_data?: string
}

export class SuccessPopupComponent extends Component<SuccessPopupComponentProps> {
    render () {
        return <PopupComponent OnClose={this.props.OnClose} ButtonsProps={ [
            {ButtonText: "OK", OnClick:this.props.OnDone, AdditionalClassForButton: "blue"}
        ] } cy_data={this.props.cy_data}>
            <div className = "csod-popup-inner-container">
                <div className="csod-icon-success"/>
                <div className="csod-popup-title-text"><div className = "csod-provider-popup-nametitle blue-text">{` ${CodeHelpers.IsStringNullOrEmpty(this.props.ProviderName)? "N/A" : this.props.ProviderName! } `}</div>has been {this.props.FrameworkMode === FrameworkModes.Create? "created" : this.props.FrameworkMode === FrameworkModes.Edit? "updated" : "" } successfully.</div>
            </div>
        </PopupComponent> 
    }
}

interface ValidationSuccessPopupComponentProps {
    CoursesCount: number,
    OnClose: () => void,
    OnDone: () => void,
    OnS3Open: () => void
}

export class ValidationSuccessPopupComponent extends Component<ValidationSuccessPopupComponentProps> {
    render () {
        return <PopupComponent OnClose={this.props.OnClose} ButtonsProps={ [
            {ButtonText: "Done", OnClick:this.props.OnDone, AdditionalClassForButton: "blue"},
            {ButtonText: "Open JSON", OnClick: this.props.OnS3Open, AdditionalClassForButton: "blue"}
        ] } >
            <div className = "csod-popup-inner-container">
                <div className="csod-icon-success"/>
                <div className="csod-popup-title-text">{"The endpoint validated successfully!"}</div>
                <div className="csod-popup-desc-text gray-text">{`${this.props.CoursesCount} courses have arrived.`}</div>
            </div>
        </PopupComponent> 
    }
}

interface GenericSucessPopupComponentProps {
    Message?: string,
    OnClose: () => void,
    OnDone: () => void,
    Title?:string,
    showIcon?:boolean
}

export class GenericSuccessPopupComponent extends Component<GenericSucessPopupComponentProps> {
    render () {
        return <PopupComponent OnClose={this.props.OnClose} ButtonsProps={ [
            {ButtonText: "Done", OnClick:this.props.OnDone, AdditionalClassForButton: "blue"}
        ] } >
            <div className = "csod-popup-inner-container">
            <div className="csod-popup-title-text">{this.props.Title}</div>
            {this.props.showIcon? <div className="csod-icon-success"/> : null }
                
                <div className="csod-popup-title-text">{this.props.Message}</div>
            </div>
        </PopupComponent> 
    }
}


export interface PhaseCondition {
    phaseName: string,
    isPhasePassedSuccessfully: boolean
}

interface ErrorPopupComponentProps {
    ProviderName?: string,
    TitleElements?:(string|JSX.Element)[],
    ErrorMessages?: string[],
    PhasesConditions?: PhaseCondition[],
    cy_data?: string,
    OnClose: () => void
}



export class ErrorPopupComponent extends Component<ErrorPopupComponentProps> {
    render() {
        return <PopupComponent OnClose={this.props.OnClose} ButtonsProps={[
            {ButtonText: "Done", AdditionalClassForButton: "blue", OnClick: this.props.OnClose}
        ]} cy_data={this.props.cy_data}>
            <div className = "csod-popup-inner-container">
                <div className="csod-icon-warning"/>
                <p className="csod-popup-title-text">{this.props.TitleElements}</p>
                <div className="csod-popup-phases-outer-containder">
                    {this.props.PhasesConditions? this.props.PhasesConditions.map(pc => 
                        <div key={v4()} className="csod-popup-phase-condition-container">
                            <div className="non-selectable">{pc.phaseName}</div>
                            <div className={`non-selectable ${pc.isPhasePassedSuccessfully? "green-text" : "error-text-color"}`}>{pc.isPhasePassedSuccessfully? PopupConstants.CHCKMARK_FOR_POPUP: PopupConstants.CRSSMARK_FOR_POPUP}</div>
                        </div>
                    ) : null}
                </div>
                {this.props.ErrorMessages != null ? this.props.ErrorMessages.map(e => <div key={v4()} className = "csod-popup-desc-text gray-text padding-top-0">{e}</div>) : null}
            </div>
        </PopupComponent> 
    }
}

interface ErrorPopupWithGridComponentProps {
    ErrorDescriptionMessage: string
    ErrorMessages?: ValidationApiErrorModel[],
    cy_data?: string,
    OnClose: () => void
}

interface ErrorGridItem {
    "Course Type": string,
    Position: string,
    Message: string,
    childErrors: ErrorGridItem[]
}

export class ErrorPopupWithGridComponent extends Component<ErrorPopupWithGridComponentProps> {

    getValidationApiString = (errors: ValidationApiErrorModel[], childErrorsDeep = 0): string[] => {
        var errorString: string[] = [];
        errors.forEach(i => {
            errorString = errorString.concat(i.childErrors? this.getValidationApiString(i.childErrors, childErrorsDeep+1) : `${"+".repeat(childErrorsDeep)} ${Object.keys(i)} : "${Object.values(i).toString()}";`);
        })
        return errorString;
    }

    private onCopyClick = () => {
        navigator.clipboard.writeText(this.getValidationApiString(this.props.ErrorMessages!).join("\n"));
    }

    private parseValidationErrorMessage = (targetError :ValidationApiErrorModel, childErrors: ValidationApiErrorModel[] ): ErrorGridItem => {
        var parsedValue: string = Object.values(targetError)[0];
        var parsedKey: string[] = Object.keys(targetError)[0].split(".");
        return {"Course Type": parsedKey[1], Position: parsedKey.length > 2? parsedKey[2] : "N\\A", Message: parsedValue, childErrors: childErrors? this.parseValidationErrorMessages(childErrors) : []};
    }

    private parseValidationErrorMessages = (errors: ValidationApiErrorModel[]): ErrorGridItem[] => {
        var errorGridItem: ErrorGridItem[] = [];
        for(var i = 0 ; i < errors.length; i++) {
            if(!errors[i].childErrors) {
                errorGridItem.push(this.parseValidationErrorMessage(errors[i], i < errors.length-1 ? errors[i+1].childErrors: []));
            }
        } 
        return errorGridItem;
    }

    render() {
        return <PopupComponent OnClose={this.props.OnClose} AdditionalClass={"alternative-padding"} ButtonsProps={[
            {ButtonText: "Copy", AdditionalClassForButton: "gray", OnClick: this.onCopyClick},
            {ButtonText: "Done", AdditionalClassForButton: "blue", OnClick: this.props.OnClose}
        ]} cy-data={this.props.cy_data}>
            <div className = "csod-popup-inner-container">
                <div className="csod-icon-warning"/>
                <div className="csod-popup-title-text">{this.props.ErrorDescriptionMessage}</div>
                <TreeGridComponent items = {this.parseValidationErrorMessages(this.props.ErrorMessages!)}/>
            </div>
        </PopupComponent> 
    }
}

interface ErrorPopupWithTextAreaComponentProps {
    ErrorDescriptionMessage: string
    ErrorMessages?: string[],
    IsSync: boolean,
    OnClose: () => void,
    OnS3Open: () => void
}

export class ErrorPopupWithTextAreaComponent extends Component<ErrorPopupWithTextAreaComponentProps> {
    render() {
        return <PopupComponent OnClose={this.props.OnClose} AdditionalClass={"alternative-padding"} ButtonsProps={[
            {ButtonText: "Done", AdditionalClassForButton: "blue", OnClick: this.props.OnClose},
            {ButtonText: "Open JSON", AdditionalClassForButton: this.props.IsSync ? "blue invisible" : "blue", OnClick: this.props.OnS3Open}
        ]}>
            <div className = "csod-popup-inner-container">
                <div className="csod-icon-warning"/>
                <div className="csod-popup-title-text">{this.props.ErrorDescriptionMessage}</div>
                <div className="csod-popup-errors-text-container border-for-input">
                    {this.props.ErrorMessages != null ? this.props.ErrorMessages.filter(function( obj ) {return !obj.includes("s3.amazonaws.com")}).join("\n") : null}
                </div>
            </div>
        </PopupComponent> 
    }
}

interface WarningPopupComponentProps {
    OnClose: () => void,
    OnDone: () => void,
    cy_data?: string
}

export class WarningPopupComponent extends Component<WarningPopupComponentProps> {
    render() {
        return <PopupComponent OnClose = {this.props.OnClose} ButtonsProps = {[
            {ButtonText: "No", AdditionalClassForButton: "gray", OnClick: this.props.OnClose},
            {ButtonText: "Yes", AdditionalClassForButton: "crimson", OnClick: this.props.OnDone}
        ]} cy_data={this.props.cy_data}>
            <div className = "csod-popup-inner-container">
                <div className="csod-icon-warning"/>
                <div className="csod-popup-title-text">Are you sure that you want to cancel?</div>
            </div>
        </PopupComponent> 
    }
}

export class FullSyncWarningPopupComponent extends Component<WarningPopupComponentProps> {
    render() {
        return <PopupComponent OnClose = {this.props.OnClose} ButtonsProps = {[
            {ButtonText: "No", AdditionalClassForButton: "gray", OnClick: this.props.OnClose},
            {ButtonText: "Yes", AdditionalClassForButton: "crimson", OnClick: this.props.OnDone}
        ]} cy_data={this.props.cy_data}>
            <div className = "csod-popup-inner-container">
                <div className="csod-icon-warning"/>
                <div className="csod-popup-title-text">Full syncing is not advised unless necessary, as this causes stress on the entire system, especially with providers containing large amounts of data going to multiple portals, are you sure you want to proceed?</div>
            </div>
        </PopupComponent> 
    }
}