import React, { Component } from 'react';
import "./DropdownComponent.css";
import ValidationApiErrorModel from '../../../models/ProviderDetails/ValidationApiErrorModel';
import { v4 } from 'uuid';

interface TreeGridItemProps {
    item: ValidationApiErrorModel
}

interface TreeGridItemState {
    isExpanded: boolean
}

export default class TreeGridItemComponent extends Component<TreeGridItemProps, TreeGridItemState> {
    
    constructor(props: TreeGridItemProps) {
        super(props);
        this.state = { isExpanded: false };
    }

    private onExpandChange = (e: any) => {
        this.setState({isExpanded: !this.state.isExpanded});
    }

    render() {
        var canBeExpanded = this.props.item.childErrors.length > 0;
        var values = Object.values(this.props.item);
        values.pop(); //pop childs
        var first = true;
        return <div className="csod-errors-grid-column-container-expandable">
            <div className="csod-errors-grid-row-container">
                {values.map(i => {
                    var item = <div className = "csod-errors-grid-item" key={v4()}>
                        {first && canBeExpanded? <button className="csod-expand-tree-grid-checkmark"  onClick={this.onExpandChange}>{this.state.isExpanded? "-" : "+"}</button> : null}
                        {i.toString()}
                    </div>
                    first = false;
                    return item;
                })}
            </div>
            {canBeExpanded? <div className={`csod-errors-grid-enhanced-content ${this.state.isExpanded? "active" : ""}`}>
                {this.props.item.childErrors.map(i => <TreeGridItemComponent key={v4()} item={i}/>)}
            </div> : null} 
        </div>
    }
}