import { observable, action } from 'mobx';

export default class GlobalLoaderStore {
    @observable isLoading: boolean = false;
    processesCount: number = 0;

    @action
    startLoading = () => {
        if(++this.processesCount === 1){
            this.isLoading = true;
        }
    }

    @action
    finishLoading = () => {
        if(--this.processesCount <= 0){
            this.isLoading = false;
        }
    }
}