import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RouterStore } from 'mobx-react-router';
import { Provider } from 'mobx-react';
import LoginComponent from './components/login/LoginComponent';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import ProviderStore from './stores/ProvidersStore';
import PackageStore from './stores/PackagesStore';
import EcaBundleStore from './stores/EcaBundleStore';
import EcaCourseStore from './stores/EcaCourseStore';
import EcaSubscriptionStore from './stores/EcaSubscriptionStore';
import OrganizationStore from './stores/OrganizationStore'
import GlobalLoaderStore from './stores/GlobalLoaderStore';
import { Authenticator, SignIn } from 'aws-amplify-react';
import ProviderDataStore from './stores/ProviderDataStore';
const packagesStore = new PackageStore();
const routingStore = new RouterStore();
const providerStore = new ProviderStore();
const ecaBundleStore = new EcaBundleStore();
const ecaCourseStore = new EcaCourseStore();
const ecaSubscriptionStore = new EcaSubscriptionStore();
const globalLoaderStore = new GlobalLoaderStore();
const providerDataStore = new ProviderDataStore();
const organizationStore = new OrganizationStore();

const stores = {
  routing: routingStore,
  providers: providerStore,
  providerData: providerDataStore,
  packages: packagesStore,
  globalLoader: globalLoaderStore,
  organizations: organizationStore,
  bundles: ecaBundleStore,
  courses: ecaCourseStore,
  subscriptions:ecaSubscriptionStore
  

};

Amplify.configure(awsconfig);

const amplifyTheme = {
  navBar: { 'display': 'none' },
  toast: { 'display': 'none' }
}

const handleAuthErrorMessage = (message: string) => {
  document.getElementsByClassName("csod-auth-error-message")[0]!.innerHTML = message;
  return message;
}

ReactDOM.render(<Provider {...stores}>

  <BrowserRouter>
    <Authenticator hide={[SignIn]} amplifyConfig={awsconfig} theme={amplifyTheme} errorMessage={handleAuthErrorMessage}>
      <LoginComponent />
      <App />
    </Authenticator>
  </BrowserRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
