import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProviderStore, { DEFAULT_TEST_SUBSCRIPTION_CONFIG } from '../../stores/ProvidersStore';
import ProviderGridViewModel from '../../models/ProvidersGrid/ProviderGridViewModel';
import ProviderItem from './ProviderItem';
import './ProvidersGridComponent.css';
import ProviderConstants from '../../constants/providersconstants';
import PaginationComponent from './PagintationComponent';
import ProviderModel from '../../models/ProviderDetails/ProviderModel';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import React from 'react';
import { RouterStore } from 'mobx-react-router';
import { PROVIDER_FRAMEWORK_PAGE_PATH } from '../../Settings';
import { Redirect } from 'react-router';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import ProviderDetailsExpansion from '../../models/ProviderDetails/ProviderDetailsExpansion';
import BasicAuthConfig from '../../models/ProviderDetails/BasicAuthConfig';
import SSOConfigModel from '../../models/SSOConfig/SSOConfigModel';
import Oauth2Config from '../../models/ProviderDetails/Oauth2Config';
import { PROVIDERS_GRID_CY_DATA, PROVIDERS_GRID_ITEM_CY_DATA, PROVIDERS_GRID_PAGINATION_CY_DATA, PROVIDERS_SEARCH_BUTTON_CY_DATA, PROVIDERS_SEARCH_INPUT_CY_DATA } from '../../cypressDataConstants/providersGridCyDataConstants';
import ApiKeyConfig from '../../models/ProviderDetails/ApiKeyConfig';

interface ProvidersGridComponentProps {
    providers?: ProviderStore,
    globalLoader?: GlobalLoaderStore,
    routing?: RouterStore
}

interface ProvidersGridComponentState {
    filterString: string,
    appliedFilterString: string,
    providerInfo?: ProviderGridViewModel,
    redirectToFramework: boolean,
}

const SEARCH_TITLE: string = "Search";
const ADD_NEW_PROVIDER: string = "New provider";

@inject('providers', 'globalLoader', 'routing')
@observer
export default class ProvidersGridComponent extends Component<ProvidersGridComponentProps, ProvidersGridComponentState> {
    constructor(props: ProvidersGridComponentProps) {
        super(props);
        this.state = {
            filterString: "",
            appliedFilterString: "",
            redirectToFramework: false,
        }
    }
    
    componentDidMount() {
        if(this.props.providers?.Providers.length == 0 ){
            this.props.globalLoader!.startLoading();
            this.props.providers!.loadProviders().finally(()=>this.props.globalLoader!.finishLoading());
        }
    }

    handleOnChangePage = (newCurrentPage: number) : Promise<any> => {
        return new Promise<any>((resolve) => {
            this.props.providers!.CurrentPage = newCurrentPage;
            resolve(null);
        })
    }

    sortProvidersByName = (isDescending: boolean) => {
        this.props.providers!.sortProviders((a,b) => {return isDescending? -(a.Name!.localeCompare(b.Name!)): (a.Name!.localeCompare(b.Name!))});
    }

    sortProvidersBySyncStatus = (isDescending: boolean) => {
        this.props.providers!.sortProviders((a,b) => {return isDescending? -(a.SyncStatus!.localeCompare(b.SyncStatus!)): (a.SyncStatus!.localeCompare(b.SyncStatus!))});
    }

    sortProvidersById = (isDescending: boolean) => {
        this.props.providers!.sortProviders((a,b) => {return isDescending? -(a.Id!.localeCompare(b.Id!)): (a.Id!.localeCompare(b.Id!))});
    }

    sortProvidersByAbbrev = (isDescending: boolean) => {
        this.props.providers!.sortProviders((a,b) => {return isDescending? -(a.Abbreviation!.localeCompare(b.Abbreviation!)): (a.Abbreviation!.localeCompare(b.Abbreviation!))});
    }

  
    sortProvidersByOffering = (isDescending: boolean) => {
        this.props.providers!.sortProviders((a,b) => {return isDescending? (a.ProviderOfferingType! > b.ProviderOfferingType!? 1 : -1) : (a.ProviderOfferingType! < b.ProviderOfferingType!? 1 : -1)});
    }

    sortProvidersByTraining = (isDescending: boolean) => {
        this.props.providers!.sortProviders((a,b) => {return isDescending? (a.Type! > b.Type!? 1 : -1) : (a.Type! < b.Type!? 1 : -1)});
    }

    onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === "") {
            this.setState({filterString: "", appliedFilterString: ""});
        } else {
            this.setState({filterString: e.target.value});
        }
    }

    onFilterApply = (event: any) => {
        event.preventDefault();
        this.setState({ appliedFilterString: this.state.filterString});
    }

    filterProviders = (isFilterByAppliedFilterString: boolean): ProviderModel[] => {
        var filterString: string = (isFilterByAppliedFilterString? this.state.appliedFilterString : this.state.filterString).toLowerCase();
        if(this.props.providers?.Providers != null) {
            return this.props.providers!.Providers.filter(i => 
                i.Name!.toLowerCase().match(filterString) || 
                i.Abbreviation!.toLowerCase().match(filterString)
            );
        }
        return [];
    }

    insertSearchTips = () => {
        var key: number = 0;
        return <datalist id="providers" className = "csod-search-tips"> 
            {this.props.providers?.Providers.map(i => <option key={key++} value={i.Name}/>).slice(0,7)}
        </datalist>
    }

    onCreateNewProviderClick = () => {
        this.props.providers!.CurrentProvider = new ProviderModel();
        this.props.providers!.CurrentProviderDetailsExpansion = new ProviderDetailsExpansion();
        this.props.providers!.CurrentBasicAuthConfig = new BasicAuthConfig();
        this.props.providers!.CurrentApiKeyConfig = new ApiKeyConfig();
        this.props.providers!.CurrentOAuth2Config = new Oauth2Config();
        this.props.providers!.CurrentTestSubscriptionConfig = DEFAULT_TEST_SUBSCRIPTION_CONFIG;
        this.props.providers!.CurrentSSOConfigModel = new SSOConfigModel();
        this.props.providers!.FrameworkMode = FrameworkModes.Create;
        this.redirectToProviderFramework();
    }

    redirectToProviderFramework = () => {
        this.setState({redirectToFramework: true});
    }

    ResetCurrentSort = (idString:string) => {
        var elements = document.getElementsByClassName("sortable");
        while (elements.length)
            elements[0].classList.remove("sortable");
        
        var sortTarget = document.getElementById(idString);
        sortTarget?.classList.add("sortable");
    }

    renderGridHeaderItem = (title: string, onSort?: (isDescending: boolean)=>void) => {
        if (!onSort )
            return   <th scope='col'>
                <div className="csod-grid-header-container">
                    <div className = "csod-sorting-container">{title}</div>
                </div>
            </th>;
        else 
            return <th scope='col'>
                <div className="csod-grid-header-container">
                    {title}
                    <div className = "csod-sorting-container">
                        <div id={title + 'up'} className="csod-sort-up smallpin"  onClick= {() => { onSort(true); 
                            this.ResetCurrentSort(title + 'up')} }><div className="csod-icon-sort-up">▲</div></div>
                        <div id={title + 'down'} className="csod-sort-down smallpin" onClick= {() =>{ onSort(false)
                            this.ResetCurrentSort(title + 'down')} }><div className="csod-icon-sort-down">▼</div></div>
                    </div>
                </div>
            </th>;
    }

    render() {
        if (this.props.globalLoader!.isLoading) {
            return null;
        }
        if (this.state.redirectToFramework) {
            return <Redirect push={true} to = {PROVIDER_FRAMEWORK_PAGE_PATH}/>
        }
        var lastItemPerCurrentPageNumber = (this.props.providers!.CurrentPage - 1) * ProviderConstants.ITEMS_PER_PAGE_COUNT + ProviderConstants.ITEMS_PER_PAGE_COUNT;
        var providersList: ProviderModel[] = this.filterProviders(true);
        var currentPage = this.props.providers!.CurrentPage;
        if(lastItemPerCurrentPageNumber > this.props.providers!.Providers!.length){
            lastItemPerCurrentPageNumber = this.props.providers!.Providers!.length;
        } 
        return <div className = "csod-providers-container">
                <div className = "csod-search-container">
                    <div className = "csod-providers-count-title">Content Providers ({providersList.length})</div>
                    <div className = "csod-search-add-new-container">
                        <form className = "csod-search-form" onSubmit={this.onFilterApply}>
                            <div className = "csod-search-field-tips-container">
                                <input type = "text" list="providers" className = "csod-input-search-tb less-rounded-corners" placeholder = {SEARCH_TITLE} value={this.state.filterString} onChange={this.onFilterTextChange} cy-data={PROVIDERS_SEARCH_INPUT_CY_DATA}/>
                                {this.insertSearchTips()}
                            </div>
                            <button type="submit"  className = "csod-button less-rounded-corners gray" onClick={this.onFilterApply} cy-data={PROVIDERS_SEARCH_BUTTON_CY_DATA}>{SEARCH_TITLE}</button>
                        </form>
                        <button className = "csod-button less-rounded-corners blue" onClick={this.onCreateNewProviderClick}>{ADD_NEW_PROVIDER}</button>
                    </div>
                </div>
                {providersList.length === 0 ? null :
                <div>
                    <table className = "csod-providers-grid" cy-data={PROVIDERS_GRID_CY_DATA}>
                        <thead>
                            <tr>
                                {this.renderGridHeaderItem(ProviderConstants.PROVIDERS_GRID_SYNC_STATUS_TITLE, this.sortProvidersBySyncStatus)}
                                {this.renderGridHeaderItem(ProviderConstants.PROVIDERS_GRID_NAME_TITLE, this.sortProvidersByName)}
                                {this.renderGridHeaderItem(ProviderConstants.PROVIDERS_GRID_ID_TITLE, this.sortProvidersById)}
                                {this.renderGridHeaderItem(ProviderConstants.PROVIDERS_GRID_ABBREV_TITLE, this.sortProvidersByAbbrev)}
                                {this.renderGridHeaderItem(ProviderConstants.PROVIDERS_GRID_OFFERING_TITLE, this.sortProvidersByOffering)}
                                {this.renderGridHeaderItem(ProviderConstants.PROVIDERS_GRID_TRAINING_TITLE, this.sortProvidersByTraining)}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                providersList.slice((this.props.providers!.CurrentPage - 1) * ProviderConstants.ITEMS_PER_PAGE_COUNT, lastItemPerCurrentPageNumber).map((provider) =>
                                <ProviderItem Provider = {ProviderGridViewModel.Map(provider)}   key={provider.Id + provider.Type}  RedirectToUpdate={this.redirectToProviderFramework} cy_data={PROVIDERS_GRID_ITEM_CY_DATA}/>
                            )}
                        </tbody>
                    </table>
                    {this.props.providers!.Providers.length <= ProviderConstants.ITEMS_PER_PAGE_COUNT? null : <PaginationComponent currentPageIndex={currentPage} listPages={this.props.providers!.TotalPages} handleChangePage={this.handleOnChangePage} cy_data={PROVIDERS_GRID_PAGINATION_CY_DATA}/>}
                </div>} 
            </div>
    }
}