export default class SSOConfigConstants {
    public static GENERAL_PROPERTIES_HEADER: string = "General Properties"; 
    public static ISSUER_REGEX: RegExp = /https:\/\/#[\w]*#.csod.com/;
    public static AUTHENTICATION_PARAMETERS_HEADER : string = "Authentication Parameters Mapping (At least one field is required)";
    public static SP_INITIATED_HEADER : string = "SP Initiated";
    public static ADVANCED_PROPERTIES_HEADER : string = "Advanced Properties";
    public static QUERYSTRING_PARAMETERS_HEADER : string = "Querystring Parameters";
    public static ADDITIONAL_SETTINGS_HEADER : string = "Additional Settings";
    public static SSOIDOUID_DESCRIPTION: string = `1. Login to any portal using 'CA' user and navigate to 
        https://<client-portal>.csod.com/ui/tools-single-sign-on/home
    2. Click "Add SSO" -> Outbound SAML -> Click "Add"
    3. "Outbound SSO Provider" drop-down-> search for the new provider
        a. Provider exists in the list: select it
        b. Provider does not exist: select drop-down value "Other". 
            Put provider name in "New SSO Provider" textbox, 
            “ACS URL”= http://example.com, 
            “Timestamp Format”= any value.
            Click “Save”.
    4. OU_ID = value in “SSO ID” textbox.`
}