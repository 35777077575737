import React, { Component } from 'react';
import ValidationApiErrorModel from '../../../models/ProviderDetails/ValidationApiErrorModel';
import { v4 } from 'uuid';
import TreeGridItemComponent from './TreeGridItemComponent';
import "./TreeGridComponent.css";

interface TreeGridProps {
    items: ValidationApiErrorModel[],
}

export default class TreeGridComponent extends Component<TreeGridProps> {
    render() {
        return <div className="csod-errors-grid-column-container">
            {this.props.items.map(i => <TreeGridItemComponent key={v4()} item = {i} />)}
        </div>
    }
}