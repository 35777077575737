import { v4 as uuid } from 'uuid';
import { AuthenticationType } from '../../models/ProviderDetails/AuthenticationType';

export default class OrganizationModel {
    constructor(
        public Id?: string,
        public Name?: string,
        public Description?: string,
        public SubscriberUri?: string,
        public LearningPlatform?: string,
        public IsActive?: boolean,
        public IsExternal?: boolean,
        public LearningHostUrl?: string,
        public TenantIdentifier?: string,
        public SalesforceId?: string,
        public AiccUrl?: string[],
        public AddUserEmails?: string[],
        public Environment?: EnvironmentType,
        public OrgSettingCollection?: KeyValue[] | null,
        public OrganizationModifications?: [OrgModifications],
        public IntegrationType?: IntegrationType,
        public OrgBundle?: [CcaBundle],
        public MetadataLanguages?: string[],
        public CreatedDate?: string,
        public Sso?: SSOObject,
        public Authentication?: AuthenticationObject,
        public XApi?: XAPIProcessObject
    ) {
        if (this.Id == null) {
            this.Id = uuid();
        }
    }
}
export class OrgModifications{
    constructor(
        public LastModifiedBy: string,
        public OrganizationModified: string
    ){}
}

export class CcaBundle{
    constructor(
        public IsActive: boolean,
        public Id?: string,
        public BundleSubscriptionDate?: string,
    ){}
}

export enum IntegrationType {
    AICC_SNS = 0,
    Deeplink= 1,
    AICC_Manual= 2,
    AICC_HACP = 3,
}

export enum SsoType {
    IDP = 0,
    SP = 1
}

export enum EnvironmentType{
    Production = 0,
    Stage = 1
}

export const stringToSsoType = (value: string) : SsoType | undefined => {
    switch(value) {
        case SsoType[SsoType.IDP]: return SsoType.IDP;
        case SsoType[SsoType.SP]: return SsoType.SP;
        default: return undefined;
    }
}

export const ssoTypeToString = (value: SsoType | undefined): string => {
    switch(value) {
        case SsoType.IDP: return SsoType[SsoType.IDP];
        case SsoType.SP: return SsoType[SsoType.SP];
        default: return "";
    }
}
export const stringToIntegrationType = (value: any): IntegrationType | undefined=> {
    switch(value) {
        case IntegrationType[IntegrationType.AICC_SNS]: return IntegrationType.AICC_SNS;
        case IntegrationType[IntegrationType.Deeplink]: return IntegrationType.Deeplink;
        case IntegrationType[IntegrationType.AICC_Manual]: return IntegrationType.AICC_Manual;
        case IntegrationType[IntegrationType.AICC_HACP]: return IntegrationType.AICC_HACP;
        default: return undefined;
    }
}
export const stringToEnvType = (value: any): EnvironmentType | undefined => {
    switch(value) {
        case EnvironmentType[EnvironmentType.Production]: return EnvironmentType.Production;
        case EnvironmentType[EnvironmentType.Stage]: return EnvironmentType.Stage;
        default: return undefined;
    }
}
export const integrationTypeToString = (value: IntegrationType | undefined): string => {
    switch(value) {
        case IntegrationType.AICC_SNS: return IntegrationType[IntegrationType.AICC_SNS];
        case IntegrationType.Deeplink: return IntegrationType[IntegrationType.Deeplink];
        case IntegrationType.AICC_Manual: return IntegrationType[IntegrationType.AICC_Manual];
        case IntegrationType.AICC_HACP: return IntegrationType[IntegrationType.AICC_HACP];
        default: return "";
    }
}


export const envTypeToString = (value:EnvironmentType | undefined): string =>{
    switch(value) {
        case EnvironmentType.Production: return EnvironmentType[EnvironmentType.Production];
        case EnvironmentType.Stage: return EnvironmentType[EnvironmentType.Stage];
        default: return "";
    }
}
export class AuthenticationObject {
    constructor(
        public AuthType : AuthenticationType,
        public Username : string ="",
        public Password : string ="",
        public ClientId : string ="",
        public ClientSecret: string =""
    ){}
}
export class XAPIProcessObject {
    constructor(
        public AuthType : AuthenticationType,
        public AuthenticationTokenUrl :string  = "",
        public ClientId               :string = "",
        public ClientSecret           :string = "",
        public Scope                  :string = "",
        public GrantType              :string = "",
        public XApiAccountHomepage    :string = "",
        public FreeformFields: KeyTypeValue[] | null,
        public XApiEndpoint           :string ){}

}
export class SSOObject {
    constructor(
        public Type  : SsoType | undefined,
        public RequestIssuer  : string = "",
        public ResponseIssuer  : string = "",
        public RequestDestination  : string = "",
        public AssertionConsumerServiceUrl  : string = "",
        public IdpCertificate: string= "",
        public FreeformFields: KeyValue[] | null,
        public Audience  : string = "",
        public SpCertificate: string = "",
        public IsSpEncrypted: boolean = false){}
        
}
export interface KeyValue {
    Key: string,
    Value: string
}


interface KeyTypeValue{
    Key:string,
    Type?:XapiFreeformType,
    Value:string
}
export class KeyValueInput implements KeyValue {
    constructor (public Key: string = "",
    public Value: string = "") { }
}

export class KeyTypeValueInput implements KeyTypeValue {
    constructor (public Key: string = "",
    public Type?: XapiFreeformType,
    public Value: string = "") { }
}
   
export enum XapiFreeformType {
    TokenBody = "token.body",
    TokenHeader = "token.header",
    StatementBody = "statement.body",
    StatementHeader = "statement.header",
    General = "general",
    Configuration= "configuration",
}