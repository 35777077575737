import React, { Component } from 'react';
import CodeHelpers from '../../../helpers/common/CodeHelpers';
import { InputWidthTypes } from '../../../helpers/common/StyleHelper';
import "./HourInputComponent.css";

interface HourInputComponentProps {
    title: string,
    important: boolean,
    type: string,
    defaultValue?: string,
    widthClass: InputWidthTypes,
    errorMessage?: string,
    onValueChanged: (value: string) => void,
    cy_data?: string
}

export default class HourInputComponent extends Component<HourInputComponentProps> {

    onHourChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        event.target.value = event.target.value.slice(event.target.value.length-2,event.target.value.length);
        while(event.target.value.length < 2)
        {
            event.target.value = "0" + event.target.value;
            this.props.onValueChanged(event.target.value);
        }
        if(!event.target.checkValidity()) {
            event.target.value = "23";
        }
        this.props.onValueChanged(event.target.value + ":00:00");
    }

    render() {
        return <div className = "csod-inputs-column-container" cy-data={this.props.cy_data}>
            <div className = "csod-input-title-text non-selectable">{this.props.title + `${this.props.important?" *" : ""}`}</div>
            <div className = "csod-inputs-row-container two-columns-container no-flex-wrap">
            <div className= {`csod-input-hour-container alternative-margin-for-inputs min-width-20 ${!CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage)? "error-border" : ""}`}>
                <input className={`csod-hour-input-field ${!CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage)? "has-error" : ""}`} type="number" step = "1" max="23" min="0" defaultValue={this.props.defaultValue != null? this.props.defaultValue.slice(0,2): "00"} onChange={this.onHourChanged}/>
                <div className="csod-hour-input-placeholder gray-text">:00</div>
            </div>
                <div className = "csod-input-title-text non-selectable">UTC</div>
            </div>
            {(!CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage))? <div className = "error-text-color">{this.props.errorMessage}</div> : null}
        </div>
    }
}