export const PROVIDER_NAME_CY_DATA = "provider-name-input"
export const PROVIDER_ABBREVIATION_CY_DATA = "provider-abbr-input"
export const PROVIDER_IS_CCC_CY_DATA = "provider-ccc-input"

export const PROVIDER_TRAINING_TYPE_CY_DATA = "provider-training-type-input"
export const PROVIDER_OFFERING_TYPE_CY_DATA = "provider-offering-type-input"
export const PROVIDER_CIID_CY_DATA = "provider-ciid-input"
export const PROVIDER_SYNC_URL_CY_DATA = "provider-sync-url-input" 
export const PROVIDER_AUTH_TYPE_CY_DATA = "provider-auth-type-input" 
//Basic
export const PROVIDER_BASIC_USERNAME_CY_DATA = "provider-username-input" 
export const PROVIDER_BASIC_PASSWORD_CY_DATA = "provider-password-input" 
//OAuth 2
export const PROVIDER_OAUTH_AUTHENTICATION_URL_CY_DATA = "provider-authentication-url-input" 
export const PROVIDER_OAUTH_CLIENT_ID_CY_DATA = "provider-client-id-input"
export const PROVIDER_OAUTH_CLIENT_SECRET_KEY_CY_DATA = "provider-client-secret-key-input"
export const PROVIDER_OAUTH_SCOPE_CY_DATA = "provider-scope-input"
export const PROVIDER_OAUTH_AUDIENCE_CY_DATA = "provider-audience-input"
//
export const PROVIDER_SYNC_HOUR_CY_DATA = "provider-sync-hour-input" 
export const PROVIDER_CONTACT_EMAILS_CY_DATA = "provider-contact-emails-input" 

export const IS_FOR_CURATION_CY_DATA = "is-for-curation-input"
export const IS_EXCEL_PROVIDER_CY_DATA = "is-excel-provider-input"
export const IS_STRICT_SCHEMA_CY_DATA = "is-strict-schema-input"
export const IS_MOBILE_DEFAULT_CY_DATA = "is-mobile-default-input"

export const PROVIDER_NOTES_CY_DATA = "provider-notes-input"
//Subjects mapping
export const PROVIDER_SUBJECTS_MAPPING_CY_DATA = "subjects-mapping-input"
export const PROVIDER_COURSE_MAPPING_CY_DATA  = "course-mapping-input"
export const PROVIDER_CUSTOM_COURSE_MAPPING_POPUP_CY_DATA  = "course-mapping-input"
export const PROVIDER_CUSTOM_TAXONOMY_POPUP_CY_DATA = "custom-taxonomy-popup"
//
