import React, { Component } from 'react';
import "./GlobalLoaderComponent.css";

interface GlobalLoaderComponentProps {
    isLoading: boolean
}

export default class GlobalLoaderComponent extends Component<GlobalLoaderComponentProps> {
    render() {
        return this.props.isLoading? (
            <div className="csod-lds"/>
        ) : null;
    }
}