import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProviderStore from '../../stores/ProvidersStore';
import { mapToTrainingType, TrainingType } from '../../models/ProviderDetails/TrainingType';
import DropdownComponent from '../common/inputs/DropdownComponent';
import { mapToProviderOfferingType, ProviderOfferingType, providerOfferingTypeToString } from '../../models/ProviderDetails/ProviderOfferingType';
import { AuthenticationType, authTypeToString, stringToAuthType } from '../../models/ProviderDetails/AuthenticationType';
import LargeTextboxComponent from '../common/inputs/LargeTextboxComponent';
import SwitcherComponent from '../common/inputs/SwitcherComponent';
import CreatableSelect from 'react-select/creatable';
import LabelComponent from '../common/inputs/LabelComponent';
import RadioButtonGroupComponent from '../common/inputs/RadioButtonGroupComponent';
import TextInputComponent from '../common/inputs/TextInputComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import { v4 } from 'uuid';
import "./FrameworkComponent.css";
import { SubjectMapping, CourseMapping} from '../../models/ProviderDetails/ProviderDetailsExpansion';
import ProviderDetailsErrorModel from '../../models/ProviderDetails/ProviderDetailsErrorModel';
import HourInputComponent from '../common/inputs/HourInputComponent';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import ProviderModel, { CSOD_PREDEFINED_COURSE_MAPPING, CSOD_PREDEFINED_SUBJECTS_MAPPING, KeyValue, KeyValueInput } from '../../models/ProviderDetails/ProviderModel';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import { IS_EXCEL_PROVIDER_CY_DATA, IS_FOR_CURATION_CY_DATA, IS_MOBILE_DEFAULT_CY_DATA, IS_STRICT_SCHEMA_CY_DATA, PROVIDER_ABBREVIATION_CY_DATA, PROVIDER_AUTH_TYPE_CY_DATA, PROVIDER_BASIC_PASSWORD_CY_DATA, PROVIDER_BASIC_USERNAME_CY_DATA, PROVIDER_CIID_CY_DATA, PROVIDER_CONTACT_EMAILS_CY_DATA, PROVIDER_NAME_CY_DATA, PROVIDER_NOTES_CY_DATA, PROVIDER_OAUTH_AUTHENTICATION_URL_CY_DATA, PROVIDER_OAUTH_CLIENT_ID_CY_DATA, PROVIDER_OAUTH_CLIENT_SECRET_KEY_CY_DATA, PROVIDER_OAUTH_SCOPE_CY_DATA, PROVIDER_OAUTH_AUDIENCE_CY_DATA, PROVIDER_OFFERING_TYPE_CY_DATA, PROVIDER_SUBJECTS_MAPPING_CY_DATA,PROVIDER_COURSE_MAPPING_CY_DATA, PROVIDER_SYNC_HOUR_CY_DATA, PROVIDER_SYNC_URL_CY_DATA, PROVIDER_TRAINING_TYPE_CY_DATA ,PROVIDER_IS_CCC_CY_DATA} from '../../cypressDataConstants/providerDetailsCyDataConstants';
import FrameworkHelpers from '../../helpers/framework/FrameworkHelpers';
import ProviderDetailsConstants from '../../constants/providerDetailsConstants';
import Select from "react-select";


interface ProviderDetailsProps {
    providerDetailsErrorMessages?: ProviderDetailsErrorModel,
    providers?: ProviderStore,
    callTaxonomyPopup: () => void,
    callCustomCourseMappingPopup: () => void,
    isScrolledToProblemElement: boolean,
    onScrolledToProblemElement: () => void,
    customCiid: string
}

interface ProviderDetailsState {
    authType?: AuthenticationType,
    OfferingType?: ProviderOfferingType,
    TrainingType?: TrainingType,
    isStrictSchema: boolean,
    isTaxonomyRadioButtonClicked: boolean,
    isCourseMappingRadioButtonClicked: boolean,
    customCiid: string,
    showCustomCiid: boolean
    selectedFile: Blob
    imagePreview: string,
    imageError: string,
}

@inject('providers')
@observer
export default class ProviderDetails extends Component<ProviderDetailsProps, ProviderDetailsState> {

    constructor(props: ProviderDetailsProps) {
        super(props);
        this.state = {
            authType: props.providers?.CurrentProvider?.AuthenticationType,
            OfferingType: this.props.providers?.CurrentProvider?.ProviderOfferingType,
            isTaxonomyRadioButtonClicked: this.props.providers?.CurrentProvider?.SubjectsMapping != null,
            isCourseMappingRadioButtonClicked: this.props.providers?.CurrentProvider?.CourseMapping != null,
            isStrictSchema: this.props.providers?.CurrentProvider?.IsStrictSchema!,
            TrainingType: this.props.providers?.CurrentProvider?.Type,
            showCustomCiid: false,
            customCiid: ""
        }
    }


    onFileChange = (event) => {
        // Update the state
        this.setState({
            selectedFile: event.target.files[0],            
        });        
    };

    onFileUpload = () => {
        try {

            this.getfileData();
        } catch (e) {
                this.setState({
                    showPopup:true,
                    modalTitle:"Invalid File Upload",
                    modalMessage:"Unable to use uploaded file"
                })
        }


    };
    getfileData = () => {
        let valid: string[] = [];        
        let blob =this.state.selectedFile;
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        if (!blob.type.includes("image/"))
            {
                this.setState({imageError: `${blob.type} is not a valid file type for logo `, imagePreview: null})
                return;
            }
    
        console.log(blob.type)
        reader.onloadend = () => {
            let base64String = reader.result;
            //base64String  = base64String.replace("data:image/jpeg;base64,","");
            base64String = base64String.substr(base64String.indexOf(',')+1);
            this.props.providers.CurrentProvider.Logo = base64String;
            this.setState({
                imagePreview:  reader.result,
                imageError: undefined
            })
        }
    }

    fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div>
                    <label>All Values from file</label>
                    <p>
                        {this.getfileData()}

                    </p>

                </div>

            );
        }
    };
    componentDidUpdate = () => {
        FrameworkHelpers.goToProblemElement(this.props.isScrolledToProblemElement, this.props.onScrolledToProblemElement);
    }

    componentDidMount = () => {
        FrameworkHelpers.goToProblemElement(this.props.isScrolledToProblemElement, this.props.onScrolledToProblemElement);
    }

    private isNeedToLockIsStrictSchema = (offeringType?: ProviderOfferingType, value?: boolean) => {
        var currentOfferingType = offeringType ? offeringType : this.state.OfferingType;
        var currentIsForCuration = value ? value : this.props.providers?.CurrentProviderDetailsExpansion.IsForCuration;
        var isExcelProvider = value ? value : this.props.providers?.CurrentProviderDetailsExpansion.IsExcelProvider;
        return (currentOfferingType !== ProviderOfferingType.OneToOne) && (currentIsForCuration || isExcelProvider);
    }


    getCiidDropDown = () => {

        if (this.state.OfferingType !== ProviderOfferingType.OneToOne && this.state.OfferingType !== ProviderOfferingType.Both)
            return null;

        return <>
            <div className='input_ciid_wrapper'>
                <Select placeholder="Select CIID from list to test or sync" myFontSize="20px" className='' onChange={this.onCIIDChanged} important={false} options={this.props.providers!.CurrentProviderDetailsExpansion.CIIDs.map(x => ({ label: x, value: x }))} />
                <button onClick={(event) => { this.setState({ showCustomCiid: !this.state.showCustomCiid }) }} className="csod-button blue less-rounded-corners"> + </button>
            </div>

            {this.state.showCustomCiid ? <div className='input_ciid_wrapper'>
                <input onChange={(e) => this.onCustomCiidChange(e)} type="text" className="csod-input-textbox" />
                <button className="csod-button blue less-rounded-corners" onClick={() => this.AddCustomCiid()} > Add </button>
            </div> : null}
        </>
    }

    render() {
        if (!this.props.providers?.CurrentProvider?.FreeFormParams)
            this.props.providers!.CurrentProvider!.FreeFormParams = [];
    
        var isActiveProvider = !(this.props.providers?.CurrentProvider?.IsActive === false);
        return <div className={isActiveProvider ? "csod-framework-tab-content" : "csod-framework-tab-content irregularColor"}>
            <div className="csod-framework-layer">
                <div className="csod-framework-header non-selectable">{ProviderDetailsConstants.GENERAL_INFORMATION_HEADER}</div>
                <div className="csod-inputs-row-container two-columns-container">
                    <TextInputComponent title="Provider Name" important={true} widthClass={InputWidthTypes.Middle} defaultValue={this.props.providers?.CurrentProvider?.Name} errorMessage={this.props.providerDetailsErrorMessages?.nameErrorMessage} maxlength={100} onValueChanged={this.onProviderNameChanged} cy_data={PROVIDER_NAME_CY_DATA} />

                    {this.props.providers?.FrameworkMode === FrameworkModes.Edit ?
                        <LabelComponent title="Abbreviation for Global Content ID" isHidden={false} isAbleToCopy={false} widthClass={InputWidthTypes.Small} value={this.props.providers.CurrentProvider!.Abbreviation || ""} cy_data={PROVIDER_ABBREVIATION_CY_DATA} />
                        :
                        <TextInputComponent title="Abbreviation for Global Content ID" placeholder="Please enter up to 4 characters" widthClass={InputWidthTypes.Small} important={true} defaultValue={this.props.providers?.CurrentProvider?.Abbreviation} errorMessage={this.props.providerDetailsErrorMessages?.abbreviationErrorMessage} maxlength={4} onValueChanged={this.onAbbreviationNameChanged} cy_data={PROVIDER_ABBREVIATION_CY_DATA} />
                    }
                </div>

                <div className="csod-inputs-row-container two-columns-container">
                    <LabelComponent title="ID" value={this.props.providers?.CurrentProvider?.Id != null ? this.props.providers.CurrentProvider.Id : "N/A"} isAbleToCopy={true} isHidden={false} widthClass={InputWidthTypes.Middle} />
                    {this.props.providers?.FrameworkMode === FrameworkModes.Edit ?
                        <LabelComponent title="Training Type" isHidden={false} isAbleToCopy={false} widthClass={InputWidthTypes.Small} value={this.props.providers.CurrentProvider!.Type || ""} cy_data={PROVIDER_TRAINING_TYPE_CY_DATA} />
                        :
                        <DropdownComponent
                            title="Training Type"
                            placeholder="Choose training type"
                            items={Object.values(TrainingType)}
                            widthClass={InputWidthTypes.Smallest}
                            onValueChanged={this.onTrainingTypeChanged}
                            important={true}
                            errorMessage={this.props.providerDetailsErrorMessages?.TrainingTypeErrorMessage}
                            defaultValue={this.props.providers?.CurrentProvider?.Type}
                            cy_data={PROVIDER_TRAINING_TYPE_CY_DATA}
                        />
                    }
                </div>
                <div className="csod-inputs-row-container two-columns-container">
                    <div className="csod-inputs-row-container two-columns-100-width-elements-container middle-width">
                        <DropdownComponent
                            title="Offering Type"
                            placeholder="Choose offering type"
                            items={[
                                providerOfferingTypeToString(ProviderOfferingType.Both),
                                providerOfferingTypeToString(ProviderOfferingType.OneToMany),
                                providerOfferingTypeToString(ProviderOfferingType.OneToOne)
                            ]}
                            widthClass={InputWidthTypes.Smallest}
                            onValueChanged={this.onOfferingTypeChanged}
                            important={true}
                            errorMessage={this.props.providerDetailsErrorMessages?.OfferingTypeErrorMessage}
                            defaultValue={providerOfferingTypeToString(this.props.providers?.CurrentProvider?.ProviderOfferingType)}
                            cy_data={PROVIDER_OFFERING_TYPE_CY_DATA}
                        />
                    {this.state.OfferingType === ProviderOfferingType.OneToOne || this.state.OfferingType === ProviderOfferingType.Both? 
                        <Select placeholder="Select CIID from list to test or sync" myFontSize="20px" className='csod-inputs-column-container' onChange={this.onCIIDChanged} important = {false}  options={ this.props.providers!.CurrentProviderDetailsExpansion?.DirectSubscriptionCIIDData?.map(x=>({label: x.SubscriptionUri + " :: " +  x.Ciid,value:x})) }  /> : null}
                    </div>

                </div>
                <div className="csod-inputs-row-container two-columns-container">
                    <TextInputComponent title="Last Modified Date"
                        defaultValue={new Date().toISOString().substring(0, 19)} type="datetime-local"
                        widthClass={InputWidthTypes.Small} important={false}
                        onValueChanged={value => this.onLastModifiedDateChanged(value)} />
                </div>
                <div className="csod-inputs-row-container two-columns-container">
                    <LargeTextboxComponent
                        title="Description"
                        placeholder="Description"
                        onValueChanged={this.onProviderDescriptionChanged}
                        maxLettersCount={ProviderDetailsConstants.DESCRIPTION_MAX_LETTERS_COUNT}
                        widthClass={InputWidthTypes.Middle}
                        defaultValue={this.props.providers?.CurrentProvider?.Description}
                        important={false}
                        cy_data={PROVIDER_NOTES_CY_DATA}
                        errorMessage={this.props.providerDetailsErrorMessages?.ProviderNotesErrorMessage}
                    />
                </div>

                <div className='file-input-buttons left'>
                    <p id="upload-logo-title">Upload Logo:</p>
                    <input type="file" id='fileUploader' onChange={this.onFileChange} />
                    <img id='imagePreview' src={this.state.imagePreview ? this.state.imagePreview : this.props.providers?.CurrentProvider?.Logo} />
                    <div className='flex-wrapper'>
                        <label htmlFor='fileUploader' className='csod-button file-input choose-logo-button' > Choose file</label>
                        {this.state.selectedFile ? <label className='fileUploaderInfo'>{this.state.selectedFile.name}</label> : null}
                    </div>
                    <div className='flex-wrapper flex-wrapper-inline'>
                        <button type="button" className="csod-button less-rounded-corners blue " onClick={this.onFileUpload}>
                            Upload</button>
                    </div>

                </div>
                <p className='image-error'> {this.state.imageError}</p>

                <TextInputComponent title="Sync URL" important={true} widthClass={InputWidthTypes.Maximum} defaultValue={this.props.providers?.CurrentProvider?.CourseSyncUrl} errorMessage={this.props.providerDetailsErrorMessages?.SyncURLErrorMessage} maxlength={200} onValueChanged={this.onSyncURLChanged} type="url" cy_data={PROVIDER_SYNC_URL_CY_DATA} />
                {this.state.TrainingType === TrainingType.LTI ? this.renderLTIInputs() : null}

                <div className="csod-sub-framework-layer">
                    <div className="csod-framework-header non-selectable  margin-top-for-inputs">{ProviderDetailsConstants.AUTHENTICATION_HEADER}</div>
                    <div className="csod-inputs-row-container four-columns-container">
                        <DropdownComponent
                            title="Authentication Type"
                            placeholder="Choose authentication type"
                            items={[AuthenticationType[AuthenticationType.None], AuthenticationType[AuthenticationType.Basic], AuthenticationType[AuthenticationType.oAuth2], AuthenticationType[AuthenticationType.ApiKey]]}
                            widthClass={InputWidthTypes.Small}
                            onValueChanged={this.onAuthTypeChanged}
                            important={true}
                            errorMessage={this.props.providerDetailsErrorMessages?.AuthenticationTypeErrorMessage}
                            defaultValue={authTypeToString(this.props.providers?.CurrentProvider?.AuthenticationType)}
                            cy_data={PROVIDER_AUTH_TYPE_CY_DATA}
                        />
                    </div>
                    {this.state.authType === AuthenticationType.oAuth2 ? this.renderOauth2Inputs() : null}
                    {this.state.authType === AuthenticationType.Basic ? this.renderBasicAuthInputs() : null}
                    {this.state.authType === AuthenticationType.ApiKey ? this.renderApiKeyInputs() : null}
                </div>
                <div className="csod-framework-header non-selectable">{ProviderDetailsConstants.CONFIGURATION_HEADER}</div>
                <div className="csod-inputs-row-container four-columns-container">
                    <HourInputComponent title="Sync Hour" type="UTC" defaultValue={this.props.providers?.CurrentProvider?.SyncTime} important={false} errorMessage={this.props.providerDetailsErrorMessages?.SyncHourErrorMessage} widthClass={InputWidthTypes.Small} onValueChanged={this.onSyncHourChanged} cy_data={PROVIDER_SYNC_HOUR_CY_DATA} />
                </div>
                <div className="csod-inputs-column-container">
                    <div className="csod-inputs-row-container">
                        <SwitcherComponent title="Is Strict Schema" defaultValue={this.props.providers?.CurrentProvider?.IsStrictSchema} disabled={this.isNeedToLockIsStrictSchema() ? true : undefined} onValueChanged={this.onIsStrictSchemaChanged} cy_data={IS_STRICT_SCHEMA_CY_DATA} />
                        <SwitcherComponent title="Is Excel Provider" defaultValue={this.props.providers?.CurrentProviderDetailsExpansion.IsExcelProvider} onValueChanged={this.onIsExcelProviderChanged} cy_data={IS_EXCEL_PROVIDER_CY_DATA} />
                        <SwitcherComponent title="Is For Curation" defaultValue={this.props.providers?.CurrentProviderDetailsExpansion.IsForCuration} disabled={this.state.OfferingType === ProviderOfferingType.OneToOne} onValueChanged={this.onIsForCurationChanged} cy_data={IS_FOR_CURATION_CY_DATA} />
                        <SwitcherComponent title="Is Mobile Default" defaultValue={this.props.providers?.CurrentProvider?.IsMobileSupported} onValueChanged={this.onIsMobileDefaultChanged} cy_data={IS_MOBILE_DEFAULT_CY_DATA} />
                        <SwitcherComponent title="Is Active" defaultValue={!(this.props.providers?.CurrentProvider?.IsActive === false)} onValueChanged={this.onIsActiveChanged} />
                        <SwitcherComponent title="Is Support Inline" defaultValue={!this.props.providers?.CurrentProvider?.SupportInline === false} onValueChanged={this.onSupportInlineChanged} />
                        <SwitcherComponent title="IsCCC" defaultValue={this.props.providers?.CurrentProvider?.IsCCC} disabled={true}  onValueChanged={this.onIsCccChanged}  />

                    </div>
                </div>
                <TextInputComponent title="Contact Emails" important={false} type={'email'} regex={/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$/}
                    widthClass={InputWidthTypes.Maximum} maxlength={400} placeholder={'testEmail@gmail.com,anotherEmail@hotmail.com'} example={'testEmail@gmail.com,anotherEmail@hotmail.com'}
                    defaultValue={this.props.providers?.CurrentProviderDetailsExpansion.ContactEmails} multiple={true}
                    errorMessage={this.props.providerDetailsErrorMessages?.EmailsErrorMessage} onValueChanged={this.onContactEmailsChanged} cy_data={PROVIDER_CONTACT_EMAILS_CY_DATA} />
                <RadioButtonGroupComponent
                    groupTitle="Subject Mapping"
                    groupName="subject-mapping"
                    names={[SubjectMapping.CSODPreset]}
                    customRadioTitle={SubjectMapping.Custom}
                    defaultValue={this.props.providers?.CurrentProviderDetailsExpansion.SubjectMappingType}
                    valueUsedIfDefaultIsNull={SubjectMapping.CSODPreset}
                    onEditClick={this.props.callTaxonomyPopup}
                    onValueChanged={this.onSubjectMappingChanged}
                    isEditButtonShowed={CodeHelpers.IsStringNullOrEmpty(this.props.providers?.CurrentProviderDetailsExpansion.CustomTaxonomy) ? undefined : true}
                    cy_data={PROVIDER_SUBJECTS_MAPPING_CY_DATA}
                />
                {this.props.providers!.CurrentProvider!.Type ===TrainingType.LTI? <RadioButtonGroupComponent
                    groupTitle="Course Mapping"
                    groupName="course-mapping"
                    names={[CourseMapping.None]}
                    tooltipLink="https://wiki.cornerstoneondemand.com/display/ELP/CDS+Provider+Course+Mapping+Setup"
                    customRadioTitle={CourseMapping.Custom}
                    defaultValue={this.props.providers?.CurrentProviderDetailsExpansion.CourseMappingType}
                    valueUsedIfDefaultIsNull={CourseMapping.None}
                    onEditClick={this.props.callCustomCourseMappingPopup}
                    onValueChanged={this.onCourseMappingChanged}
                    isEditButtonShowed={CodeHelpers.IsStringNullOrEmpty(this.props.providers?.CurrentProviderDetailsExpansion.CustomCourseMapping) ? undefined : true}
                    cy_data={PROVIDER_COURSE_MAPPING_CY_DATA}
                /> : null }
            </div>
            {(this.props.providers!.FrameworkMode === FrameworkModes.Edit &&
                !CodeHelpers.IsStringNullOrEmpty(this.props.providers!.CurrentProgressApiConfig!.Password) &&
                !CodeHelpers.IsStringNullOrEmpty(this.props.providers!.CurrentProgressApiConfig!.Username)) ? this.renderProgressApiCredentials() : null}
            {(this.props.providers!.FrameworkMode === FrameworkModes.Edit && this.props.providers?.LastModifiedBy && this.renderEmailUpdate())}
        </div>
    }
    private renderLTIInputs = () => {
        var freeFormParamCount = 0;
        return <div>
            <div>
                <TextInputComponent title="Login URL" important={true} widthClass={InputWidthTypes.Maximum} defaultValue={this.props.providers?.CurrentProvider?.LoginUrl}
                 maxlength={100} onValueChanged={this.onLoginUrlChanged}  />
            </div>
            <div> 
                <div style={{marginTop:"36px"}} className = "csod-input-title-text non-selectable">Launch URL * </div> 
                <CreatableSelect placeholder="Launch URL" myFontSize="20px" isMulti={true} important={true}
                value={this.props.providers?.CurrentProvider?.LaunchUrl?.map(x => ({ label: x, value: x }))}
                onChange={this.onLaunchUrlChanged} />
            </div>
            <div>
               
            </div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent title="JWKS URL" important={true} widthClass={InputWidthTypes.Large} defaultValue={this.props.providers?.CurrentProvider?.JWKsURL}
                 onValueChanged={this.onJWKsURLChanged}  />
                <TextInputComponent title="LTI Client ID/Provider ID" important={true} widthClass={InputWidthTypes.Middle} defaultValue={this.props.providers?.CurrentProvider?.LTIClientId}
                 maxlength={100} onValueChanged={this.onLTIClientIdChanged}  />
            </div>

            <div className="csod-accordion-content">
                <button className="csod-button gray less-rounded-corners" onClick={() => this.OnAddNewFreeFormKV()}>Add New Freeform parameter</button>
                {this.props.providers!.CurrentProvider!.FreeFormParams!.map(i => this.renderFreeForm(v4(), freeFormParamCount++, freeFormParamCount !== 1))}
            </div>
        </div>
    }
    
    private renderFreeForm = (key: string, index: number, isHeaderHidden: boolean) => {
        var additionalSettingModel = this.props.providers?.CurrentProvider?.FreeFormParams![index];

        return <div key={key} className="csod-inputs-row-container no-right-margins four-columns-30-20-20-30">
            <TextInputComponent title="Key" isHeaderHidden={isHeaderHidden} additionalClass={"less-margin-top"} defaultValue={additionalSettingModel?.Key} widthClass={InputWidthTypes.Small} onValueChanged={this.onChangeFreeFormKey(index)} />
            <TextInputComponent title="Value" isHeaderHidden={isHeaderHidden} additionalClass={"less-margin-top"} defaultValue={additionalSettingModel?.Value} widthClass={InputWidthTypes.Small} onValueChanged={this.onChangeFreeFormValue(index)} />
            <button className="csod-remove-row-button org-remove-param" onClick={this.OnRemoveFreeFormKV(index)} />
        </div>
    }
    private OnRemoveFreeFormKV = (index: number) => {
        return () => {
            this.props.providers?.CurrentProvider?.FreeFormParams!.splice(index, 1);
            this.forceUpdate();
        }
    }
    private OnAddNewFreeFormKV = () => {
        this.props.providers!.CurrentProvider!.FreeFormParams!.push(new KeyValueInput());
        this.forceUpdate();
    }
    private onChangeFreeFormValue = (index: number) => {
        return (value: string) => {
                this.props.providers!.CurrentProvider!.FreeFormParams![index].Value = value;
        }
    }

    private onChangeFreeFormKey = (index: number)  => {
        return (key: string) => {
            this.props.providers!.CurrentProvider!.FreeFormParams![index].Key = key;
        }
    }

    private renderOauth2Inputs = () => {
        return <div>
            <TextInputComponent title="Authentication URL" important={true} errorMessage={this.props.providerDetailsErrorMessages?.AuthenticationURLErrorMessage} widthClass={InputWidthTypes.Maximum} defaultValue={this.props.providers?.CurrentOAuth2Config?.AuthenticationUrl} maxlength={150} onValueChanged={this.onAuthenticationURLChanged} type="url" cy_data={PROVIDER_OAUTH_AUTHENTICATION_URL_CY_DATA} />
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent type="username" title="Client ID" important={true} errorMessage={this.props.providerDetailsErrorMessages?.ClientIDErrorMessage} widthClass={InputWidthTypes.Large} defaultValue={this.props.providers?.CurrentOAuth2Config?.ClientID} maxlength={100} onValueChanged={this.onClientIdChanged} cy_data={PROVIDER_OAUTH_CLIENT_ID_CY_DATA} />
            </div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent type="password" title="Client Secret Key" important={true} errorMessage={this.props.providerDetailsErrorMessages?.ClientSecretKeyErrorMessage} widthClass={InputWidthTypes.Large} defaultValue={this.props.providers?.CurrentOAuth2Config?.ClientSecretKey} maxlength={130} onValueChanged={this.onClientSecretKeyChanged} cy_data={PROVIDER_OAUTH_CLIENT_SECRET_KEY_CY_DATA} />
            </div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent title="Scope" important={false} widthClass={InputWidthTypes.Middle} defaultValue={this.props.providers?.CurrentOAuth2Config?.Scope} maxlength={100} onValueChanged={this.onScopeChanged} cy_data={PROVIDER_OAUTH_SCOPE_CY_DATA} />
            </div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent title="Audience" important={false} widthClass={InputWidthTypes.Middle} defaultValue={this.props.providers?.CurrentOAuth2Config?.Audience} maxlength={100} onValueChanged={this.onAudienceChanged} cy_data={PROVIDER_OAUTH_AUDIENCE_CY_DATA} />
            </div>
        </div>
    }

    private renderEmailUpdate = () => {
        return <div>
            <div className="csod-inputs-row-container two-columns-container changeByUserStyle">
                Last modified by:  {this.props.providers?.LastModifiedBy} - {this.props.providers?.ProviderModified}
            </div>
        </div>

    }

    private renderBasicAuthInputs = () => {
        return <div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent title="Username" important={true} widthClass={InputWidthTypes.Middle} errorMessage={this.props.providerDetailsErrorMessages?.UsernameErrorMessage} defaultValue={this.props.providers?.CurrentBasicAuthConfig?.Username} maxlength={100} onValueChanged={this.onUsernameChanged} cy_data={PROVIDER_BASIC_USERNAME_CY_DATA} />
            </div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent title="Password" important={true} widthClass={InputWidthTypes.Middle} errorMessage={this.props.providerDetailsErrorMessages?.PasswordErrorMessage} defaultValue={this.props.providers?.CurrentBasicAuthConfig?.Password} maxlength={100} onValueChanged={this.onPasswordChanged} type="password" cy_data={PROVIDER_BASIC_PASSWORD_CY_DATA} />
            </div>
        </div>
    }

    private renderApiKeyInputs = () => {
        return <div>
            <div className="csod-inputs-row-container two-columns-container">
                <TextInputComponent type="password" title="Api Key"  important={true} errorMessage={this.props.providerDetailsErrorMessages?.ClientSecretKeyErrorMessage} widthClass={InputWidthTypes.Large} defaultValue={this.props.providers?.CurrentApiKeyConfig?.ClientSecretKey} maxlength={130} onValueChanged={this.onApiKeyChanged} cy_data={PROVIDER_OAUTH_CLIENT_SECRET_KEY_CY_DATA} />
            </div>
        </div>
    }
    
    private renderProgressApiCredentials = () => {
        return <div className="csod-framework-layer">
            <div className="csod-framework-header non-selectable">{ProviderDetailsConstants.BASIC_AUTHENTICATION_HEADER}</div>
            <div className="csod-inputs-row-container fit-content-width-elements-container">
                <LabelComponent title="Username" value={this.props.providers?.CurrentProgressApiConfig?.Username != null ? this.props.providers.CurrentProgressApiConfig.Username : ""} isAbleToCopy={true} isHidden={false} widthClass={InputWidthTypes.Middle} />
                <LabelComponent title="Password" value={this.props.providers?.CurrentProgressApiConfig?.Password != null ? this.props.providers.CurrentProgressApiConfig.Password : ""} isAbleToCopy={true} isHidden={true} widthClass={InputWidthTypes.Middle} />
            </div>
        </div>
    }

    /*OAuth2Config mapping */
    private onAuthenticationURLChanged = (value: string) => {
        this.props.providers!.CurrentOAuth2Config!.AuthenticationUrl = value;
    }

    private onClientIdChanged = (value: string) => {
        this.props.providers!.CurrentOAuth2Config!.ClientID = value;
    }

    private onClientSecretKeyChanged = (value: string) => {
        this.props.providers!.CurrentOAuth2Config!.ClientSecretKey = value;
    }

    private onApiKeyChanged = (value: string) => {
        this.props.providers!.CurrentApiKeyConfig!.ClientSecretKey = value;
    }

    private onScopeChanged = (value: string) => {
        this.props.providers!.CurrentOAuth2Config!.Scope = value;
    }

    private onAudienceChanged = (value: string) => {
        this.props.providers!.CurrentOAuth2Config!.Audience = value;
    }
    /*OAuth2Config mapping end*/

    /*BasicAuthConfig mapping*/
    private onUsernameChanged = (value: string) => {
        this.props.providers!.CurrentBasicAuthConfig!.Username = value;
    }

    private onPasswordChanged = (value: string) => {
        this.props.providers!.CurrentBasicAuthConfig!.Password = value;
    }
    /*BasicAuthConfig mapping end*/

    private onProviderNameChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.Name = value;
    }
    private onJWKsURLChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.JWKsURL = value;
    }
    private onPublicKeyChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.PublicKey = value;
    }
    private onLTIClientIdChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.LTIClientId = value;
    }
    private onLaunchUrlChanged = (value: any) => {
        this.props.providers!.CurrentProvider!.LaunchUrl = value.map((x) => {
            return x.value;
        });
        this.setState({});
    }

    private onLoginUrlChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.LoginUrl = value;
    }

    private onAbbreviationNameChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.Abbreviation = value;
    }

    private onLastModifiedDateChanged = (value: string) => {
        if (!Date.parse(value))
            return false;
        this.props.providers!.CurrentProvider!.LastSuccessfulModifiedDateCourse = value;
    }

    private onOfferingTypeChanged = (value: string) => {
        let offeringType = mapToProviderOfferingType(value);
        this.props.providers!.CurrentProvider!.ProviderOfferingType = offeringType;
        if (this.isNeedToLockIsStrictSchema(offeringType)) {
            this.props.providers!.CurrentProvider!.IsStrictSchema = true;
        }
        if (offeringType === ProviderOfferingType.OneToOne) {
            this.props.providers!.CurrentProviderDetailsExpansion.IsForCuration = false;
        }
        this.setState({ OfferingType: offeringType });
    }

    private onCIIDChanged = (item: any) => {
        this.props.providers!.CurrentProviderDetailsExpansion!.SelectedCIID = item.value;
    }

    private onTrainingTypeChanged = (value: string) => {
        let trainingType: TrainingType | undefined = mapToTrainingType(value);
        
        this.props.providers!.CurrentProvider!.IsCCC = value==TrainingType.LTI;
        this.props.providers!.CurrentProvider!.Type = trainingType;
        this.setState({ TrainingType: trainingType });
    }

    private onAuthTypeChanged = (value: string) => {
        let authType = stringToAuthType(value);
        if (authType != null) {
            this.props.providers!.CurrentProvider!.AuthenticationType = authType;
            this.setState({ authType: authType });
        }
    }

    private AddCustomCiid = () => {
        let inputAddition =  ProviderDetailsConstants.CIID_CUSTOM_STRING + this.props.providers!.CustomCiid;
        if (!this.props.providers!.CurrentProviderDetailsExpansion.CIIDs.includes(inputAddition))
            this.props.providers!.CurrentProviderDetailsExpansion.CIIDs.push(inputAddition)
        
        this.setState({ customCiid: this.props.providers!.CustomCiid,
        showCustomCiid: false })        

    }
    private onCustomCiidChange = (event: React.FormEvent<HTMLInputElement>) => {

        this.props.providers!.CustomCiid = event.target!.value;
        console.log(this.props.providers!.CustomCiid)

    }
    private onProviderDescriptionChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.Description = value;
    }

    private onSyncURLChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.CourseSyncUrl = value;
    } 
    private onLogoChanged = (value: string) => {
        this.props.providers!.CurrentProvider!.Logo = value;
    }

    private onSupportInlineChanged = (value:boolean) => {
        this.props.providers!.CurrentProvider!.SupportInline = value;
    }

    private onIsStrictSchemaChanged = (value: boolean) => {
        this.props.providers!.CurrentProvider!.IsStrictSchema = value;
    }

    private onIsExcelProviderChanged = (value: boolean) => {
        if (this.isNeedToLockIsStrictSchema(undefined, value)) {
            this.props.providers!.CurrentProvider!.IsStrictSchema = true;
        }
        this.props.providers!.CurrentProviderDetailsExpansion!.IsExcelProvider = value;
        this.setState({ isStrictSchema: value });
    }

    private onIsForCurationChanged = (value: boolean) => {
        if (this.isNeedToLockIsStrictSchema(undefined, value)) {
            this.props.providers!.CurrentProvider!.IsStrictSchema = true;
        }
        this.props.providers!.CurrentProviderDetailsExpansion!.IsForCuration = value;
        this.setState({ isStrictSchema: value });
    }
    private onIsCccChanged = (value: boolean) => {
       
        this.props.providers!.CurrentProvider!.IsCCC = value;
    }
    private onIsMobileDefaultChanged = (value: boolean) => {
        this.props.providers!.CurrentProvider!.IsMobileSupported = value;
    }

    private onIsActiveChanged = (value: boolean) => {
        this.props.providers!.CurrentProvider!.IsActive = value;
    }

    private onContactEmailsChanged = (value: string) => {
        this.props.providers!.CurrentProviderDetailsExpansion!.ContactEmails = value;
    }


    
    private onCourseMappingChanged = (value: string) => {
        this.props.providers!.CurrentProviderDetailsExpansion!.CourseMappingType = value as CourseMapping;        
        switch (value) {
            case CourseMapping.Custom: {                
                this.props.providers!.CurrentProvider!.CourseMapping = this.props.providers!.CurrentProviderDetailsExpansion.CustomCourseMapping != null ? this.props.providers?.CurrentProviderDetailsExpansion.CustomCourseMapping! : "";
                break;
            }
            case CourseMapping.None: {
                this.props.providers!.CurrentProvider!.CourseMapping = CSOD_PREDEFINED_COURSE_MAPPING;
            }
        }
    }
    private onSubjectMappingChanged = (value: string) => {
        this.props.providers!.CurrentProviderDetailsExpansion!.SubjectMappingType = value as SubjectMapping;        
        switch (value) {
            case SubjectMapping.Custom: {
                this.props.providers!.CurrentProvider!.SubjectsMapping = this.props.providers!.CurrentProviderDetailsExpansion.CustomTaxonomy != null ? this.props.providers?.CurrentProviderDetailsExpansion.CustomTaxonomy! : "";
                break;
            }
            case SubjectMapping.CSODPreset: {
                this.props.providers!.CurrentProvider!.SubjectsMapping = CSOD_PREDEFINED_SUBJECTS_MAPPING;
            }
        }
    }

    private onSyncHourChanged = (value: string | undefined) => {
        this.props.providers!.CurrentProvider!.SyncTime = value;
    }
}