export const ENABLE_SSO_CY_DATA = "enable-sso-input";
export const SSO_ID_OU_ID_CY_DATA = "sso-id-ou-id-input";
export const OUTBOUND_SSO_URL_CY_DATA = "outbound-sso-url-label";
export const ACS_URL_CY_DATA = "acs-url-input";
export const TIMESTAMP_FORMAT_CY_DATA = "timestamp-format-input";
export const ISSUER_CY_DATA = "issuer-input";
export const AUDIENCE_CY_DATA = "audience-input";

//Authentication parameters
export const AUTHENTICATION_PARAMETERS_MAPPING_DROPDOWN_CY_DATA = "authentication-parameters-mapping-dropdown";
export const USER_ID_APM_CY_DATA = "user-id-apm-input";
export const USERNAME_APM_CY_DATA = "username-apm-input";
export const EMAIL_ADRESS_APM_CY_DATA = "email-adress-apm-input";
export const FIRST_NAME_APM_CY_DATA = "first-name-apm-input";
export const LAST_NAME_APM_CY_DATA = "last-name-apm-input";
export const MIDDLE_NAME_APM_CY_DATA = "middle-name-apm-input";
export const MANAGER_ID_APM_CY_DATA = "manager-id-apm-input";
export const USER_DIVISION_APM_CY_DATA = "user-division-apm-input";
export const USER_LOCATION_APM_CY_DATA = "user-location-apm-input";
export const LANGUAGE_APM_CY_DATA = "language-apm-input";
export const PHONE_HOME_APM_CY_DATA = "phone-home-apm-input";
export const WORK_PHONE_APM_CY_DATA = "work-phone-apm-input";

//SP initiated
export const SP_INITIATED_DROPDOWN_CY_DATA = "sp-initiated-dropdown";
export const SP_INITIATED_ENABLE_CY_DATA = "sp-initiated-enable-input";
export const AUTHENTICATION_REQUEST_ISSUER_CY_DATA = "authentication-request-issuer-input";
export const AUTHENTICATION_REQUEST_DESTINATION_CY_DATA = "authentication-request-destination-label";

//Advanced Properties
export const ADVANCED_PROPERTIES_DROPDOWN_CY_DATA = "advanced-properties-dropdown";
export const SIGNATURE_WITHIN_ASSERTION_CY_DATA = "signature-within-assertion-input";
export const INCLUDE_ISSUER_IN_RESPONSE_CY_DATA = "include-issuer-in-response-input";

//Querystring parameters
export const QUERYSTRING_PARAMETERS_DROPDOWN_CY_DATA = "querystring-parameters-dropdown";
export const QUERYSTRING_PARAMETER_ADD_CY_DATA = "querystring-parameter-add-bttn";
export const QUERYSTRING_PARAMETER_ROW_CY_DATA = "querystring-parameter-row";
export const QUERYSTRING_PARAMETER_NAME_CY_DATA = "querystring-parameter-name-input";
export const QUERYSTRING_PARAMETER_SAML_ATTRIBUTE_CY_DATA = "querystring-parameter-saml-attribute-input";
export const QUERYSTRING_PARAMETER_DELETE_CY_DATA = "querystring-parameter-delete-bttn";

//Additional settings
export const ADDITIONAL_SETTINGS_DROPDOWN_CY_DATA = "additional-settings-dropdown";
export const ADDITIONAL_SETTING_ADD_CY_DATA = "additional-setting-add-bttn";
export const ADDITIONAL_SETTING_ROW_CY_DATA = "additional-setting-row";
export const ADDITIONAL_SETTING_KEY_CY_DATA = "additional-setting-key-input";
export const ADDITIONAL_SETTING_TYPE_CY_DATA = "additional-setting-type-input";
export const ADDITIONAL_SETTING_POSITION_CY_DATA = "additional-setting-position-input";
export const ADDITIONAL_SETTING_VALUE_CY_DATA = "additional-setting-value-input";
export const ADDITIONAL_SETTING_DELETE_CY_DATA = "additional-setting-delete-bttn";
