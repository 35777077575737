import { observable, action } from 'mobx';
import GraphQLHelper from '../helpers/common/GraphQLHelper';
import { FrameworkModes } from '../models/ProviderFramework/FrameworkMode';

import PackageDetailsExpansion from '../models/PackageDetails/PackageModel';
import PackagesGridViewModel from '../models/PackagesGrid/PackagesGridViewModel';
import PackageModel from '../models/PackageDetails/PackageModel';
import SSOConfigModel from '../models/SSOConfig/SSOConfigModel';
import { TestSubscriptionOption } from '../models/TestSubscription/TestSubscriptionOption';


export default class PackagesStore {
    @observable Packages: PackageModel[] = observable([]);
    @observable CurrentPackage: PackageModel | null = null;    
    @observable CurrentSSOConfigModel: SSOConfigModel = new SSOConfigModel();
    @observable CurrentPage: number = 1;
    @observable LastModifiedBy: String | null = null;
    @observable PackageModified: String | null = null;
    @observable TotalPages: number = 1;
    @observable FrameworkMode: FrameworkModes = FrameworkModes.Create;

    @action
    loadCurrentPackage = (Package: PackagesGridViewModel) => {
        //this.CurrentPackage = this.Packages.filter(p => p.Id === Package.ID && p.Type === Package.PackageTrainingType)[0];
        return true; 
    }

    @action
    setNewPackage = (Package: PackageModel | null) => {
        this.CurrentPackage = Package;
    }

    @action
    getPackage = (PackageId: string) => {
        return this.Packages.find(p => p.Id === PackageId);
    }

    @action
    sortPackages = (compareFn?: ((a: PackageModel, b: PackageModel) => number) | undefined) => {
        this.Packages = this.Packages.slice().sort(compareFn);
    }
}