import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './ProviderDataGridComponent.css';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import React from 'react';
import ProviderDataGridViewModel from '../../models/ProviderDataGrid/ProviderDataGridViewModel'
import PaginationComponent from '../providers/PagintationComponent';
import ProviderDataStore from '../../stores/ProviderDataStore';
import { RouterStore } from 'mobx-react-router';
import ProviderDataConstants from '../../constants/ProviderDataConstants';
import DropdownComponent from '../common/inputs/DropdownComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import ProviderDataItem from './ProviderDataItem'
import ProviderDataModel from '../../models/ProviderDataDetails/ProviderDataModel'
import { PROVIDER_DATA_NAME_CY_DATA, PROVIDER_DATA_FILTER_CY_DATA, PROVIDER_DATA_GRID_CY_DATA, PROVIDER_DATA_GRID_PAGINATION_CY_DATA } from '../../cypressDataConstants/ProviderDataGridCyDataConstants';
import ProviderGridViewModel from '../../models/ProvidersGrid/ProviderGridViewModel';
import { CaughtException } from 'mobx/lib/internal';
import LargeTextboxComponent from '../common/inputs/LargeTextboxComponent';
import TextInputComponent from '../common/inputs/TextInputComponent';
import { Button } from 'aws-amplify-react/lib-esm/AmplifyTheme';
import HourInputComponent from '../../components/common/inputs/HourInputComponent'
import ProviderDataQueryParameter from "../../models/TestSubscription/ProviderDataQueryParameter";
import GraphQLHelper from '../../helpers/common/GraphQLHelper'
import { resultsAriaMessage } from 'react-select/src/accessibility';
import { _allowStateChangesInsideComputed } from 'mobx';
interface ProviderDataGridComponentProps {
    globalLoader?: GlobalLoaderStore,
    routing?: RouterStore,
    providerData: ProviderDataStore,
}

interface ProviderDataGridComponentState {
    providerId: string,
    ciid: string,
    hasError: boolean,
    textInputError: string,
    resultData: any
}

@inject('providerData', 'globalLoader', 'routing')
@observer
export default class ProviderDataGridComponent extends Component<ProviderDataGridComponentProps, ProviderDataGridComponentState> {
    constructor(props: ProviderDataGridComponentProps) {
        super(props);
        this.state = {
            providerId: "",
            ciid: "",
            hasError: false,
            textInputError: '',
            resultData: null
        }
    }

    componentDidMount() {
    }

    onProviderIdChange = (event: string) => {
        this.setState({ providerId: event })
    }
    onCiidChange = (event: string) => {
        this.setState({ ciid: event })
    }

    sortByErrorMessage  = (isDescending: boolean) => {
        this.props.providerData!.sortProviderData((a,b) => {return isDescending? (a.ErrorMessage! > b.ErrorMessage!? 1 : -1) : (a.ErrorMessage! < b.ErrorMessage!? 1 : -1)});
    }
    sortBySyncDate  = (isDescending: boolean) => {
        this.props.providerData!.sortProviderData((a,b) => {return isDescending? (a.SyncDate! > b.SyncDate!? 1 : -1) : (a.SyncDate! < b.SyncDate!? 1 : -1)});
    }
    sortByErrorType  = (isDescending: boolean) => {
        this.props.providerData!.sortProviderData((a,b) => {return isDescending? (a.ErrorType! > b.ErrorType!? 1 : -1) : (a.ErrorType! < b.ErrorType!? 1 : -1)});
    }
    sortBySuccessfulSync  = (isDescending: boolean) => {
        this.props.providerData!.sortProviderData((a,b) => {return isDescending? (a.LastSuccessfulSyncDate! > b.LastSuccessfulSyncDate!? 1 : -1) : (a.LastSuccessfulSyncDate! < b.LastSuccessfulSyncDate!? 1 : -1)});
    }
    sortByUrl  = (isDescending: boolean) => {
        this.props.providerData!.sortProviderData((a,b) => {return isDescending? (a.Url! > b.Url!? 1 : -1) : (a.Url! < b.Url!? 1 : -1)});
    }


    runQuery = async () => {
        if (!this.state.providerId) {
            alert("Invalid Query Value")
        }

        let query: any = {
            ProviderId: this.state.providerId,
            Ciid: this.state.ciid
        }

        this.props.globalLoader!.startLoading();
        await GraphQLHelper.FetchProviderStatesById(query.ProviderId, query.Ciid).then(data => {
            var valid = this.mapResultsToProviderDataGridViewModelObject(data);
            // console.log("list:", valid);
            this.setState({ resultData: valid })
        }).catch(err => {
            console.log("Error!", err)
        }).finally(()=>this.props.globalLoader!.finishLoading());


    }
    mapResultsToProviderDataGridViewModelObject = (data: any) => {
        this.props.providerData.ProviderData = data.data.providerDataList;
        this.props.providerData.TotalPages = Math.ceil(this.props.providerData.ProviderData.length / ProviderDataConstants.ITEMS_PER_PAGE_COUNT);
        var result: ProviderDataGridViewModel[] = []
        data.data.providerDataList.forEach(item => {
            result.push(new ProviderDataGridViewModel(item.LastSuccessfulSyncDate, item.ProviderID,
                item.StateType, item.TrainingType, item.SyncDate ,item.Url, item.ErrorMessage, item.ErrorType, item.TrainingsCount, item.CorrelationId))
        });
        return result;
    }
    
   
  
    handleOnChangePage = (newCurrentPage: number): Promise<any> => {
        return new Promise<any>((resolve) => {
            this.props.providerData!.CurrentPage = newCurrentPage;
            resolve(null);
        })
    }

    ResetCurrentSort = (idString: string) => {
        var elements = document.getElementsByClassName("sortable");
        while (elements.length)
            elements[0].classList.remove("sortable");

        var sortTarget = document.getElementById(idString);
        sortTarget?.classList.add("sortable");
    }

    renderGridHeaderItem = (title: string, onSort?: (isDescending: boolean) => void, textWidth: string) => {
        var colClassNames =  (textWidth == "xlarge") ? "xlargeTextWidth" : (textWidth == "large") ? "largeTextWidth" : ""
        if (!onSort )
        return   <th scope='col' className={colClassNames}>
            <div className="csod-grid-header-container">
                <div className = "csod-sorting-container">{title}</div>
            </div>
        </th>;
        return <th scope='col' className={colClassNames}>
            <div className="csod-grid-header-container">
                {title}
                <div className="csod-sorting-container">

                    <div id={title + 'up'} className="csod-sort-up " onClick={() => {
                        onSort(true);
                        this.ResetCurrentSort(title + 'up')
                    }}><div className="csod-icon-sort-up smallpin">▲</div></div>
                    <div id={title + 'down'} className="csod-sort-down" onClick={() => {
                        onSort(false)
                        this.ResetCurrentSort(title + 'down')
                    }}><div className="csod-icon-sort-down smallpin">▼</div></div>
                </div>
            </div>
        </th>
    }
    static getDerivedStateFromError(error: CaughtException) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    render() {
        if (this.props.globalLoader!.isLoading) {
            return null;
        }
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
      
        var ProviderDataList: ProviderDataModel[] = this.props.providerData?.ProviderData;

        var currentPage = this.props.providerData!.CurrentPage;
        var lastItemPerCurrentPageNumber = (this.props.providerData!.CurrentPage - 1) * ProviderDataConstants.ITEMS_PER_PAGE_COUNT + ProviderDataConstants.ITEMS_PER_PAGE_COUNT;
        if (lastItemPerCurrentPageNumber > this.props.providerData!.ProviderData!.length) {
            lastItemPerCurrentPageNumber = this.props.providerData!.ProviderData!.length;
        }

        return <div className="csod-provider-data-container">

            <div className="csod-search-container">
                <div className="csod-provider-data-count-title">Content Provider Data </div>
                <div className="csod-search-container-query">
                    <TextInputComponent title="Provider ID"
                            placeholder="Insert Provider ID"
                            widthClass={InputWidthTypes.Small}
                            important={false}
                            errorMessage={this.state.textInputError}
                            onValueChanged={(e) => this.onProviderIdChange(e)}
                        />

                    <TextInputComponent title="CIID (optional)"
                            placeholder="Insert CIID"
                            widthClass={InputWidthTypes.Small}
                            important={false}
                            errorMessage={this.state.textInputError}
                            onValueChanged={(e) => this.onCiidChange(e)}
                        />
                    <div className="csod-inputs-column-container">
                        <button type="button" className="csod-button less-rounded-corners blue query-set-width" onClick={async () => await this.runQuery()}>Query Provider Data</button>
                    </div>
                </div>
                
                {this.props.providerData.ProviderData.length === 0 ? null :
                    <div>
                        <table className="csod-provider-data-grid" cy-data={PROVIDER_DATA_GRID_CY_DATA}>
                            <thead>
                                <tr>
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_STATE_TYPE)} 
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_PROVIDER_ID)} 
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_TRAINING_TYPE)} 
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_SYNC_DATE,  this.sortBySyncDate)} 
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_URL,  this.sortByUrl, 'xlarge')} 
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_LAST_SUCCESSFUL_SYNC_DATE, this.sortBySuccessfulSync)} 
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_TRAININGS_COUNT)}  
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_CORRELATION_ID)} 
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_ERROR_TYPE,  this.sortByErrorType)} 
                                    {this.renderGridHeaderItem(ProviderDataConstants.PROVIDER_DATA_GRID_ERROR_MESSAGE, this.sortByErrorMessage, 'large')} 
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    console.log( "DATA: ",this.props.providerData.ProviderData)
                                }
                                
                                {                                        
                                    this.props.providerData.ProviderData.slice((this.props.providerData!.CurrentPage - 1) * ProviderDataConstants.ITEMS_PER_PAGE_COUNT, lastItemPerCurrentPageNumber)
                                        .map((item) => { return <ProviderDataItem ProviderData={item} RedirectToUpdate={() => { }} /> })
                                }
                            </tbody>
                        </table>
                        {this.props.providerData.ProviderData!.length <= ProviderDataConstants.ITEMS_PER_PAGE_COUNT ? null : <PaginationComponent currentPageIndex={currentPage} listPages={this.props.providerData!.TotalPages} handleChangePage={this.handleOnChangePage} cy_data={PROVIDER_DATA_GRID_PAGINATION_CY_DATA} />}

                    </div>}
            </div>
        </div>
    }
}