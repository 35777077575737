import { observable, action } from 'mobx';
import GraphQLHelper from '../helpers/common/GraphQLHelper';

import BasicAuthConfig from '../models/ProviderDetails/BasicAuthConfig';
import Oauth2Config from '../models/ProviderDetails/Oauth2Config';
import EcaGridViewModel from '../models/ExploreContentAnytimeModels/EcaBundleGridViewModel';
import SSOConfigModel from '../models/SSOConfig/SSOConfigModel';
import { TestSubscriptionOption } from '../models/TestSubscription/TestSubscriptionOption';
import EcaConstants from '../constants/ExploreContentAnytimeConstants';
import EcaModel from '../models/ExploreContentAnytimeModels/EcaBundleModel';


export default class EcaStore {
    @observable Bundles: EcaModel[] = observable([]);
    @observable CurrentBundle: EcaModel | null = null;
    @observable CurrentPage: number = 1;
    @observable LastModifiedBy: String | null = null;
    @observable TotalPages: number = 1;
    @observable SelectedBundle: String | null = null;


    @action
    loadBundles = async () => {
        let res = await GraphQLHelper.GetCuratedBundles();
        if (res != null) {            
            this.Bundles = res["data"].getBundles;
            this.TotalPages = Math.ceil(this.Bundles.length / EcaConstants.ITEMS_PER_PAGE_COUNT);
        }
    }

    updateSelectedBundle = (bId) =>{
        this.SelectedBundle = bId;
    }
    cleanBundleData = () => {
        this.CurrentBundle = null;
    }

    @action
    loadCurrentBundle = (bundle: EcaGridViewModel) => {
        
        this.CurrentBundle = this.Bundles.filter(b => b.Id === bundle.Id)[0];
    }

    @action
    setNewBundle = (bundle: EcaModel | null) => {
        this.CurrentBundle = bundle;
    }

    @action
    getBundle = (bundleId: string) => {
        return this.Bundles.find(b => b.Id === bundleId);
    }

    @action
    sortBundles = (compareFn?: ((a: EcaModel, b: EcaModel) => number) | undefined) => {
        this.Bundles = this.Bundles.slice().sort(compareFn);
    }
}