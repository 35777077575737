import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProviderStore from '../../stores/ProvidersStore';
import TestSubscription from './TestSubscription';
import { Link, Redirect } from 'react-router-dom';
import { PROVIDERS_PAGE_PATH } from '../../Settings';
import ProviderDetails from './ProviderDetails';
import SwitcherComponent from '../common/inputs/SwitcherComponent';
import PopupComponent, { ErrorPopupComponent, ErrorPopupWithGridComponent, GenericSuccessPopupComponent, ErrorPopupWithTextAreaComponent, PhaseCondition, SuccessPopupComponent, ValidationSuccessPopupComponent, WarningPopupComponent, FullSyncWarningPopupComponent } from '../common/popups/PopupComponent';
import ProviderDetailsErrorModel from '../../models/ProviderDetails/ProviderDetailsErrorModel';
import SSOConfiguration from './SSOConfiguration';
import ProviderDetailsExpansion, { SubjectMapping ,CourseMapping} from '../../models/ProviderDetails/ProviderDetailsExpansion';
import ProviderModel, { CSOD_PREDEFINED_SUBJECTS_MAPPING, CSOD_PREDEFINED_COURSE_MAPPING } from '../../models/ProviderDetails/ProviderModel';
import SSOConfigErrorModel from '../../models/SSOConfig/SSOConfigErrorModel';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import LargeTextboxComponent from '../common/inputs/LargeTextboxComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import FrameworkValidationService from '../../helpers/framework/FrameworkValidationService';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import UpsertProviderModel from '../../models/ProviderDetails/UpsertProviderModel';
import LoaderComponent from '../common/LoaderComponent';
import "./FrameworkComponent.css";
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import ProviderToolData from '../../models/TestSubscription/ProviderToolData';
import HttpHelper from '../../helpers/common/HttpHelper';
import { ProviderOfferingType } from '../../models/ProviderDetails/ProviderOfferingType';
import DirectSubscriptionInput from '../../models/TestSubscription/DirectSubscriptionInput';
import { TestSubscriptionOption } from '../../models/TestSubscription/TestSubscriptionOption';
import SubscriptionInput from '../../models/TestSubscription/SubscriptionInput';
import ValidationApiErrorModel from '../../models/ProviderDetails/ValidationApiErrorModel';
import BundleProviderParameter from '../../models/TestSubscription/BundleProviderParameter';
import { v4 } from 'uuid';
import { CANCEL_BUTTON_CY_DATA, CANCEL_POPUP_CY_DATA, ERROR_POPUP_CY_DATA, PROVIDER_DETAILS_TAB_CY_DATA, SSO_CONFIGURATION_TAB_CY_DATA, SUBMIT_BUTTON_CY_DATA, SUCCESS_POPUP_CY_DATA, TEST_SUBSCRIPTIONS_TAB_CY_DATA } from '../../cypressDataConstants/providerFrameworkCyDataConstants';
import { PROVIDER_CUSTOM_TAXONOMY_POPUP_CY_DATA ,PROVIDER_CUSTOM_COURSE_MAPPING_POPUP_CY_DATA} from '../../cypressDataConstants/providerDetailsCyDataConstants';
import FrameworkConstants from '../../constants/frameworkConstants';
import { TrainingType } from '../../models/ProviderDetails/TrainingType';
import { Auth } from '@aws-amplify/auth';
import ProviderConstants from '../../constants/providersconstants';
import ProviderDetailConstants from '../../constants/providerDetailsConstants';
const { REACT_APP_ENV } = process.env;
console.log(process.env);
enum TabNames {
    ProviderDetails = "Provider Details",
    // TestSubscription = "Test Subscription",
    SSOConfiguration = "SSO Configuration"
}

export enum PopupTypes {
    None,
    Success,
    Error,
    SavingError,
    Warning,
    FullSyncWarning,
    TaxonomyEdit,
    CourseMappingEdit,
    TestEndpointErrorWithGrid,
    TestEndpointErrorWithTextArea,
    TestEndpointSuccess,
    SyncSuccess,
    SyncFailure,
    MissingCIID
}

interface FrameworkComponentProps {
    providers?: ProviderStore,
    globalLoader?: GlobalLoaderStore
}

interface FrameworkComponentState {
    CurrentTab: TabNames,
    CurrentPopup: PopupTypes,
    ProviderDetailsErrorMessages: ProviderDetailsErrorModel | null,
    SSOConfigErrorMessages: SSOConfigErrorModel | null,
    TestSubscriptionErrorMessages: { [id: number]: string } | null,
    AdditionalSettingsErrorMessages: { [id: number]: string } | null,
    QuerystringParametersErrorMessages: { [id: number]: string } | null,
    IsRedirectingToProvidersGrid: boolean,
    ErrorPopupMessages: string[] | ValidationApiErrorModel[],
    ReadonlyState: boolean,
    IsSubmitRequestPosting: boolean,
    IsTestEnpointRequestPosting: boolean,
    S3Url: string,
    taxonomyErrorMessage: string,
    courseMappingErrorMessage: string,
    ShowForceSync: boolean,
    IsForceSyncRequestPosting: boolean,
    isScrolledToProblemElement: boolean,
    coursesCount?: number,
}



@inject("providers", "globalLoader")
@observer
export default class FrameworkComponent extends Component<FrameworkComponentProps, FrameworkComponentState> {
    private taxonomyValue: string = "";
    private courseMappingValue: string = "";
    private phaseConditions: PhaseCondition[] = [];

    constructor(props: FrameworkComponentProps) {
        super(props);
        this.state = {
            CurrentTab: TabNames.ProviderDetails,
            CurrentPopup: PopupTypes.None,
            IsRedirectingToProvidersGrid: false,
            ProviderDetailsErrorMessages: new ProviderDetailsErrorModel(),
            SSOConfigErrorMessages: new SSOConfigErrorModel(),
            TestSubscriptionErrorMessages: {},
            AdditionalSettingsErrorMessages: {},
            QuerystringParametersErrorMessages: {},
            ReadonlyState: false,
            S3Url: "",
            ErrorPopupMessages: [],
            IsSubmitRequestPosting: false,
            IsForceSyncRequestPosting: false,
            ShowForceSync: true,
            IsTestEnpointRequestPosting: false,
            taxonomyErrorMessage: "",
            courseMappingErrorMessage: "",
            isScrolledToProblemElement: true
        }
        if (this.props.providers?.CurrentProviderDetailsExpansion.SubjectMappingType === SubjectMapping.Custom) {
            this.taxonomyValue = this.props.providers.CurrentProvider!.SubjectsMapping;
        }
        if (this.props.providers?.CurrentProviderDetailsExpansion.CourseMappingType === CourseMapping.Custom) {
            this.courseMappingValue = this.props.providers.CurrentProvider!.CourseMapping;
        }
        Auth.currentAuthenticatedUser().then(res=>{
            var groups = res.signInUserSession.accessToken.payload["cognito:groups"];
            if(groups && groups.includes('Readonly') && !groups.includes('Admins'))
              this.setState({ReadonlyState: true});
            });
    }

    getCcmsBundleId = (env: string | undefined) => {
        switch (env) {
            case "prod":
                return FrameworkConstants.CCMS_MEGA_BUNDLE_ID_prod;
            case "stge":
                return FrameworkConstants.CCMS_MEGA_BUNDLE_ID_stge;
        }
        return FrameworkConstants.CCMS_MEGA_BUNDLE_ID_dev;

    }

    onScrolledToProblemElement = () => {
        this.setState({ isScrolledToProblemElement: true });
    }

    getGoToTabFunction = (tab: TabNames) => {
        return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();
            if (this.state.CurrentTab !== tab) {
                this.setState({ CurrentTab: tab });
            }
        }
    }

    SyncCCMSBundle = () => {
        // alert("This should call the updateCCMSBundleForProvider backend -- not yet implemented")
    }
    renderTab(tab: TabNames, cy_data?: string) {
        return <div className={`csod-tab-item non-selectable ${this.state.CurrentTab === tab ? "active-item gray-text" : "blue-text"}`} onClick={this.getGoToTabFunction(tab)} cy-data={cy_data}>{tab}</div>
    }

    setForceSync = () => {
        this.props.providers!.CurrentProvider!.ForceSync = true;
        this.closePopup();
    }
    revertForceSync = () => {
        this.props.providers!.CurrentProvider!.ForceSync = false;
        this.closePopup();
    }

    onIsForceSyncChanged = (fullSync: boolean) => {
        if (fullSync) {
            this.setState({ CurrentPopup: PopupTypes.FullSyncWarning })
        }
        else {
            this.props.providers!.CurrentProvider!.ForceSync = fullSync
        }
    }

    resetPSSOConfigErrorModel = () => {//for is sso enabled
        this.setState({ SSOConfigErrorMessages: new SSOConfigErrorModel() });
    }

    subscribeCall = (): Promise<any> => {
        var callsSequense: Promise<any>[] = [];
        if (this.props.providers?.CurrentProvider?.ProviderOfferingType === ProviderOfferingType.OneToOne || this.props.providers?.CurrentProvider?.ProviderOfferingType === ProviderOfferingType.Both) {
            [
                TestSubscriptionOption.TestPortal1,
                TestSubscriptionOption.TestPortal2
            ].forEach(tso => {
                if (this.props.providers?.CurrentTestSubscriptionConfig[tso].isChecked) {
                    callsSequense.push(GraphQLHelper.DirectSubscribeGQCall(new DirectSubscriptionInput(this.props.providers.CurrentProvider!.Id!, this.props.providers?.CurrentTestSubscriptionConfig[tso].url, this.props.providers?.CurrentTestSubscriptionConfig[tso].CIID)));
                }
            })
        }
        var oneToManySubscribed = false;
        if (this.props.providers?.CurrentProvider?.ProviderOfferingType === ProviderOfferingType.OneToMany || this.props.providers?.CurrentProvider?.ProviderOfferingType === ProviderOfferingType.Both) {
            [
                TestSubscriptionOption.CSODTestPortal,
                TestSubscriptionOption.SabaTestPortal
            ].forEach(tso => {
                if (this.props.providers?.CurrentTestSubscriptionConfig[tso].isChecked) {
                    oneToManySubscribed = true;
                    callsSequense.push(GraphQLHelper.SubscribeGQCall(new SubscriptionInput(this.props.providers?.CurrentTestSubscriptionConfig[tso].url, FrameworkConstants.SUBSCRIPTION_BUNDLE_ID)));
                }
            })
            if (oneToManySubscribed) {
                callsSequense.push(GraphQLHelper.AddProviderGQCall(new BundleProviderParameter(FrameworkConstants.SUBSCRIPTION_BUNDLE_ID, this.props.providers.CurrentProvider.Id!)));
            }
        }
        return Promise.all(callsSequense).then(responses => {
            this.phaseConditions.push({ phaseName: FrameworkConstants.SUBSCRIBE_PHASE_STRING, isPhasePassedSuccessfully: true });
        });
    }

    addProviderToMegabundleCall = () => {
        var ccmsBundleId = this.getCcmsBundleId(REACT_APP_ENV);
        if (this.props.providers?.CurrentProvider?.IsStrictSchema && this.props.providers.CurrentProviderDetailsExpansion.IsForCuration) {
            return GraphQLHelper.AddProviderGQCall(new BundleProviderParameter(ccmsBundleId, this.props.providers.CurrentProvider.Id!)).then(res => {
                this.phaseConditions.push({ phaseName: FrameworkConstants.ADD_TO_MEGABUNDLE_PHASE_STRING, isPhasePassedSuccessfully: true })
            })
        }
        return Promise.resolve();
    }

    upsertSettingsCall = () => {
        return GraphQLHelper.UpsertSettingsGQCall(ProviderToolData.ComposeProviderToolData(this.props.providers?.CurrentProvider!, this.props.providers?.CurrentProviderDetailsExpansion!, this.props.providers?.CurrentTestSubscriptionConfig!));
    }

    onForceSync = () => {
        this.setState({ IsForceSyncRequestPosting: true })
        var ciidString = this.props.providers?.CurrentProviderDetailsExpansion?.SelectedCIID.Ciid ?? this.props.providers?.CurrentProviderDetailsExpansion?.SelectedCIID;
        if (this.props.providers?.CurrentProvider?.ProviderOfferingType == ProviderOfferingType.OneToOne && (!ciidString || ciidString == "no ciid")) {
            this.setState({ CurrentPopup: PopupTypes.MissingCIID, IsForceSyncRequestPosting: false });
            return;
        }

        if (!ciidString)
            ciidString = "NonApplicableCiidString";
        ciidString = ciidString.replace(ProviderDetailConstants.CIID_CUSTOM_STRING,'');
        
        GraphQLHelper.ForceProviderSyncGQCall(this.props.providers?.CurrentProvider!, ciidString).then(s => {
            this.setState({ CurrentPopup: PopupTypes.SyncSuccess, IsForceSyncRequestPosting: false });
        })
            .catch((err) => {
                this.setState({
                    CurrentPopup: PopupTypes.SyncFailure,
                    ErrorPopupMessages: err && err.errors ? err.errors.map((i: any) => i.message) : [],
                    IsForceSyncRequestPosting: false
                });
            }).finally(() => {
                if (this.state.IsForceSyncRequestPosting) {
                    this.setState({ IsForceSyncRequestPosting: false })
                }
            })
    }

    finishSubmitProcess = (renderPopup: PopupTypes, errors?: any) => {
        this.setState({
            ErrorPopupMessages: errors && errors.errors ? errors.errors.map((i: any) => i.message) : [],
            CurrentPopup: renderPopup,
            IsSubmitRequestPosting: false
        })
    }

    onSubmitClick = () => {
        this.setState({ IsSubmitRequestPosting: true });
        var providerDetailsErrorMessages = FrameworkValidationService.ProviderDetailsValidate(this.props.providers?.CurrentProvider, this.props.providers?.CurrentProviderDetailsExpansion, this.props.providers?.CurrentBasicAuthConfig, this.props.providers?.CurrentOAuth2Config, this.props.providers?.Providers, this.props.providers?.FrameworkMode!);
        var ssoConfigurationErrorMessages = FrameworkValidationService.SSOConfigurationValidatate(this.props.providers?.CurrentSSOConfigModel);
        var testSubscriptionErrorMessages = FrameworkValidationService.TestSubscriptionValidate(this.props.providers?.CurrentTestSubscriptionConfig);
        if (providerDetailsErrorMessages != null || ssoConfigurationErrorMessages != null || testSubscriptionErrorMessages != null) {
            var tabToMove = providerDetailsErrorMessages != null ? TabNames.ProviderDetails : ssoConfigurationErrorMessages != null ? TabNames.SSOConfiguration : testSubscriptionErrorMessages != null ? TabNames.TestSubscription : this.state.CurrentTab;
            this.setState({
                isScrolledToProblemElement: false,
                ProviderDetailsErrorMessages: providerDetailsErrorMessages,
                SSOConfigErrorMessages: ssoConfigurationErrorMessages,
                TestSubscriptionErrorMessages: testSubscriptionErrorMessages,
                CurrentTab: tabToMove,
                IsSubmitRequestPosting: false
            })
        }
        else {
            GraphQLHelper.UpsertProviderGQCall(UpsertProviderModel.ComposeUpsertProviderModel(this.props.providers?.CurrentProvider!, this.props.providers?.CurrentOAuth2Config!, this.props.providers?.CurrentApiKeyConfig!, this.props.providers?.CurrentBasicAuthConfig!, this.props.providers?.CurrentProviderDetailsExpansion!, this.props.providers?.CurrentSSOConfigModel!, Auth.Credentials.Auth.user.attributes.email, new Date().toLocaleString()))
                .then(() => {
                    this.phaseConditions.push({ phaseName: FrameworkConstants.UPSERT_PROVIDER_PHASE_STRING, isPhasePassedSuccessfully: true })
                    this.upsertSettingsCall().then(() => {
                        this.phaseConditions.push({ phaseName: FrameworkConstants.SETTINGS_PHASE_STRING, isPhasePassedSuccessfully: true })
                        // this.subscribeCall().then(() => {
                        this.addProviderToMegabundleCall().then(() => {
                            if(this.props.providers?.Providers.map(x=>x.Id).indexOf(this.props.providers?.CurrentProvider.Id) == -1) 
                                this.props.providers?.Providers.push(this.props.providers?.CurrentProvider);
                            this.finishSubmitProcess(PopupTypes.Success);
                        }).catch(err => {
                            this.phaseConditions.push({ phaseName: FrameworkConstants.ADD_TO_MEGABUNDLE_PHASE_STRING, isPhasePassedSuccessfully: false })
                            this.finishSubmitProcess(PopupTypes.Error, err);
                        })
                        // }).catch(err => {
                        //     this.phaseConditions.push({ phaseName: FrameworkConstants.SUBSCRIBE_PHASE_STRING, isPhasePassedSuccessfully: false })
                        //     this.finishSubmitProcess(PopupTypes.Error, err);
                        // });
                    }).catch(err => {
                        this.phaseConditions.push({ phaseName: FrameworkConstants.SETTINGS_PHASE_STRING, isPhasePassedSuccessfully: false })
                        this.finishSubmitProcess(PopupTypes.Error, err);
                    });
                }).catch(err => {
                    this.finishSubmitProcess(PopupTypes.SavingError, err);
                })
        }
    }

    closePopup = () => {
        this.setState({ CurrentPopup: PopupTypes.None })
        this.phaseConditions = [];
    }

    onS3OpenPopup = () => {
        if (this.state.S3Url)
            window.open(this.state.S3Url, 'popUpWindow');

        else if (this.state.ErrorPopupMessages[this.state.ErrorPopupMessages.length - 1]) {
            var s3Url = this.state.ErrorPopupMessages[this.state.ErrorPopupMessages.length - 1] as string;
            if (s3Url.includes('S3 Url:'))
                window.open(JSON.parse(s3Url).replace('S3 Url: ', ''), 'popUpWindow');
            else
                window.open(JSON.parse(this.state.ErrorPopupMessages[this.state.ErrorPopupMessages.length - 1].toString()).s3, 'popUpWindow');
        }
    }

    redirectToProvidersGrid = () => {
        this.props.providers!.CurrentProviderDetailsExpansion = new ProviderDetailsExpansion();
        this.props.providers!.CurrentProvider = new ProviderModel();
        this.setState({ IsRedirectingToProvidersGrid: true })
    }

    onCancelClick = () => {
        this.setState({ CurrentPopup: PopupTypes.Warning })
    }

    onSubmitTaxonomy = () => {
        try {
            if (CodeHelpers.IsJSONString(this.taxonomyValue)) {
                this.props.providers!.CurrentProviderDetailsExpansion.CustomTaxonomy = this.taxonomyValue;
                this.setState({ taxonomyErrorMessage: "", CurrentPopup: PopupTypes.None })
            } else {
                this.setState({ taxonomyErrorMessage: FrameworkConstants.TAXONOMY_ERROR_MESSAGE })
            }
        } catch {
            this.setState({ taxonomyErrorMessage: FrameworkConstants.TAXONOMY_ERROR_MESSAGE })
        }
    }

    onSubmitCourseMapping = () => {
        try {
            if (CodeHelpers.IsJSONString(this.courseMappingValue)) {
                this.props.providers!.CurrentProviderDetailsExpansion.CustomCourseMapping = this.courseMappingValue;
                    this.setState({ courseMappingErrorMessage: "", CurrentPopup: PopupTypes.None })
            } else {
                this.setState({ courseMappingErrorMessage: FrameworkConstants.TAXONOMY_ERROR_MESSAGE })
            }
        } catch(err) {
            this.setState({ courseMappingErrorMessage: FrameworkConstants.TAXONOMY_ERROR_MESSAGE })
        }
    }


    onTaxonomyChanged = (value: string) => {
        this.taxonomyValue = value;
        this.props.providers!.CurrentProvider!.SubjectsMapping = value
    }
    onCourseMappingChanged = (value: string) => {
        this.courseMappingValue = value;
        this.props.providers!.CurrentProvider!.CourseMapping = value
    }

    
    onTestEndpoint = () => {
        this.setState({ IsTestEnpointRequestPosting: true });
        var subjectsMappingParsed = CodeHelpers.IsStringNullOrEmpty(this.props.providers?.CurrentProvider?.SubjectsMapping) ? {} : JSON.parse(this.props.providers!.CurrentProvider!.SubjectsMapping!);
        var clientIdAndSecretKey = UpsertProviderModel.setupClientIdAndSecretKey(this.props.providers?.CurrentBasicAuthConfig!, this.props.providers?.CurrentOAuth2Config!, this.props.providers?.CurrentApiKeyConfig!, this.props.providers?.CurrentProvider?.AuthenticationType)
        var ciidString = this.props.providers?.CurrentProviderDetailsExpansion?.SelectedCIID.Ciid ?? this.props.providers?.CurrentProviderDetailsExpansion?.SelectedCIID;
        var lastModifiedString = this.props.providers?.CurrentProvider?.LastSuccessfulModifiedDateCourse;
        if (!lastModifiedString)
            lastModifiedString = new Date().toISOString();
        if (ciidString && this.props.providers?.CurrentProvider?.ProviderOfferingType == ProviderOfferingType.OneToOne)
            ciidString = "?ciid=" + ciidString;
        else {
            ciidString = "";
        }
        ciidString = ciidString.replace( ProviderDetailConstants.CIID_CUSTOM_STRING,'');
        HttpHelper.SendPostRequest(FrameworkConstants.VALIDATION_API_URL, {
            Abbreviation: this.props.providers?.CurrentProvider?.Abbreviation,
            Audience: this.props.providers?.CurrentProvider?.Audience,
            AuthenticationType: this.props.providers?.CurrentProvider?.AuthenticationType,
            ClientId: clientIdAndSecretKey.Username,
            ClientSecretKey: clientIdAndSecretKey.Password,
            AuthenticationUrl: clientIdAndSecretKey.AuthUrl,
            Scope: this.props.providers?.CurrentProvider?.Scope,
            CourseSyncUrl: this.props.providers?.CurrentProvider?.CourseSyncUrl + ciidString,
            Id: this.props.providers?.CurrentProvider?.Id,
            IsExcelProvider: this.props.providers?.CurrentProviderDetailsExpansion.IsExcelProvider,
            IsMobileSupported: this.props.providers?.CurrentProvider?.IsMobileSupported,
            IsActive: this.props.providers?.CurrentProvider?.IsActive ?? true,
            IsStrictSchema: this.props.providers?.CurrentProvider?.IsStrictSchema,
            LastSuccessfulModifiedDateCourse: lastModifiedString,
            Name: this.props.providers?.CurrentProvider?.Name,
            ProviderOfferingType: this.props.providers?.CurrentProvider?.ProviderOfferingType,
            SubjectsMapping: subjectsMappingParsed,
            SyncTime: this.props.providers?.CurrentProvider?.SyncTime,
            IsCCC: this.props.providers?.CurrentProvider?.IsCCC,
            Type: TrainingType[this.props.providers?.CurrentProvider?.Type!]
        }).then(res => {
            if (res.status == 304) {
                this.setState({ CurrentPopup: PopupTypes.TestEndpointSuccess, coursesCount: 0, IsTestEnpointRequestPosting: false });
                return;
            }
            var messages = res as { ID: string, error: string, childErrors: [] }[];
            var s3Url = res.S3Url;
            var coursesCount = res.IDs ;
            if (coursesCount > 0) {//message has success response structure
                this.setState({ S3Url: s3Url });
                this.setState({ CurrentPopup: PopupTypes.TestEndpointSuccess, coursesCount: coursesCount, IsTestEnpointRequestPosting: false });
            } else if (messages[0].error) {//message has simple error response structure
                this.setState({ CurrentPopup: PopupTypes.TestEndpointErrorWithTextArea, ErrorPopupMessages: messages.map(i => i.error ? i.error : ""), IsTestEnpointRequestPosting: false });
            } else {//message has array of errors response structure
                // if(res[0]["error:"])
                //     messages = [res[0]["error:"]];
                if (messages.map != null)
                    this.setState({ CurrentPopup: PopupTypes.TestEndpointErrorWithTextArea, ErrorPopupMessages: messages.map(i => (JSON.stringify(i))), IsTestEnpointRequestPosting: false });
                else
                    this.setState({ CurrentPopup: PopupTypes.TestEndpointErrorWithTextArea, ErrorPopupMessages: messages, IsTestEnpointRequestPosting: false });
            }
        })
            .catch((err) => {
                this.setState({ CurrentPopup: PopupTypes.TestEndpointErrorWithTextArea, ErrorPopupMessages: [err.message], IsTestEnpointRequestPosting: false });
            })
            .finally(() => {
                if (this.state.IsTestEnpointRequestPosting) {
                    this.setState({ IsTestEnpointRequestPosting: false })
                }
            });
    }

    renderPopup = () => {
        switch (this.state.CurrentPopup) {
            case PopupTypes.Success: return <SuccessPopupComponent FrameworkMode={this.props.providers?.FrameworkMode} ProviderName={this.props.providers?.CurrentProvider?.Name} OnClose={this.redirectToProvidersGrid} OnDone={this.redirectToProvidersGrid} cy_data={SUCCESS_POPUP_CY_DATA} />
            case PopupTypes.Error: return <ErrorPopupComponent ErrorMessages={this.state.ErrorPopupMessages as string[]} PhasesConditions={this.phaseConditions} OnClose={this.closePopup} TitleElements={["Provider ", <span key={v4()} className="blue-text">{this.props.providers?.CurrentProvider?.Name}</span>, " was saved, but the following errors occured"]} cy_data={ERROR_POPUP_CY_DATA} />
            case PopupTypes.SavingError: return <ErrorPopupComponent ErrorMessages={this.state.ErrorPopupMessages as string[]} OnClose={this.closePopup} TitleElements={["Failed to save provider ", <span className="blue-text">{this.props.providers?.CurrentProvider?.Name}</span>]} cy_data={ERROR_POPUP_CY_DATA} />
            case PopupTypes.Warning: return <WarningPopupComponent OnClose={this.closePopup} OnDone={this.redirectToProvidersGrid} cy_data={CANCEL_POPUP_CY_DATA} />
            case PopupTypes.FullSyncWarning: return <FullSyncWarningPopupComponent OnClose={this.revertForceSync} OnDone={this.setForceSync} />
            case PopupTypes.TaxonomyEdit: return <PopupComponent OnClose={this.closePopup} Title="Enter JSON subject taxonomy" AdditionalClass={"alternative-padding"} ErrorMessage={""} ButtonsProps={[
                { ButtonText: "Cancel", OnClick: this.closePopup, AdditionalClassForButton: "gray" },
                { ButtonText: "Submit", OnClick: this.onSubmitTaxonomy, AdditionalClassForButton: "blue" }
            ]}
                cy_data={PROVIDER_CUSTOM_TAXONOMY_POPUP_CY_DATA}>
                <LargeTextboxComponent title="" errorMessage={this.state.taxonomyErrorMessage} additionalClass="min-w-min-h-300-600" important={false} widthClass={InputWidthTypes.Maximum} defaultValue={this.props.providers?.CurrentProviderDetailsExpansion?.CustomTaxonomy} placeholder={CSOD_PREDEFINED_SUBJECTS_MAPPING} onValueChanged={this.onTaxonomyChanged} />
                <div className="csod-input-title-text gray-text padding-td-10">Paste the information in order to update custom taxonomy</div>
            </PopupComponent>
            case PopupTypes.CourseMappingEdit: return <PopupComponent OnClose={this.closePopup} Title="Enter JSON Course Mapping" AdditionalClass={"alternative-padding"} ErrorMessage={""} ButtonsProps={[
                { ButtonText: "Cancel", OnClick: this.closePopup, AdditionalClassForButton: "gray" },
                { ButtonText: "Submit", OnClick: this.onSubmitCourseMapping, AdditionalClassForButton: "blue" }
            ]}
                cy_data={PROVIDER_CUSTOM_COURSE_MAPPING_POPUP_CY_DATA}>
                <LargeTextboxComponent title="" errorMessage={this.state.courseMappingErrorMessage} additionalClass="min-w-min-h-300-600" important={false} widthClass={InputWidthTypes.Maximum} defaultValue={this.props.providers?.CurrentProviderDetailsExpansion?.CustomCourseMapping} placeholder={CSOD_PREDEFINED_COURSE_MAPPING} onValueChanged={this.onCourseMappingChanged} />
                <div className="csod-input-title-text gray-text padding-td-10">Paste the information in order to update course mapping</div>
            </PopupComponent>
            case PopupTypes.TestEndpointErrorWithGrid: return <ErrorPopupWithGridComponent ErrorDescriptionMessage={"Failed validation, due to the following reason"} ErrorMessages={this.state.ErrorPopupMessages as ValidationApiErrorModel[]} OnClose={this.closePopup} />
            case PopupTypes.TestEndpointErrorWithTextArea: return <ErrorPopupWithTextAreaComponent ErrorDescriptionMessage={"Failed validation, due to the following reason (Possibly no courses were returned)"} ErrorMessages={this.state.ErrorPopupMessages as string[]} OnClose={this.closePopup} OnS3Open={this.onS3OpenPopup} IsSync={false} />
            case PopupTypes.TestEndpointSuccess: return <ValidationSuccessPopupComponent CoursesCount={this.state.coursesCount!} OnClose={this.closePopup} OnDone={this.closePopup} OnS3Open={this.onS3OpenPopup} />
            case PopupTypes.SyncSuccess: return <GenericSuccessPopupComponent OnClose={this.closePopup} Message={"Provider has synced successfully"} OnDone={this.closePopup} showIcon={true} />
            case PopupTypes.MissingCIID: return <ErrorPopupComponent ErrorMessages={[("Please pick a CIID you want to sync from the drop down")]} OnClose={this.closePopup} />
            case PopupTypes.SyncFailure: return <ErrorPopupWithTextAreaComponent ErrorDescriptionMessage={"Failed syncing provider, due to the following reason"} ErrorMessages={this.state.ErrorPopupMessages as string[]} OnClose={this.closePopup} OnS3Open={this.closePopup} IsSync={true} />
            
            default: return null;
        }
    }

    renderReadonlyPosts= () => {
        if(!this.state.ReadonlyState)
        return <div className="csod-inputs-container">
                    <div className="csod-inputs-row-container">
                        {this.state.CurrentTab === TabNames.ProviderDetails ? <button className="csod-button gray less-rounded-corners" onClick={this.onTestEndpoint}>{this.state.IsTestEnpointRequestPosting ? <LoaderComponent isLoading={true} /> : "Test Endpoint"}</button> : <div />}
                        {this.state.CurrentTab === TabNames.ProviderDetails && this.state.ShowForceSync ? <button className="csod-button gray less-rounded-corners" onClick={this.onForceSync}>{this.state.IsForceSyncRequestPosting ? <LoaderComponent isLoading={true} /> : "Sync Now"}</button> : <div />}

                        {this.state.CurrentTab === TabNames.ProviderDetails && this.state.ShowForceSync ?
                            <SwitcherComponent additionalClass="forceFullSyncCheckBox" title="Full Sync" defaultValue={this.props.providers?.CurrentProvider?.ForceSync} onValueChanged={this.onIsForceSyncChanged} /> : <div />}
                    </div>
                    <div className="csod-inputs-row-container">
                        <button className="csod-button crimson less-rounded-corners" onClick={this.onCancelClick} cy-data={CANCEL_BUTTON_CY_DATA}>Cancel</button>
                        <button type="submit" className="csod-button blue less-rounded-corners" onClick={this.onSubmitClick} cy-data={SUBMIT_BUTTON_CY_DATA}>{this.state.IsSubmitRequestPosting ? <LoaderComponent isLoading={true} /> : "Submit"}</button>
                    </div>
               </div>
        else
        return <div className="csod-inputs-container">
                    <div className="csod-inputs-row-container">
                        {this.state.CurrentTab === TabNames.ProviderDetails ? <button className="csod-button gray less-rounded-corners" onClick={this.onTestEndpoint}>{this.state.IsTestEnpointRequestPosting ? <LoaderComponent isLoading={true} /> : "Test Endpoint"}</button> : <div />}
                    </div>
               </div>
    }
    render() {
        const providers = this.props.providers;
        return this.props.globalLoader?.isLoading ? null :
            this.state.IsRedirectingToProvidersGrid || providers?.CurrentProvider == null ? <Redirect to={PROVIDERS_PAGE_PATH} /> : <div className="csod-framework-container">
                {this.renderPopup()}
                <div className="csod-breadcrumb-container">
                    <Link to={PROVIDERS_PAGE_PATH} className="csod-breadcrumb-item non-selectable blue-text">Content Providers</Link>
                    <div className="non-selectable">/</div>
                    <div className="csod-breadcrumb-item non-selectable gray-text">{this.props.providers?.FrameworkMode === FrameworkModes.Create ? "New Provider" : "Edit Provider"}</div>
                </div>
                <div className="csod-current-tab-title non-selectable">{`${this.props.providers?.FrameworkMode === FrameworkModes.Create ? "Create" : "Edit"} ${this.state.CurrentTab}`}</div>
                <div className="csod-tabs-container">
                    {this.renderTab(TabNames.ProviderDetails, PROVIDER_DETAILS_TAB_CY_DATA)}
                    {this.renderTab(TabNames.TestSubscription, TEST_SUBSCRIPTIONS_TAB_CY_DATA)}
                    {this.renderTab(TabNames.SSOConfiguration, SSO_CONFIGURATION_TAB_CY_DATA)}
                </div>
                <div className="csod-framework-content">
                    {this.state.CurrentTab === TabNames.ProviderDetails ? <ProviderDetails providerDetailsErrorMessages={this.state.ProviderDetailsErrorMessages!} onScrolledToProblemElement={this.onScrolledToProblemElement} isScrolledToProblemElement={this.state.isScrolledToProblemElement} callTaxonomyPopup={() => { this.setState({ CurrentPopup: PopupTypes.TaxonomyEdit }) }} callCustomCourseMappingPopup={()=>{this.setState({ CurrentPopup: PopupTypes.CourseMappingEdit })}} /> : null}
                    {this.state.CurrentTab === TabNames.TestSubscription ? <TestSubscription testSubscriptionErrorMessages={this.state.TestSubscriptionErrorMessages!} onScrolledToProblemElement={this.onScrolledToProblemElement} isScrolledToProblemElement={this.state.isScrolledToProblemElement} /> : null}
                    {this.state.CurrentTab === TabNames.SSOConfiguration ? <SSOConfiguration ssoConfigErrorMessages={this.state.SSOConfigErrorMessages!} resetErrors={this.resetPSSOConfigErrorModel} onScrolledToProblemElement={this.onScrolledToProblemElement} isScrolledToProblemElement={this.state.isScrolledToProblemElement} /> : null}
                </div>
                <div className="csod-inputs-sticky-container">
                    {this.renderReadonlyPosts()}
                </div>
            </div>
    }
}