export default class FrameworkHelpers {
    public static goToProblemElement = (isScrolledToProblemElement: boolean, onScrolledToProblemElement: () => void) => {
        if(!isScrolledToProblemElement) {
            var hasErrorElements = document.getElementsByClassName("has-error");
            if(hasErrorElements.length > 0) {
                var elementToScroll = hasErrorElements[0] as HTMLElement;
                if(elementToScroll) {
                    elementToScroll?.scrollIntoView({block: "center", behavior: "smooth"});
                    setTimeout(() => {
                        elementToScroll?.focus();
                        onScrolledToProblemElement();
                    }, 500);
                }
            }
        }
    }
}