import React, { Component } from 'react';
import CodeHelpers from '../../../helpers/common/CodeHelpers';
import { InputWidthTypes } from '../../../helpers/common/StyleHelper';
import "./TextInputComponent.css";

interface TextInputComponentState {
    innerErrorMessage: string,
    isCopied:boolean
}

interface TextInputComponentProps {
    title: string,
    additionalClass?: string,
    placeholder?: string,
    important: boolean,
    defaultValue?: string,
    widthClass: InputWidthTypes,
    id?: string,
    type?: string
    removeElement?: boolean,
    errorMessage?: string,
    maxlength?: number,
    multiple?: boolean,
    isHeaderHidden?: boolean,
    step?: number,
    description?: string,
    regex?: RegExp,
    example?: string,
    cy_data?: string,
    isAbleToCopy: boolean,
    value: string

    onValueChanged: (value: string) => void
}

export default class TextInputComponent extends Component<TextInputComponentProps, TextInputComponentState> {

    constructor(props: TextInputComponentProps) {
        super(props);
        this.state = {innerErrorMessage: "", isCopied:false}
    }

    onTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(Date.parse(event.target.value)){
            this.props.onValueChanged(event.target.value.trim());
        }

        if(event.target.checkValidity()) {
            if(this.props.regex != null && !this.props.regex.test(event.target.value.trim())) {
                this.setState({innerErrorMessage: `* Please fill this field with right format. Example: ${this.props.example}`})
                this.props.onValueChanged("");
            } else {
                this.setState({innerErrorMessage: ""})
                this.props.onValueChanged(event.target.value.trim());
            }
        } else {
            if(this.props.type === "url") {
                this.setState({innerErrorMessage: "* Please enter a url fromat string"});
            }
            if(this.props.type === "number" && this.props.step === 1) {
                this.setState({innerErrorMessage: "* Please enter integer value"})
            }
            this.props.onValueChanged("");
        }
    }

    private onCopyButtonClick = (event: any) => {
        this.setState({isCopied: true})
        setTimeout(()=> {this.setState({isCopied: false})},1500)
        navigator.clipboard.writeText(this.props.value);
    }

    private getInput() {
        return <input
            id={this.props.id!}
            className={`csod-input-textbox ${this.props.isAbleToCopy ? 'full-width' : null} ${(!CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage) || this.state.innerErrorMessage !== "") ? "error-border has-error" : ""}`}
            type={this.props.type != null ? (this.props.type !== "" ? this.props.type : "text") : "text"}
            placeholder={this.props.placeholder == null ? this.props.title : this.props.placeholder}
            maxLength={this.props.maxlength != null ? this.props.maxlength : 1000}
            multiple={this.props.multiple ? true : false}
            onChange={this.onTextChanged}
            defaultValue={this.props.defaultValue!}
            step={this.props.step!}
        />
    } 

    render() {
        return  <div className = {`csod-inputs-column-container ${this.props.additionalClass!}  ${this.props.widthClass}`} cy-data={this.props.cy_data}>
            {this.props.isHeaderHidden? null: <div className = "csod-input-title-text non-selectable">{this.props.title + `${this.props.important?" *" : ""}`}</div> }
            
            {this.props.isAbleToCopy ?
              <div className='csod-inputs-row-container no-flex-wrap'>
              {this.getInput()}   
              <button className = "csod-copy-button" onClick={this.onCopyButtonClick}/> 
            </div>
            :
            this.getInput()  }
          
            {!CodeHelpers.IsStringNullOrEmpty(this.props.description)? <div className="csod-description-container">
                <div className="csod-description-icon"/>
                <div className="csod-description-text">{this.props.description}</div>
            </div> : null}
            {!CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage)? <div className = "error-text-color">{this.props.errorMessage}</div> : null}
            {this.state.innerErrorMessage !== ""? <div className = "error-text-color">{this.state.innerErrorMessage}</div> : null}
        </div>
    }
}