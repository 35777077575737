import { AdditionalSettingType } from "./AdditionalSettingsType";

export interface INameValueWithTypeAndPositionInput {
    name: string,
    position: number,        
    type?: AdditionalSettingType,
    value: string
} 

export default class NameValueWithTypeAndPositionInput implements INameValueWithTypeAndPositionInput {
    constructor (public name: string = "",
    public position: number = 0,
    public type?: AdditionalSettingType,
    public value: string = "") { }
}
   