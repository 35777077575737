export const PROVIDERS_PAGE_PATH = "/";
export const PACKGES_PAGE_PATH = "/packages";
export const PROVIDER_FRAMEWORK_PAGE_PATH = "/providerframework";
export const PROVIDER_DATA_PATH = "/provider_data";
export const ORGANIZATIONS_PATH ="/organizations";
export const ECA_BUNDLES_PATH ="/explore_content_anytime_bundles";
export const ECA_ORG_CC_PATH ="/catalog";
export const ECA_COURSES_PATH ="/explore_content_anytime_courses";
export const ECA_SUBSCRIPTION_PATH ="/explore_content_anytime_subscription";
export const ORGANIZATION_FRAMWORK_PAGE_PATH = "/organizationframework";
