import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PackagesGridViewModel from '../../models/PackagesGrid/PackagesGridViewModel';
import "./PackageItem.css";
import PackagesConstants from '../../constants/packagesConstants';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import PackageStore from '../../stores/PackagesStore';


interface PackageItemProps {
    Package: PackagesGridViewModel,
    RedirectToUpdate: () => void,
    Packages?: PackageStore,
    globalLoader?: GlobalLoaderStore,
    cy_data?: string
}

interface PackageItemState {
    Package: PackagesGridViewModel | null;
}

@inject("packages", "globalLoader")
@observer
export default class PackageItem extends Component<PackageItemProps, PackageItemState> {
    constructor(props: PackageItemProps) {
        super(props);
        this.state = {
            Package: props.Package
        }
    }


    render() {
        return <tr cy-data={this.props.cy_data}>
            <td data-th={PackagesConstants.PACKAGES_GRID_BUNDLE_ID_TITLE} className='csod-table-td'>{this.props.Package.BundleID}</td>
            <td data-th={PackagesConstants.PACKAGES_GRID_CIID_TITLE} className='csod-table-td' >{this.props.Package.CIID}</td>
            <td data-th={PackagesConstants.PACKAGES_GRID_GCID_TITLE} className='csod-table-td' >{this.props.Package.GlobalContentID}</td>
            <td data-th={PackagesConstants.PACKAGES_GRID_PACKAGE_ID_TITLE} className='csod-table-td'>{this.props.Package.PackageID}</td>
            <td data-th={PackagesConstants.PACKAGES_GRID_PROVIDER_ID_TITLE} className='csod-table-td'>{this.props.Package.ProviderID}</td>
            <td data-th={PackagesConstants.PACKAGES_GRID_SUBSCRIBER_URI_TITLE} className='csod-table-td'>{this.props.Package.SubscriberUri}</td>
            <td data-th={PackagesConstants.PACKAGES_GRID_PROCESS_SOURCE} className='csod-table-td'>{this.props.Package.ProcessSource}</td>
            <td data-th={PackagesConstants.PACKAGES_GRID_CREATE_DATE} className='csod-table-td'>{new Date(this.props.Package.CreateDate).toLocaleString()}</td>
        </tr>
    }
}