import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import EcaCourseGridViewModel from '../../models/ExploreContentAnytimeModels/EcaCourseGridViewModel';
import "./EcaBundleItem.css";
import { providerOfferingTypeToString } from '../../models/ProviderDetails/ProviderOfferingType';
import { TrainingType } from '../../models/ProviderDetails/TrainingType';
import EcaConstants from '../../constants/ExploreContentAnytimeConstants';
import EcaCourseStore from '../../stores/EcaCourseStore';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import { SubjectMapping } from '../../models/ProviderDetails/ProviderDetailsExpansion';
import SSOConfigModel from '../../models/SSOConfig/SSOConfigModel';
import ProviderToolData from '../../models/TestSubscription/ProviderToolData';
import SsoData from '../../models/SSOConfig/SsoData';
import UpsertProviderModel from '../../models/ProviderDetails/UpsertProviderModel';
import { AuthenticationType } from '../../models/ProviderDetails/AuthenticationType';
import SyncStatusSuccessImg from '../../resources/csod-green-checkmark.svg';
import SyncStatusFailedImg from '../../resources/csod-red-cross.svg';

interface EcaCourseItemProps {
    Course: EcaCourseGridViewModel,
    isOrgCca: boolean,
    courses?: EcaCourseStore,
    globalLoader?: GlobalLoaderStore,
    cy_data?: string,
    selectCourse: (gcid) => {},
    selectAllcourses: boolean    
}

interface EcaCourseItemState {
    Course: EcaCourseGridViewModel | null;

}



@inject("courses", "globalLoader")
@observer
export default class EcaCourseItem extends Component<EcaCourseItemProps, EcaCourseItemState> {
    constructor(props: EcaCourseItemProps) {
        super(props);
        this.state = {
            Course: props.Course,
        }
    }
 
    fixDateFormat = () => {
        let date = new Date( this.props.Course.LastModifiedUTC)
        // let result = `${date.getMonth()}/${date.getDay()}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`
        // var result = date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear() + " " +
        //     date.getHours() + ":" + date.getMinutes();
        var result = date.toLocaleString();
        return result;
    }


    render() {
        let isOrgCca = this.props.isOrgCca
        if(isOrgCca){
            return  (<tr cy-data={this.props.cy_data}>
                <td id={this.props.Course.GlobalContentID} className='csod-table-td '> <input type="checkbox" checked={this.props.Checked} onChange={() => this.props.ChangeCheckbox(this.props.Course.GlobalContentID, !this.props.Checked)} /> </td>
                <td data-th={EcaConstants.ECA_GRID_TITLE_TITLE} className='csod-table-td csod-provider-name'>{this.props.Course.Title}</td>
                <td data-th={EcaConstants.ECA_GRID_GCID_TITLE} className='csod-table-td csod-provider-name'>{this.props.Course.GlobalContentID}</td>
                <td data-th={EcaConstants.ECA_GRID_PROVIDER_TITLE} className='csod-table-td' >{this.props.Course.ProviderName}</td>
                <td data-th={EcaConstants.ECA_GRID_SUBJECT_TITLE} className='csod-table-td'>{this.props.Course.Subjects.join(',')}</td>
                <td data-th={EcaConstants.ECA_GRID_METADATA_LANGUAGE_TITLE} className='csod-table-td'>{this.props.Course.Languages?.map(x=>this.props.courses?.FormatLanguageTag(x)).join(', ')}</td>
                <td data-th={EcaConstants.ECA_GRID_UPDATE_DATE_TITLE} className='csod-table-td'>{this.fixDateFormat()}</td>
            </tr>);
        }
     
        else{
            return (<tr cy-data={this.props.cy_data}>
                <td id={this.props.Course.GlobalContentID} className='csod-table-td '> <input type="checkbox" checked={this.props.Checked} onChange={() => this.props.ChangeCheckbox(this.props.Course.GlobalContentID, !this.props.Checked)} /> </td>
                <td data-th={EcaConstants.ECA_GRID_TITLE_TITLE} className='csod-table-td csod-provider-name'>{this.props.Course.Title}</td>
                <td data-th={EcaConstants.ECA_GRID_ID_TITLE} className='csod-table-td csod-provider-name'>{this.props.Course.GlobalContentID}</td>
                <td data-th={EcaConstants.ECA_GRID_DESCRIPTION_TITLE} className='csod-table-td' >{this.props.Course.Description}</td>
                <td data-th={EcaConstants.ECA_GRID_PROVIDER_TITLE} className='csod-table-td' >{this.props.Course.Provider?.Name}</td>
                <td data-th={EcaConstants.ECA_GRID_LANGUAGE_TITLE} className='csod-table-td'>{this.props.Course.Languages?.map(x=>this.props.courses?.FormatLanguageTag(x)).join(', ')}</td>
                <td data-th={EcaConstants.ECA_GRID_SUBJECT_TITLE} className='csod-table-td'>{this.props.Course.Subjects.join(',')}</td> 
                <td data-th={EcaConstants.ECA_GRID_DURATION_TITLE} className='csod-table-td'>{this.props.Course.Duration}</td>
                <td data-th={EcaConstants.ECA_GRID_UPDATE_DATE_TITLE} className='csod-table-td'>{this.fixDateFormat()}</td>
            </tr>);
        }
    }
}
