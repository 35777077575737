import { KeyValue, KeyValueModel } from "../../models/ProviderDetails/UpsertProviderModel";

export default class CodeHelpers {

  public static IsStringNullOrEmpty = (value?: string) : boolean => {
    if(value == null || value.length == 0 ) {
      return true;
    }
    return (value as string).trim() === "";
  }

  public static IsStringANumber = (value: string) : boolean => {
    return value.match(/^\d+$/) != null;
  }



  public static IsJSONString = (value: string) : boolean => {
    var stringItemCount = (value.match(/:/g) || []).length;
    // eslint-disable-next-line
    var isJSONBased = (/^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
    // eslint-disable-next-line
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    // eslint-disable-next-line
    replace(/(?:^|:|,)(?:\s*\[)+/g, '')));
    if(isJSONBased) {
      try {
        var jsonItem = JSON.parse(value);
        return true;
      } catch {
        return false;
      }
    } 
    return false;
  }
  
  public static ToKeyValue = (value: string) : KeyValueModel[] => {
    try{
      
      var json = JSON.parse(value);
      var keys = Object.keys(json);
      var values = Object.values(json) as unknown as string;
      var keyValues: KeyValueModel[] = [];
      for (let index = 0; index < keys.length; index++) {
        var keyValue: KeyValueModel = {Key: keys[index], Value: values[index]} ;
        keyValues.push(keyValue);
      }
      return keyValues;
    } 
    catch (error) {
      console.log(error);
      return [];
    }
  }

  public static ArrayKeyValueToString = (value: KeyValueModel[]): string  => {
    
    var keyValueString = "{\n";
    for(var i = 0; i < value.length; i++) {
      keyValueString = value[i]? keyValueString.concat(`\t"${value[i].Key}": "${value[i].Value}"${i !== value.length-1? ',' : ''}\n`) : keyValueString.concat(`\tnull${i !== value.length-1? ',' : ''}\n`);
    }
    keyValueString = keyValueString.concat("}");
    return keyValueString;
  }

  public static GetArrayItemByKey = (array: KeyValue[] | undefined,key: string): KeyValue | undefined => {
    return array ? array.find(p => p && p.key === key) : undefined;
  }

  public static ChangeArrayItemValueByKey = (array: KeyValue[] | undefined, key: string, value: string): boolean => {
    try {
      var changingArrayItemIndex = array?.findIndex(p => p != null && p.key === key);
      if(changingArrayItemIndex != null && changingArrayItemIndex !== -1) {
        array![changingArrayItemIndex].value = value;
      } else {
        array?.push({key: key, value: value});
      }
      return true;
    } catch(err) {
      console.log(err);
      return false
    }
  }
} 
  