export default class HttpHelper {

    static async SendPostRequest(url: string, params: any = null) {
        if(params != null) {
            try {
                var jsonParams = JSON.stringify(params);
                return await fetch(url, {
                    method: 'POST',
                    body: jsonParams,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(res => {
                    if(res.status == 304){
                        return res;
                    }
                    return res.json();
                })
            } catch (err) {
                throw err;
            }
        }
    }

    static async SendGetRequest(url: string) {        
        console.log("getting url: "+url)
            try {                
                return await fetch(url, {
                    method: 'GET',                                  
                    headers: {
                        'Content-Type': 'application/json',                       
                    }
                }).then(res => {
                    console.log(res.status);

                    if(res.status == 304){
                        return res;
                    }                    
                    return res.json();
                })
            } catch (err) {
                throw err;
            }
        
    }


}