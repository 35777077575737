import ProviderDataModel from "../ProviderDataDetails/ProviderDataModel";
import { ProviderOfferingType } from "../ProviderDetails/ProviderOfferingType";
import { TrainingType } from "../ProviderDetails/TrainingType";

export default class ProviderDataGridViewModel {
    constructor(
        public TrainingType: string = "1",
        public LastSuccessfulSyncDate: string = "",
        public ProviderId: string = "",
        public SyncDate: string = "",
        public StateType: string = "",
        public Url: string = "",    
        public ErrorMessage: string = "",
        public ErrorType: string = "",
        public TrainingsCount: string = "",
        public CorrelationId: string = "",
    ) { }
    
    public static Map(source: ProviderDataModel) : ProviderDataGridViewModel {
        return new ProviderDataGridViewModel(source.TrainingType ,source.LastSuccessfulSyncDate, source.ProviderId,
        source.SyncDate,source.StateType,source.Url, source.ErrorMessage, source.ErrorType, source.TrainingsCount, source.CorrelationId);
    } 
}
