import React, { Component } from 'react';
import CodeHelpers from '../../../helpers/common/CodeHelpers';
import { InputWidthTypes } from '../../../helpers/common/StyleHelper';
import "./LargeTextboxComponent.css";

interface LargeTextboxComponentProps {
    maxLettersCount?: number
    title: string,
    placeholder: string,
    important: boolean,
    widthClass: InputWidthTypes,
    errorMessage?: string,
    defaultValue: string | undefined,
    additionalClass?: string,
    
    onValueChanged: (value: string) => void,
    cy_data?: string
}

interface LargeTextboxComponentState {
    letterCount: number,
    isExceeded: boolean,
    isNotJSONContent: boolean
}

const EXCEEDED_ERROR_TITLE = "Letters maximum is exceeded";

export default class LargeTextboxComponent extends Component<LargeTextboxComponentProps, LargeTextboxComponentState> {
    constructor(props: LargeTextboxComponentProps) {
        super(props);
        this.state = { letterCount: this.props.defaultValue? this.props.defaultValue.length : 0, isExceeded: false, isNotJSONContent: false };
    }
    private onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        let isExceeded = false;
        let lettersCount = event.target.value.length;
        if(this.props.maxLettersCount != null && lettersCount > this.props.maxLettersCount){
            isExceeded = true;
        }
        this.setState({letterCount: lettersCount, isExceeded: isExceeded});
        this.props.onValueChanged(event.target.value);
    }

    render() {
        return <div className = {`csod-inputs-column-container  ${this.props.widthClass}`} cy-data={this.props.cy_data}>
            <div className = "csod-input-title-text non-selectable">{this.props.title + `${this.props.important?" *" : ""}`}</div>
            <div className = "csod-input-large-textbox-container">
                <textarea className={`csod-input-large-textbox ${CodeHelpers.IsStringNullOrEmpty(this.props.errorMessage)? null : "error-border has-error" } ${this.props.additionalClass != null? this.props.additionalClass : ""}`} placeholder={this.props.placeholder} defaultValue={this.props.defaultValue != null ? this.props.defaultValue : ""} onChange={this.onTextChange}  />
            </div>
            {this.props.maxLettersCount != null ? <div className={`csod-input-largebox-letter-counter non-selectable ${this.state.isExceeded? "error-text-color" :""}`}>{`${this.state.isExceeded? EXCEEDED_ERROR_TITLE + " " :""}`}{this.state.letterCount}/{this.props.maxLettersCount}</div> : null}
            {this.props.errorMessage != null ? <div className = "error-text-color">{this.props.errorMessage}</div> : null}
        </div>
    }
}