import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Select from "react-select";
import EcaCourseGridViewModel from '../../models/ExploreContentAnytimeModels/EcaCourseGridViewModel';
import './ExploreContentAnytimeGridComponent.css';
import EcaConstants from '../../constants/ExploreContentAnytimeConstants';
import PaginationComponent from '../providers/PagintationComponent';
import EcaModel from '../../models/ExploreContentAnytimeModels/EcaCourseModel';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import EcaCourseStore from '../../stores/EcaCourseStore'
import EcaBundleStore from '../../stores/EcaBundleStore'
import React from 'react';
import { Redirect } from 'react-router';
import EcaCourseItem from './EcaCourseItem'
import Tooltip from "@material-ui/core/Tooltip";
import { RouterStore } from 'mobx-react-router';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import { Auth } from 'aws-amplify';
import TextInputComponent from '../common/inputs/TextInputComponent';
import { InputWidthTypes } from '../../helpers/common/StyleHelper';
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import PopupComponent, { GenericSuccessPopupComponent } from '../common/popups/PopupComponent';
import OrganizationStore from '../../stores/OrganizationStore';
import Papa from "papaparse";

interface OrgCcaCoursesGridComponentProps {
    courses?: EcaCourseStore,
    bundles?: EcaBundleStore,
    globalLoader?: GlobalLoaderStore,
    routing?: RouterStore,
}

interface OrgCcaCoursesGridComponentState {
    filterString: string,
    appliedFilterString: string,
    courseInfo?: EcaCourseGridViewModel,
    redirectToFramework: boolean,
    checkedSelectAllCourses: boolean,
    showPopup: boolean,
    modalTitle: string,
    modalMessage: string,
    selectedFile: Blob,
    fileGcids:string[],
    fileInavlidGcids:string[],
    filterSelected:boolean,
    refinedSearchSubject:string[],
    refinedSearchText: string,
    refinedSearchProvider:any[],
    refinedOrgIdentifier:any,
    refinedSearchBundles:any[],
    refinedSearchLanguages:string[],
    refinedSearchDateStart:string,
    refinedSearchDateEnd:string,    
    uiFilterChangeKey: number,
    uiPaginationChangeKey: number
}

const SEARCH_TITLE: string = "Search";
var totalSubjects:any[];
var totalProviders:any[];
var totalLanguages:any[];
var totalBundles:any[];
var orgLanguageList:string[]  = [];
var showOrg:boolean = false;

@inject('courses', 'globalLoader', 'routing' )
@observer
export default class OrgCcaCoursesGridComponent extends Component<OrgCcaCoursesGridComponentProps, OrgCcaCoursesGridComponentState> {
    constructor(props: OrgCcaCoursesGridComponentProps) {
        super(props);
        this.state = {
            filterString: "",
            appliedFilterString: "",
            redirectToFramework: false,
            checkedSelectAllCourses: false,
            filterSelected:false,
            refinedSearchText:'',
            refinedSearchSubject:[],
            refinedSearchProvider:[],
            refinedOrgIdentifier:'',
            refinedSearchLanguages:[],
            refinedSearchBundles:[],
            refinedSearchDateStart:'',
            refinedSearchDateEnd:'',
            uiFilterChangeKey: 0,
            uiPaginationChangeKey: 0,
        }
    }   

    componentDidMount() {
        console.log('Downloads start', new Date());
        if(this.props.courses?.Courses?.length > 0 && !this.state.refinedOrgIdentifier)
            return;        
        this.props.globalLoader!.startLoading();
        let cognitoUserId = Object.entries(localStorage).find(x=>x[0].includes('LastAuthUser'))[1];
        cognitoUserId = this.state.refinedOrgIdentifier ?  this.state.refinedOrgIdentifier  : cognitoUserId;
        this.props.courses!.loadOrgCourses(cognitoUserId).then((data) => {                
            orgLanguageList = data.MetadataLanguages                
        }).finally(()=>{
            this.props.globalLoader!.finishLoading();
        })
        
        var groups = [];
        Auth.currentAuthenticatedUser().then(res=>{
            groups = res.signInUserSession.accessToken.payload["cognito:groups"];
            var showOrgGroup = groups?.some((element:string) => ['Admins','Contentops'].includes(element));
            showOrg = showOrgGroup;
        });
    }

    initializeOrganization = () =>{

        if(!this.state.refinedOrgIdentifier)
            return;
        totalSubjects = [];
        totalProviders = [];
        totalLanguages = [];
        totalBundles = [];
        orgLanguageList = [];
        this.componentDidMount();
    }
    getBundleName = () => {

        let bundleId = this.props.bundles?.SelectedBundle;
        let bundle = this.props.bundles.Bundles.find(f => f.Id === bundleId)

        return bundle.Name
    }
    async downloadInactiveCourses() {
        let inactiveCourses = this.props.courses?.Courses.filter(x => !x.IsActive || x.IsForBundleRemoval)
        let activeCourses = this.props.courses?.Courses.filter(x => x.IsActive && !x.IsForBundleRemoval) 
        if(activeCourses && inactiveCourses){
            let idSet = new Set(activeCourses.map(item => item.GlobalContentID));
            inactiveCourses = inactiveCourses.filter(item => !idSet.has(item.GlobalContentID));
        }
        await this.getGcidsAsPresignedUrl(inactiveCourses, "inactive");
    }

    async downloadSelectedCourses(type) {

        let allSelectedCourses = this.props.courses?.Courses.filter(x => x.Checked)
        await this.getGcidsAsPresignedUrl(allSelectedCourses, type);
    }
    
    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    async getGcidsAsPresignedUrl(courses, type) {
        if (courses.length == 0) {
            let message = "No courses were selected for download"
            let title = "Operation Failed"
            this.setState({ showPopup: true, modalMessage: message, modalTitle: title })
            return;
        }
        let email = Auth.Credentials.Auth.user.attributes.email
        
        let bundleIds = courses.map(x=>x.BundleIds).flat().filter(this.onlyUnique);
        let gcids  = courses.map(x => x.GlobalContentID);

        GraphQLHelper.GetPersignedUrl('', '', gcids, type, true, email,bundleIds);
        let title = "Download Via Email"
        let message = "Download Link Sent To:" + email
        this.setState({ showPopup: true, modalMessage: message, modalTitle: title })
    }

    handleCsvParser =(event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        let that = this;
        Papa.parse(event.target.files[0], {
            header: false,
            skipEmptyLines: true,
            complete: function (results) {
                that.props.courses!.CurrentPage = 1;
                that.props.courses?.CheckAllCheckboxs(false);
                that.props.courses.SetArrayChecked(results.data.flat());
                that.filterSelectedCoursesTrue();
            },
        });
    }

    selectAllCourses = () => {
        let isChecked = !this.state.checkedSelectAllCourses;
        this.props.courses?.CheckAllCheckboxs(isChecked, this.refinedSearchFilterCourses(this.filterProviders(true)));
        this.setState({ checkedSelectAllCourses: isChecked })
    }

    handleOnChangePage = (newCurrentPage: number): Promise<any> => {
        this.setState({
            uiPaginationChangeKey: this.state.uiPaginationChangeKey + 1
        })
        return new Promise<any>((resolve) => {
            this.props.courses!.CurrentPage = newCurrentPage;
            resolve(null);
        })
    }

    sortCoursesByTitle = (isDescending: boolean) => {
        this.props.courses!.sortCourses((a, b) => { return isDescending ? -(a.Title!.localeCompare(b.Title!)) : (a.Title!.localeCompare(b.Title!)) });
    }

    sortCoursesByProviderId = (isDescending: boolean) => {
        this.props.courses!.sortCourses((a, b) => { return isDescending ? -(a.ProviderName!.localeCompare(b.ProviderName!)) : (a.ProviderName!.localeCompare(b.ProviderName!)) });
    }

    sortCoursesByUpdateDate = (isDescending: boolean) => {
        this.props.courses!.sortCourses((a, b) => { return isDescending ? -(a.LastModifiedUTC!.localeCompare(b.LastModifiedUTC!)) : (a.LastModifiedUTC!.localeCompare(b.LastModifiedUTC!)) });
    }

    onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ refinedSearchDateEnd: e.target.value })
    }


    filterProviders = (isFilterByAppliedFilterString: boolean, ignoreChecked: boolean = false): EcaModel[] => {
        let filterString: string = (isFilterByAppliedFilterString ? this.state.appliedFilterString : this.state.filterString).toLowerCase();
        if (this.props.courses?.Courses != null) {
            let res = this.props.courses!.Courses.filter(i =>
                i.Title!.toLowerCase().match(filterString) ||
                i.GlobalContentID!.toLowerCase().match(filterString)
            );
            if (this.state.filterSelected && !ignoreChecked)
                res = res.filter(i => i.Checked)
            return res;
        }
        return [];
    }

    getSubjectList = (courses: any[])=>{ 
        if(totalSubjects?.length > 0)
            return totalSubjects;
        let subjectList: any[]= [];

        for (let index = 0; index < courses.length; index++) {
            const element = courses[index].Subjects;
            element.forEach(item => {
                if (!subjectList.includes(item))
                subjectList.push(item)
            });
        }

        let res = subjectList.map((subject, index) => ({ label: subject, value: index }));
        totalSubjects =res;
        return totalSubjects.sort((a, b) => a.label.localeCompare(b.label));
    }
    getBundleList = (courses: any[], override:boolean)=>{
        let result: any[] = [];
        if(totalBundles?.length > 0 && !override)
           return totalBundles;
        totalBundles = [...new Set(courses.flatMap(obj => obj.BundleNames))].map((subject, index) => ({ label: subject, value: index }));
        return totalBundles.sort((a, b) => a.label.localeCompare(b.label));
    }
    getProviderList = (courses: any[])=>{
        let result: any[] = [];
        if(totalProviders?.length > 0)
           return totalProviders;
        for (let index = 0; index < courses.length; index++) {             
           if (!result.includes(courses[index].ProviderName))
               result.push(courses[index].ProviderName)
        }
        totalProviders = result.map((subject, index) => ({ label: subject, value: index }));
        return totalProviders.sort((a, b) => a.label.localeCompare(b.label));
    }
    getLanguageList = (courses: any[]) => {
        if(totalLanguages?.length > 0)
            return totalLanguages;

        let res = null; 
        if (orgLanguageList.length > 0){
              res =  orgLanguageList.map(x=> ({label:this.props.courses?.FormatLanguageTag(x), value:x}))
           }
        else {
            let langList: any[]= [];
            for (let index = 0; index < courses.length; index++) {
                const element = courses[index].Languages;
                element.forEach(item => {
                    let langName = this.props.courses?.FormatLanguageTag(item);
                    if (!langList.includes(langName))
                        langList.push(langName)
                });
            }
            res = langList.map((subject, index) => ({ label: subject, value: index }));
        }

        totalLanguages = res;
        return totalLanguages.sort((a, b) => a.label.localeCompare(b.label));
    }

    onOrgIdentifierChange = (value: any) => {
        this.setState({ refinedOrgIdentifier: value })
    }

    onRefinedSearchSubjectsChange = (value: any) => {
        if(this.props.courses!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchSubject: value })
    }
    onRefinedSearchBundleChange = (value: any) => {
        if(this.props.courses!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchBundles: value })
    }
    onRefinedSearchProviderChange = (value: any) => {
        if(this.props.courses!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchProvider: value })
    }
    onRefinedSearchTextChange = (e: any) => {
        if(this.props.courses!.CurrentPage > 1){
            this.handleOnChangePage(1);
        }
        this.setState({ refinedSearchText: e.target.value })
    }
    onRefinedSearchLanguageChange = (value: any) => {
        if(this.props.courses!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchLanguages: value })
    }
    onRefinedSearchDateStartChange = (value: any) => {
        if(this.props.courses!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchDateStart: value[0] })
    }
    onRefinedSearchDateEndChange = (value: any) => {
        if(this.props.courses!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchDateEnd: value[0] })
    }

    escapeRegex(string) {
        return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    refinedSearchFilterCourses = (courses: any[]) => {
        if (courses == undefined)
            return [];

        //filter subject
        if (this.state.refinedSearchSubject.length != 0) {
            let subjectList = this.state.refinedSearchSubject.map(x => x.label);
            courses = courses.filter(course => {
                if (subjectList.some(r => course.Subjects.includes(r)))
                    return course;
            })
 
        }
        //filter title & gcid
        if (this.state.refinedSearchText.length > 0) {
            var refinedMatch = this.escapeRegex(this.state.refinedSearchText.toLowerCase());
            courses = courses.filter(i => i.Title.toLowerCase().match(refinedMatch) || 
             i.GlobalContentID.toLowerCase().match(refinedMatch))
        }

        //filter provider
        if (this.state.refinedSearchProvider.length != 0)
            courses = courses.filter(course => this.state.refinedSearchProvider.map(x => x.label).includes(course.ProviderName))

             //filter provider
        if (this.state.refinedSearchBundles.length != 0)
        {
            var arr1 = this.state.refinedSearchBundles.map(x => x.label);
            courses = courses.filter(x=>x.BundleNames.some(y => arr1.includes(y)) )
        }

        //filter language
        if (this.state.refinedSearchLanguages.length != 0) {
            let languageList = this.state.refinedSearchLanguages.map(x => x.label);
            courses = courses.filter(course => {
                if (languageList.some(r => course.Languages.map(x=>this.props.courses?.FormatLanguageTag(x)).includes(r)))
                    return course;
            })
        }
         
        //filter date
        if (this.state.refinedSearchDateStart) {
            var refinedSearch = this.state.refinedSearchDateStart.toISOString()
            courses = courses.filter(course => refinedSearch < course.LastModifiedUTC)
        }
        if (this.state.refinedSearchDateEnd) {
            var refinedSearch = this.state.refinedSearchDateEnd.toISOString()
            courses = courses.filter(course => refinedSearch > course.LastModifiedUTC)
        }
        return courses; 
    }

    addCourseToSelectedCourses = (gcid, isChecked) => {
        this.props.courses?.ChangeCheckbox(gcid, isChecked)
    }

    ResetCurrentSort = (idString: string) => {
        let elements = document.getElementsByClassName("sortable");
        while (elements.length)
            elements[0].classList.remove("sortable");

        let sortTarget = document.getElementById(idString);
        sortTarget?.classList.add("sortable");
    }

    renderGridHeaderItem = (title: string, onSort?: (isDescending: boolean) => void) => {
        if (!onSort)
            return <th scope='col'>
                <div className="csod-grid-header-container">
                    <div className="csod-sorting-container">{title}</div> 
                </div>
            </th>;
        else
            return <th scope='col'>
                <div className="csod-grid-header-container">
                    {title}
                    <div className="csod-sorting-container">
                        <div id={title + 'up'} className="csod-sort-up smallpin" onClick={() => {
                            onSort(true);
                            this.ResetCurrentSort(title + 'up')
                        }}><div className="csod-icon-sort-up">▲</div></div>
                        <div id={title + 'down'} className="csod-sort-down smallpin" onClick={() => {
                            onSort(false)
                            this.ResetCurrentSort(title + 'down')
                        }}><div className="csod-icon-sort-down">▼</div></div>
                    </div>
                </div>
            </th>;
    }

    selectCourse = (gcid, isChecked) => {
        this.props.courses?.ChangeCheckbox(gcid, isChecked);
    }

    renderTableCheckbox = (id: string) => {
        return <th scope='col'>
            <div className="csod-grid-header-container">
                <div className="csod-sorting-container">{id}
                    <input type="checkbox" /></div>
            </div>
        </th>;
    }

    noCoursesFound = () => {
        return
    }

    getModal = () => {
        return <GenericSuccessPopupComponent Title={this.state.modalTitle} Message={this.state.modalMessage} OnClose={() => { this.setState({ showPopup: false }) }} OnDone={() => { this.setState({ showPopup: false }) }} />
    }

    onFileChange = (event) => {
        // Update the state
        this.setState({
            selectedFile: event.target.files[0],
        });        
    };

    onFileUpload = () => {
        try {
            this.getfileData();
        } catch (e) {
                this.setState({
                    showPopup:true,
                    modalTitle:"Invalid File Upload",
                    modalMessage:"Unable to use uploaded file"
                })
        }
    };

    getfileData = () => {
        let valid: string[] = [];        
        if (!this.state.selectedFile.name.includes('.csv'))
            throw new Error('Invalid File Format');

        this.state.selectedFile.text().then(data => {
            {
                let array = data.split("\n")
                array.forEach(fullItem => {
                    let item = fullItem.replaceAll("\r", "")
                    if (item.includes("_") && item.length >= 39)
                        valid.push(item);
                })
            }

            let CourseList = this.filterProviders(true, true);
            for (let i = 0; i < CourseList.length; i++) {
                const element = CourseList[i];                
                if (valid.includes(element.GlobalContentID))
                    element.Checked = true;
                else
                    element.Checked = false;
            }
        })
        this.setState({
            showPopup: true,
            modalTitle: "Upload succeeded, courses are checked",
            modalMessage: ''
        })
    }
 
    fileData = () => {
        if (this.state.selectedFile) {
            return (
                <div>
                    <label>All Values from file</label>
                    <p>
                        {this.getfileData()}
                    </p>
                </div>
            );
        }
    };
    filterSelectedCoursesTrue = () =>{
        if(!this.state.filterSelected)
        {
            document.getElementById('filter-checked-courses').checked = true
            this.setState({ filterSelected: true })
        }
    }

    filterSelectedCourses = () => {
        this.setState({ filterSelected: !this.state.filterSelected })
    }

    clearRefinedSearch = () =>{
        this.setState({
            refinedSearchDateEnd:'',
            refinedSearchDateStart:'',
            refinedSearchText:'',
            refinedSearchLanguages:[],
            refinedSearchProvider:[],
            refinedSearchSubject:[],
            refinedSearchBundles:[],
            uiFilterChangeKey: this.state.uiFilterChangeKey + 1
        })
    }
  


    render() {
        if (this.props.globalLoader!.isLoading) {
            return null;
        }
        let input = document.getElementsByClassName('hide-input')[0];
        if(input){
            input.onclick = function () {
                this.value = null;
              };
        }
        
        let lastItemPerCurrentPageNumber = (this.props.courses!.CurrentPage - 1) * EcaConstants.ITEMS_PER_PAGE_COUNT + EcaConstants.ITEMS_PER_PAGE_COUNT;        
        let CourseList: any[] = this.filterProviders(true);        
        CourseList = CourseList.filter(x => x.IsActive && !x.IsForBundleRemoval)        
        CourseList = this.refinedSearchFilterCourses(CourseList)
        var pageListCount =this.props.courses!.TotalPages;
        pageListCount = Math.ceil(CourseList.length / EcaConstants.ITEMS_PER_PAGE_COUNT);
        
        let currentPage = this.props.courses!.CurrentPage;
        if (lastItemPerCurrentPageNumber > CourseList!.length) {
            lastItemPerCurrentPageNumber = CourseList!.length;
        }
        if (this.state.showPopup)
            return this.getModal()

        return <div className="csod-eca-container">
                 <div className="csod-search-container">
                     <div className="csod-providers-count-title">
                         <div className='csod-bundle-numbers' style={{fontWeight:'bold', fontSize:'xx-large'}}>
                            Active Courses ({CourseList.length})
                         </div>
                     </div>
                    {showOrg &&  
                        <div className="csod-search-container-query low-org-select-margin" >
                            <TextInputComponent title="Organization Select"
                                placeholder="Enter Specific Org ID"
                                widthClass={InputWidthTypes.Small}
                                value={this.state.refinedOrgIdentifier}
                                important={false}
                                onValueChanged={(e) => this.onOrgIdentifierChange(e)}
                            />
                            <div className="csod-inputs-column-container">
                                <button type="button" className="csod-button less-rounded-corners blue query-set-width" onClick={this.initializeOrganization}>Go to Catalog</button>
                            </div>
                        </div> }
                 </div>
                <div className='refinement-div'>
                    <div style={{display:'inline-block'}} className='advanced-search-wrapper wrapper-top'>
                        <div className="csod-search-add-new-container">
                                <div className="csod-search-field-tips-container max-width-search">
                                    <input type="text" id='refined-text-search-input' list="providers" style={{width:'inherit'}} 
                                    key={this.state.uiFilterChangeKey} 
                                    className="csod-input-search-tb less-rounded-corners" placeholder={"Search GCID or Title"} 
                                    defaultValue={this.state.refinedSearchText} onChange={this.onRefinedSearchTextChange}  />
                                </div>
                                {/* <button type="submit" style={{marginLeft:'-7px'}} className="csod-button less-rounded-corners gray" onClick={this.onFilterApply}>{SEARCH_TITLE}</button> */}
                        </div>
                        <div className='advanced-search-buttons'>
                            <div className='select-input-org'>
                                <Select placeholder="Subscriptions" myFontSize="20px" isMulti={true} 
                                        important={true} 
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, fontFamily:'Segoe UI'  }) }}
                                        options={this.getBundleList(CourseList)}                               
                                        onChange={this.onRefinedSearchBundleChange} 
                                        value={this.state.refinedSearchBundles}
                                        className='select-class'/>
                            </div>
                            <div className='select-input-org'>
                                <Select placeholder="Subjects" myFontSize="20px" isMulti={true} 
                                        important={true} 
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, fontFamily:'Segoe UI'  }) }}
                                        options={this.getSubjectList(CourseList)}                               
                                        onChange={this.onRefinedSearchSubjectsChange} 
                                        value={this.state.refinedSearchSubject}
                                        className='select-class'/>
                            </div>
                            <div className='select-input-org'>
                                <Select placeholder="Providers" myFontSize="20px" isMulti={true} 
                                        important={true} 
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, fontFamily:'Segoe UI'  }) }}
                                        options={this.getProviderList(CourseList)}                               
                                        onChange={this.onRefinedSearchProviderChange} 
                                        value={this.state.refinedSearchProvider}
                                        className='select-class' />
                            </div>

                            <div className='select-input-org'>
                                <Select placeholder="Languages" myFontSize="20px" isMulti={true} 
                                        important={true} 
                                        styles={{ menuPortal: base => ({ ...base, fontFamily:'Segoe UI'  }) }}
                                        menuPortalTarget={document.querySelector('body')}
                                        options={this.getLanguageList(CourseList)} 
                                        value={this.state.refinedSearchLanguages}
                                        onChange={this.onRefinedSearchLanguageChange} 
                                className='select-class'/>
                            </div>
                            
                            <div className="select-input-org">
                                <Flatpickr className="class-start-date" key={this.state.uiFilterChangeKey} placeholder="From date" onChange={(date) => this.onRefinedSearchDateStartChange(date)}  />
                                {/* <DatePicker placeholderText="Updated From" onChange={(date) => this.onRefinedSearchDateStartChange(date)} /> */}
                                {/* <TextInputComponent title="Modified From"
                                    value={this.state.refinedSearchDateStart}
                                    defaultValue={this.state.refinedSearchDateStart}
                                    type="date"
                                    additionalClass='tooltip'
                                    widthClass={InputWidthTypes.Small} important={false}
                                    onValueChanged={value => this.onRefinedSearchDateStartChange(value)} isAbleToCopy={false}/> */}
                            </div>
                            <div className="select-input-org">
                                <Flatpickr className="class-end-date" key={this.state.uiFilterChangeKey} placeholder="To date" onChange={(date) => this.onRefinedSearchDateEndChange(date)}  />
                                {/* <DatePicker placeholderText="Updated To" onChange={(date) => this.onRefinedSearchDateEndChange(date)} /> */}
                                {/* <TextInputComponent title="Modified To"
                                    additionalClass='tooltip'
                                    value={this.state.refinedSearchDateEnd}
                                    defaultValue={this.state.refinedSearchDateEnd}
                                    type="date"                                
                                    widthClass={InputWidthTypes.Small} important={false}
                                    onValueChanged={value => this.onRefinedSearchDateEndChange(value)} isAbleToCopy={false} /> */}
                            </div>
                            <div className='clear-button-wrapper select-input-org'>
                                <button onClick={() => this.clearRefinedSearch()} type="button" className='clear-button button csod-button less-rounded-corners '>  Clear All Filters </button>
                            </div>

                          
                        </div>
                       
                    </div>
                </div>
                <div className='csod-search-container' style={{display:'flow-root'}}>
                    <div style={{display:'inline', float:'left'}}>
                        <Tooltip title="CSV file should include 1 column with GCIDs of courses">
                            <label className="custom-label-style">
                                    <input type="file" name="file" className="hide-input" onChange={this.handleCsvParser} accept=".csv" />
                                    Select Courses Via CSV File
                            </label>
                        </Tooltip>
                    </div>
                    <div style={{display:'inline', float:'right'}} > 
                        <button type="button" className="csod-button less-rounded-corners blue download-aicc" onClick={() => this.downloadSelectedCourses("zip")}
                        >{"Download AICC packages"}</button>
                        <button type="button" className="csod-button less-rounded-corners blue download-aicc" onClick={() => this.downloadSelectedCourses("csv")}
                        >{"Download Metadata CSV file"}</button>
                        <button type="button" className="csod-button less-rounded-corners red download-aicc" onClick={() => this.downloadInactiveCourses()}
                        >{"Download Retired Courses"}</button>
                    </div>
                </div>
                <div className='csod-search-container remove-margins'>
                        <div id='filter-selected-wrapper' style={{margin:'0px'}}>
                            <input id="filter-checked-courses" type="checkbox" defaultChecked={this.state.filterSelected} onChange={this.filterSelectedCourses} />
                            <span>Hide Unselected Courses</span>
                        </div>
                        <div style={{margin:'0px'}}>
                            <p style={{width:"100%", paddingLeft:'13px'}} className='courses-selected-text'> 
                                Courses Selected ({CourseList.filter(i => i.Checked).length}) </p>
                        </div>
                </div>
                 {CourseList.length === 0 ? <h2>No Courses Found </h2> :
                <div>
                   <table className="csod-providers-grid">
                        <thead>
                            <tr>
                                <th scope='col' className='select-all-courses-th'> 
                                <label className={"select-all-courses"} >
                                    <input id="select-all-courses" type="checkbox" defaultChecked={false} onChange={this.selectAllCourses} />
                                    <span> Select</span>
                                </label>
                                </th>
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_TITLE_TITLE, this.sortCoursesByTitle)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_GCID_TITLE)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_PROVIDER_TITLE, this.sortCoursesByProviderId)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_SUBJECT_TITLE )}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_METADATA_LANGUAGE_TITLE)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_UPDATE_DATE_TITLE, this.sortCoursesByUpdateDate)}
                                {/* {this.renderGridHeaderItem("Last Modified")} 
                                 {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DOWNLOAD_AICC_TITLE)}
                                {this.renderGridHeaderItem(EcaConstants.ECA_GRID_DOWNLOAD_METADATA_TITLE)} */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                CourseList.slice((this.props.courses!.CurrentPage - 1) * EcaConstants.ITEMS_PER_PAGE_COUNT, lastItemPerCurrentPageNumber).map((course) =>
                                    <EcaCourseItem Course={course} key={course.GlobalContentID}  Checked={course.Checked} ChangeCheckbox={this.selectCourse} isOrgCca={true} />
                                )}
                        </tbody>
                    </table>
                    {CourseList.length <= EcaConstants.ITEMS_PER_PAGE_COUNT ? null : <PaginationComponent key={this.state.uiPaginationChangeKey} currentPageIndex={currentPage} listPages={pageListCount} handleChangePage={this.handleOnChangePage}  />}
                </div>}
             </div>
                 
    }
}