import PackageModel from "../PackageDetails/PackageModel";
import { ProviderOfferingType } from "../ProviderDetails/ProviderOfferingType";
import { TrainingType } from "../ProviderDetails/TrainingType";

export default class PackagesGridViewModel {
    constructor(
        public Id: string,
        public BundleID: string,
        public GlobalContentID: string="",
        public SubscriberUri: string ="",
        public ProviderID: string ="",
        public PackageID: string ="", 
        public CIID: string ="",
        public ProcessSource: string ="",
        public CreateDate: string =""      
    ) { }
    
    public static Map(source: PackageModel) : PackagesGridViewModel {
        return new PackagesGridViewModel(source.Id,source.BundleID,source.GlobalContentID,
            source.PackageID,source.CIID, source.SubscriberUri, source.ProviderID,source.CreateDate,source.ProcessSource);
    } 
}